import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { Container, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getInitialState } from "../../utils/campaignCreation";
import { MANAGER_ROUTES } from "../../utils/constants";

export default function CampaignCreate() {
  const { subtype: startType = "" } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    businessId,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [initialValues, setInitialValues] = useState(
    getInitialState(startType.toUpperCase(), bmapi)
  );

  useEffect(() => {
    setInitialValues((vs) => getInitialState(vs.subtype, bmapi));
  }, [businessId, bmapi]);

  const onTypeChange = useCallback(
    (subtype) => {
      history.replace(
        MANAGER_ROUTES.CREATE_CAMPAIGN.replace(
          ":subtype?/",
          !subtype ? "" : `${subtype.toLowerCase()}/`
        )
      );
    },
    [history]
  );

  const handleSubmit = (values, after) => {
    startLoading();

    return bmapi
      .saveCampaign(values)
      .then(after)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.campaignCreate.saved",
            defaultMessage: "Campagna creata con successo",
          })
        );
        history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <IconButton
          component={RouterLink}
          to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
          size="small"
        >
          <ArrowBack />
        </IconButton>{" "}
        <FormattedMessage
          id="pages.campaignCreate.title"
          defaultMessage="Crea una campagna per {businessName}"
          values={{
            businessName: <strong>{bmapi.getUserInfo().business.name}</strong>,
          }}
        />
      </Title>

      <CommonCreateForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        onTypeChange={onTypeChange}
      />
    </Container>
  );
}
