import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  MoreVert,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Audiotrack as AudiotrackIcon,
  Videocam as VideocamIcon,
  ThreeDRotation as ThreeDRotationIcon,
  Timeline as TimelineIcon,
  InsertLink as InsertLink,
} from "@material-ui/icons";
import { useIntl } from "react-intl";
import { wikiMuseo } from "../messages/wikiMuseo";
import { common } from "../messages";

const cropModes = new Map();
cropModes.set("x", "cropDefault");
cropModes.set("contain", "cropContain");
cropModes.set("center", "cropCenter");
cropModes.set("top", "cropTop");
cropModes.set("bottom", "cropBottom");
cropModes.set("right", "cropRight");
cropModes.set("left", "cropLeft");

const MediaContentList = ({
  title,
  mediaContents,
  setContentSelected,
  setViewContent,
  setShowDeleteAlert,
  anchorEl,
  setAnchorEl,
  imageList,
  audioList,
  videoList,
  mapList,
  threeDmodelList,
  externalContents,
}) => {
  const intl = useIntl();

  const handleClick = (event, cSel) => {
    setAnchorEl(event.currentTarget);
    setContentSelected(cSel);
  };

  const showViewContent = () => {
    setViewContent(true);
  };

  return mediaContents.length ? (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6" }}
        style={{ padding: "4px 16px" }}
      />
      <CardContent>
        <List>
          {mediaContents &&
            mediaContents.map((content, i) => {
              return (
                <React.Fragment key={content.id}>
                  {i !== 0 && <Divider key={i} component="li" />}
                  <ListItem key={content.id} title={content.title}>
                    <ListItemAvatar>
                      {content.media_type === "image" && (
                        <Avatar src={content.url} />
                      )}
                      {content.media_type === "map" &&
                        content.media_subtype === "image" && (
                          <Avatar src={content.url} />
                        )}
                      {content.media_type === "map" &&
                        content.media_subtype === "openstreetmap" && (
                          <Avatar>
                            <TimelineIcon />
                          </Avatar>
                        )}
                      {content.media_type === "audio" && (
                        <Avatar>
                          <AudiotrackIcon />
                        </Avatar>
                      )}
                      {content.media_type === "video" && (
                        <Avatar>
                          <VideocamIcon />
                        </Avatar>
                      )}
                      {content.media_type === "3dmodel" && (
                        <Avatar>
                          <ThreeDRotationIcon />
                        </Avatar>
                      )}
                      {content.media_type === "externalContent" && (
                        <Avatar>
                          <InsertLink />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            component={"span"}
                            display="block"
                            variant="h6"
                            gutterBottom
                          >
                            {content.title}
                          </Typography>
                          {content.media_subtype !== "openstreetmap" && (
                            <Chip
                              label={
                                content.external
                                  ? intl.formatMessage(common.url)
                                  : intl.formatMessage(common.file)
                              }
                            />
                          )}
                          {content.media_type === "image" && (
                            <Chip
                              label={intl.formatMessage(
                                wikiMuseo[
                                  cropModes.get(content.crop_mode || "x")
                                ]
                              )}
                            />
                          )}
                          {content.media_type === "map" &&
                            content.media_subtype === "image" && (
                              <Chip
                                label={intl.formatMessage(
                                  wikiMuseo[
                                    cropModes.get(content.crop_mode || "x")
                                  ]
                                )}
                              />
                            )}
                          {content.media_subtype === "logo" && (
                            <Chip label="Logo" />
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction key={content.id}>
                      <IconButton
                        onClick={(e) => {
                          handleClick(e, content);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              );
            })}
        </List>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setContentSelected(null);
          }}
          onClick={() => setAnchorEl(null)}
        >
          <MenuItem onClick={showViewContent}>
            <ListItemIcon>
              <CreateIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.modify)}
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => setShowDeleteAlert(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.delete)}
            </Typography>
          </MenuItem>
        </Menu>{" "}
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardContent style={{ padding: 16 }}>
        {mediaContents === imageList && (
          <Typography>{intl.formatMessage(wikiMuseo.noImage)}</Typography>
        )}
        {mediaContents === mapList && (
          <Typography>{intl.formatMessage(wikiMuseo.noMap)}</Typography>
        )}
        {mediaContents === audioList && (
          <Typography>{intl.formatMessage(wikiMuseo.noAudio)}</Typography>
        )}
        {mediaContents === videoList && (
          <Typography>{intl.formatMessage(wikiMuseo.noVideo)}</Typography>
        )}
        {mediaContents === threeDmodelList && (
          <Typography>{intl.formatMessage(wikiMuseo.no3D)}</Typography>
        )}
        {mediaContents === externalContents && (
          <Typography>
            {intl.formatMessage(wikiMuseo.noExternalContent)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MediaContentList;
