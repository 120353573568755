import { defineMessages } from "react-intl";

export const jsc = defineMessages({
  atTime: {
    id: "component.jsc.atTime",
    defaultMessage: "alle",
  },
  /* Notify */
  eventWorkerCreate: {
    id: "component.jsc.eventWorkerCreate",
    defaultMessage: "Evento lavoratore creato con successo",
  },
  eventWorkerUpdate: {
    id: "component.jsc.eventWorkerUpdate",
    defaultMessage: "Evento lavoratore aggiornato con successo",
  },
  reportCreate: {
    id: "component.jsc.reportCreate",
    defaultMessage: "Report creato con successo",
  },
  reportUpdate: {
    id: "component.jsc.reportUpdate",
    defaultMessage: "Report aggiornato con successo",
  },
  /* Translate */
  anyDepartment: {
    id: "component.jsc.anyDepartment",
    defaultMessage: "Qualsiasi reparto",
  },
  anyEvent: {
    id: "component.jsc.anyEvent",
    defaultMessage: "Qualsiasi evento",
  },
  anyFactory: {
    id: "component.jsc.anyFactory",
    defaultMessage: "Qualsiasi stabilimento",
  },
  anyReport: {
    id: "component.jsc.anyReport",
    defaultMessage: "Qualsiasi report",
  },
  anyTeam: {
    id: "component.jsc.anyTeam",
    defaultMessage: "Qualsiasi squadra",
  },
  anyWorker: {
    id: "component.jsc.anyWorker",
    defaultMessage: "Qualsiasi lavoratore",
  },
  BCStatus: {
    id: "component.jsc.BCStatus",
    defaultMessage: "Stato Blockchain",
  },
  blockNumber: {
    id: "component.jsc.blockNumber",
    defaultMessage: "Numero del blocco",
  },
  blockTimestamp: {
    id: "component.jsc.blockTimestamp",
    defaultMessage: "Timestamp del blocco",
  },
  dateError: {
    id: "component.jsc.dateError",
    defaultMessage: "Data di inizio successiva a data di fine",
  },
  dateInsertion: {
    id: "component.jsc.dateInsertion",
    defaultMessage: "Data inserimento",
  },
  dateLastInsertion: {
    id: "component.jsc.dateLastInsertion",
    defaultMessage: "Data ultimo modifica",
  },
  dateNotarization: {
    id: "component.jsc.dateNotarization",
    defaultMessage: "Data notarizzazione",
  },
  dateLastNotarization: {
    id: "component.jsc.dateLastNotarization",
    defaultMessage: "Data ultima notarizzazione",
  },
  dateTime: {
    id: "component.jsc.dateTime",
    defaultMessage: "Data e Ora",
  },
  dateTimeIfPresent: {
    id: "component.jsc.dateTimeIfPresent",
    defaultMessage: "Data e Ora (se presente)",
  },
  department: {
    id: "component.jsc.department",
    defaultMessage: "Reparto",
  },
  eventAttachments: {
    id: "component.jsc.eventAttachments",
    defaultMessage: "Allegati Evento",
  },
  etherscanUrl: {
    id: "component.jsc.etherscanUrl",
    defaultMessage: "Etherscan URL (link alla transazione)",
  },
  eventCreate: {
    id: "component.jsc.eventCreate",
    defaultMessage: "Creazione eventi lavoratore",
  },
  eventDate: {
    id: "component.jsc.eventDate",
    defaultMessage: "Data evento",
  },
  eventDescription: {
    id: "component.jsc.eventDescription",
    defaultMessage: "Descrizione evento",
  },
  eventInspection: {
    id: "component.jsc.eventInspection",
    defaultMessage: "Ispezione eventi lavoratore",
  },
  eventType: {
    id: "component.jsc.eventType",
    defaultMessage: "Tipo evento",
  },
  eventWorkerDetail: {
    id: "component.jsc.eventWorkerDetail",
    defaultMessage: "Dettaglio Evento Lavoratore",
  },
  eventWorkerModify: {
    id: "component.jsc.eventWorkerModify",
    defaultMessage: "Modifica Evento Lavoratore",
  },
  factory: {
    id: "component.jsc.factory",
    defaultMessage: "Stabilimento",
  },
  filterClear: {
    id: "component.jsc.filterClear",
    defaultMessage: "Pulisci filtri",
  },
  filterCustomer: {
    id: "component.jsc.filterCustomer",
    defaultMessage: "Filtra per cliente",
  },
  filterDepartment: {
    id: "component.jsc.filterDepartment",
    defaultMessage: "Filtra per reparto",
  },
  filterDevice: {
    id: "component.jsc.filterDevice",
    defaultMessage: "Filtra per dispositivo",
  },
  filterEvent: {
    id: "component.jsc.filterEvent",
    defaultMessage: "Filtra per evento",
  },
  filterFactory: {
    id: "component.jsc.filterFactory",
    defaultMessage: "(*) Filtra per stabilimento",
  },
  filterFrom: {
    id: "component.jsc.filterFrom",
    defaultMessage: "Filtra periodo da",
  },
  filterGroup: {
    id: "component.jsc.filterGroup",
    defaultMessage: "Filtra per gruppo",
  },
  filterSource: {
    id: "component.jsc.filterSource",
    defaultMessage: "Filtra per sorgente",
  },
  filterTeam: {
    id: "component.jsc.filterTeam",
    defaultMessage: "Filtra per team",
  },
  filterTo: {
    id: "component.jsc.filterTo",
    defaultMessage: "Filtra periodo a",
  },
  filterWorker: {
    id: "component.jsc.filterWorker",
    defaultMessage: "Filtra per lavoratore",
  },
  fromTime: {
    id: "component.jsc.fromTime",
    defaultMessage: "Dalle",
  },
  history: {
    id: "component.jsc.history",
    defaultMessage: "Storico",
  },
  lastVersion: {
    id: "component.jsc.lastVersion",
    defaultMessage: "Ultima versione: ",
  },
  mandatory: {
    id: "component.jsc.mandatory",
    defaultMessage: "i campi contrasseganti con (*) sono obbligatori",
  },
  messageText: {
    id: "component.jsc.messageText",
    defaultMessage: "Inserire il testo del messaggio da inviare",
  },
  noFile: {
    id: "component.jsc.noFile",
    defaultMessage: "Nessun file uplodato!",
  },
  noAttachment: {
    id: "component.jsc.noAttachment",
    defaultMessage: "Nessun allegato!",
  },
  removeAttachment: {
    id: "component.jsc.removeAttachment",
    defaultMessage: "Allegato rimosso con successo!",
  },
  noResults: {
    id: "component.jsc.noResults",
    defaultMessage: "Non ci sono risultati da mostrare",
  },
  noHistory: {
    id: "component.jsc.noHistory",
    defaultMessage: "Non esistono versioni precedenti nella cronologia!",
  },
  report: {
    id: "component.jsc.report",
    defaultMessage: "report",
  },
  reportAttachments: {
    id: "component.jsc.reportAttachments",
    defaultMessage: "Allegati Report",
  },
  reportCreation: {
    id: "component.jsc.reportCreation",
    defaultMessage: "Creazione report",
  },
  reportDate: {
    id: "component.jsc.reportDate",
    defaultMessage: "Data Report",
  },
  reportTime: {
    id: "component.jsc.reportTime",
    defaultMessage: "Ora Report",
  },
  reportDescription: {
    id: "component.jsc.reportDescription",
    defaultMessage: "Descrizione Report",
  },
  reportDetail: {
    id: "component.jsc.reportDetail",
    defaultMessage: "Dettaglio Report",
  },
  reportHistory: {
    id: "component.jsc.reportHistory",
    defaultMessage: "Storico Report",
  },
  reportInspection: {
    id: "component.jsc.reportInspection",
    defaultMessage: "Ispezione report",
  },
  reportModify: {
    id: "component.jsc.reportModify",
    defaultMessage: "Modifica Report",
  },
  rootHash: {
    id: "component.jsc.rootHash",
    defaultMessage: "Root Hash (hash minato)",
  },
  search: {
    id: "component.jsc.search",
    defaultMessage: "Ricerca",
  },
  searchIntro: {
    id: "component.searchIntro",
    defaultMessage: "Per effettuare una ricerca",
  },
  searchAgain: {
    id: "component.jsc.searchAgain",
    defaultMessage: "Modificare i parametri per effettuare una nuova ricerca",
  },
  selectDepartment: {
    id: "component.jsc.selectDepartment",
    defaultMessage: "Seleziona un reparto",
  },
  selectEvent: {
    id: "component.jsc.selectEvent",
    defaultMessage: "Seleziona un evento",
  },
  selectFactory: {
    id: "component.jsc.selectFactory",
    defaultMessage: "Selezionare almeno uno stabilimento",
  },
  selectWorker: {
    id: "component.jsc.selectWorker",
    defaultMessage: "Seleziona un lavoratore",
  },
  selectWorkerByDept: {
    id: "component.jsc.selectWorkerByDept",
    defaultMessage: "Seleziona un lavoratore (Reparto: {department})",
  },
  sensorDetection: {
    id: "component.jsc.sensorDetection",
    defaultMessage: "Rilevazioni sensori",
  },
  sync: {
    id: "component.jsc.sync",
    defaultMessage: "Dati sincronizzati sulla blockchain.",
  },
  syncAt: {
    id: "component.jsc.syncAt",
    defaultMessage: "Sincronizzazione",
  },
  syncAwait: {
    id: "component.jsc.syncAwait",
    defaultMessage: "Dati in attesa di sincronizzazione sulla blockchain.",
  },
  team: {
    id: "component.jsc.team",
    defaultMessage: "Squadra",
  },
  timeEnd: {
    id: "component.jsc.timeEnd",
    defaultMessage: "Ora fine",
  },
  timeStart: {
    id: "component.jsc.timeStart",
    defaultMessage: "Ora inizio",
  },
  toTime: {
    id: "component.jsc.toTime",
    defaultMessage: "alle",
  },
  transactionHash: {
    id: "component.jsc.transactionHash",
    defaultMessage: "Hash della transazione",
  },
  worker: {
    id: "component.jsc.worker",
    defaultMessage: "Lavoratore",
  },
  saveEventWorker: {
    id: "component.jsc.saveEventWorker",
    defaultMessage: "Salva evento lavoratore",
  },
  administration: {
    id: "component.jsc.administration",
    defaultMessage: "Amministrazione",
  },
  createUser: {
    id: "component.jsc.createUser",
    defaultMessage: "Crea utente",
  },
  addUser: {
    id: "component.jsc.addUser",
    defaultMessage: "Aggiungi utente",
  },
  editUser: {
    id: "component.jsc.editUser",
    defaultMessage: "Modifica utente",
  },
  nameSurname: {
    id: "component.jsc.nameSurname",
    defaultMessage: "Nome e Cognome",
  },
  users: {
    id: "component.jsc.users",
    defaultMessage: "Utenti",
  },
  allUsers: {
    id: "component.jsc.allUsers",
    defaultMessage: "Tutti gli utenti",
  },
  userCreated: {
    id: "component.jsc.userCreated",
    defaultMessage: "Utente creato con successo",
  },
  userUpdate: {
    id: "component.jsc.userUpdate",
    defaultMessage: "Utente aggiornato con successo",
  },
  userDeleted: {
    id: "component.jsc.userDeleted",
    defaultMessage: "Utente eliminato con successo",
  },
  update: {
    id: "component.jsc.update",
    defaultMessage: "Aggiornamento",
  },
  role: {
    id: "component.jsc.role",
    defaultMessage: "Ruolo",
  },
  amministratore: {
    id: "component.jsc.amministratore",
    defaultMessage: "Amministratore",
  },
  capoturno: {
    id: "component.jsc.capoturno",
    defaultMessage: "Capo turno",
  },
  visualizzatore: {
    id: "component.jsc.visualizzatore",
    defaultMessage: "Visualizzatore",
  },
  //events
  infortunio: {
    id: "component.jsc.infortunio",
    defaultMessage: "Infortunio",
  },
  training: {
    id: "component.jsc.training",
    defaultMessage: "Training",
  },
  malattia: {
    id: "component.jsc.malattia",
    defaultMessage: "Malattia",
  },
  straordinario: {
    id: "component.jsc.straordinario",
    defaultMessage: "Straordinario",
  },
  orarionormale: {
    id: "component.jsc.orarionormale",
    defaultMessage: "Orario Normale",
  },
  ferie: {
    id: "component.jsc.ferie",
    defaultMessage: "Ferie",
  },
  permesso: {
    id: "component.jsc.permesso",
    defaultMessage: "Permesso",
  },
  noTrasmit: {
    id: "component.jsc.noTrasmit",
    defaultMessage: "Non ancora trasmesso",
  },
  noFilePermission: {
    id: "component.jsc.noFilePermission",
    defaultMessage: "Uno o più files non sono immagini o documenti pdf!",
  },
  /* graph */
  graphColumns: {
    id: "component.jsc.graphColumns",
    defaultMessage: "Indicare i valori da inserire nel grafico",
  },
  maxColumns: {
    id: "component.jsc.maxColumns",
    defaultMessage: "È possibile selezionare al massimo 5 opzioni.",
  },
  viewGraph: {
    id: "component.jsc.viewGraph",
    defaultMessage: "Visualizza grafico",
  },
});
