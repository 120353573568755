import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, Prompt } from "react-router-dom";
import { addDays, addMonths, endOfDay } from "date-fns";

import { Button, FormControl, Grid, Typography } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import { useBmapi } from "../../utils/bmapi-context";
import { getLabel, useCampaigns } from "../../utils/campaigns";
import { MANAGER_ROUTES, PRODUCT_SUBTYPES } from "../../utils/constants";
import { formatValues, getInitialState } from "../../utils/campaignCreation";
import { getErrorMessageString } from "../../utils/errors";
import {
  common,
  confirm,
  form,
  navigation,
  notifications,
  product,
  types,
} from "../../messages";

import AutoJoin from "./input/AutoJoin";
import Cover from "./input/Cover";
import CodesPrefix from "./input/CodesPrefix";
import Currency from "./input/Currency";
import Demo from "./input/Demo";
import Description from "./input/Description";
import DynamicValue from "./input/DynamicValue";
import EarningMinimumThreshold from "./input/EarningMinimumThreshold";
import ExpirationDate from "./input/ExpirationDate";
import EndHour from "./input/EndHour";
import ExternalIdFormat from "./input/ExternalIdFormat";
import FormSection from "./input/FormSection";
import GracePeriod from "./input/GracePeriod";
import Icon from "./input/Icon";
import LinkDistribution from "./input/LinkDistribution";
import MainEventId from "./input/MainEventId";
import MaxIssueNumber from "./input/MaxIssueNumber";
import MaxProductsPerUser from "./input/MaxProductsPerUser";
import Name from "./input/Name";
import Prizes from "./input/Prizes";
import FrontEndType from "./input/FrontEndType";
import ProductLifespan from "./input/ProductLifespan";
import ProductLimitDate from "./input/ProductLimitDate";
import ProductName from "./input/ProductName";
import Quantum from "./input/Quantum";
import Rate from "./input/Rate";
import ShotNumber from "./input/ShotNumber";
import StartDate from "./input/StartDate";
import StartHour from "./input/StartHour";
import TosConsumerUrl from "./input/TosConsumerUrl";
import TosMerchantUrl from "./input/TosMerchantUrl";
import UseFrequency from "./input/UseFrequency";
import UsePeriod from "./input/UsePeriod";
import Value from "./input/Value";
import WaitingList from "./input/WaitingList";
import ManagerRestriction from "./input/ManagerRestriction";
import Reimbursement from "./input/Reimbursement";
import PrimaryProductMandatory from "./input/PrimaryProductMandatory";
import { parseBmarkenDate } from "../../utils/utils";

function Navigation() {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(navigation.backToCampaigns)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            {intl.formatMessage(navigation.goHome)}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default function CommonCreateForm({
  handleSubmit,
  initialValues,
  onTypeChange,
  performance,
  ...props
}) {
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const { campaigns, loadCampaigns } = useCampaigns();
  const [dirty, setDirty] = useState(false);
  const [errorValidity, setErrorValidity] = useState(false);
  const [errorGracePeriod, setErrorGracePeriod] = useState(false);
  const [errorShotNumber, setErrorShotNumber] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState(initialValues);

  const checkPrizes = useCallback(() => {
    return !values.prizes
      .map(
        (p) =>
          campaigns.find((c) => c.campaign_id === p.prize_id).expiration_date
      )
      .some(
        (exp) => endOfDay(new Date(exp)) < endOfDay(values.product_limit_date)
      );
  }, [values, campaigns]);

  const saveImages = (values) => async (campaignView) => {
    if (typeof values.cover === "object") {
      await bmapi
        .uploadCampaignCover(campaignView.id, values.cover)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
    if (typeof values.icon === "object") {
      await bmapi
        .uploadCampaignIcon(campaignView.id, values.icon)
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
    setDirty(false);
    return campaignView;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!checkPrizes()) {
      return notifyError(
        intl.formatMessage(notifications.wrongPrizeExpiration)
      );
    }

    handleSubmit(formatValues(values), saveImages(values)).catch((e) =>
      notifyError(getErrorMessageString(e, intl))
    );
  };

  const handleChangeProduct = () => (e) => {
    setDirty(false);
    setValues((vs) => getInitialState(e.target.value, bmapi, vs));
  };

  const handleChangePeriodType = (label, e, val) => {
    setDirty(true);
    setValues((v) => ({
      ...v,
      [label]: e.target.value,
    }));
    if (performance?.issued_qty > 0 && label === "product_lifespan_type") {
      isGreater(val, e.target.value);
    }
  };

  const handleChange = useCallback(
    (label, newLifeSpanType) => {
      const updateValue = (val) => {
        setDirty(true);
        setValues((v) => ({ ...v, [label]: val }));
        if (performance?.issued_qty > 0 && label === "product_lifespan") {
          isGreater(val, newLifeSpanType);
          if (PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE) {
            let prizesToModify = [];
            for (let i = 0; i < values.prizes.length; i++) {
              const prize = campaigns?.find(
                (c) => values.prizes[i].prize_id === c.campaign_id
              );
              const prizeExp = addDays(
                addMonths(
                  parseBmarkenDate(prize.campaign_data.expiration_date),
                  prize.campaign_data.product_lifespan
                ),
                prize.campaign_data.grace_period
              );
              const newDate = addDays(
                values.product_lifespan_type !== "D"
                  ? addMonths(values.expiration_date, val)
                  : addDays(values.expiration_date, val),
                values.grace_period
              );
              if (newDate > prizeExp) {
                prizesToModify.push(values.prizes[i].name);
              }
            }
            if (prizesToModify.length) {
              setValues((v) => ({
                ...v,
                product_lifespan: props.campaign.product_lifespan,
                product_lifespan_type: props.campaign.product_lifespan_type,
              }));
              alert(
                `Per estendere la durata di questa campagna è necessario posticipare la data di scadenza dei premi: ${prizesToModify.join(
                  ", "
                )}`
              );
            }
          }
        } else if (performance?.issued_qty > 0 && label === "grace_period") {
          if (+val < props.campaign.grace_period && +val !== 0) {
            setErrorGracePeriod(true);
          } else setErrorGracePeriod(false);
        } else if (performance?.issued_qty > 0 && label === "shot_number") {
          if (
            (props.campaign.rules.shot_number === -1 && +val !== -1) ||
            (props.campaign &&
              +val !== -1 &&
              +val < props.campaign.rules.shot_number)
          ) {
            setErrorShotNumber(true);
          } else {
            setErrorShotNumber(false);
          }
        }
      };

      return (i, f) => {
        if (typeof f === "boolean") updateValue(f);
        else if (i?.target) updateValue(i.target.value);
        else updateValue(i);
      };
    },
    [isGreater, performance, campaigns, values, props]
  );

  const isGreater = useCallback(
    (val, newLifeSpanType) => {
      const lifeSpanType = props.campaign.product_lifespan_type;
      const pLifeSpan = props.campaign.product_lifespan;
      const pExpDate = props.campaign.expiration_date;
      if (lifeSpanType === "M" || lifeSpanType === "") {
        switch (lifeSpanType === "M" || lifeSpanType === "") {
          case newLifeSpanType === "M" || newLifeSpanType === "":
            if (+val < pLifeSpan && +val !== 0) {
              setErrorValidity(true);
            } else setErrorValidity(false);
            break;
          case newLifeSpanType === "D":
            if (
              addDays(parseBmarkenDate(pExpDate), +val) <
                addMonths(parseBmarkenDate(pExpDate), pLifeSpan) &&
              +val !== 0
            ) {
              setErrorValidity(true);
            } else setErrorValidity(false);
            break;
        }
      } else if (lifeSpanType === "D") {
        switch (lifeSpanType === "D") {
          case newLifeSpanType === "D":
            if (+val < pLifeSpan && +val !== 0) {
              setErrorValidity(true);
            } else setErrorValidity(false);
            break;
          case newLifeSpanType === "M":
            if (
              addDays(parseBmarkenDate(pExpDate), pLifeSpan) >
                addMonths(parseBmarkenDate(pExpDate), +val) &&
              +val !== 0
            ) {
              setErrorValidity(true);
            } else setErrorValidity(false);
            break;
        }
      }
    },
    [props]
  );

  useEffect(() => {
    if (
      !campaigns &&
      [
        PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
        PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      ].includes(values.subtype)
    ) {
      loadCampaigns();
    }
  }, [values.subtype, campaigns, loadCampaigns]);

  useEffect(() => {
    onTypeChange && onTypeChange(values.front_end_type);
  }, [onTypeChange, values.front_end_type]);

  const inputProps = {
    campaign: props.campaign,
    campaigns,
    handleChange,
    values,
    productType: getLabel(values.subtype),
    performance,
    handleChangePeriodType,
    errorValidity,
    errorGracePeriod,
    setError,
  };

  return (
    <React.Fragment>
      <Prompt when={dirty} message={intl.formatMessage(confirm.exitPrompt)} />

      <form onSubmit={onSubmit}>
        <FormSection>
          <FrontEndType {...inputProps} handleChange={handleChangeProduct} />
          {!!values.subtype && (
            <Typography variant="caption">
              {intl.formatMessage(types[values.front_end_type].description)}
            </Typography>
          )}
        </FormSection>

        {!!values.subtype && (
          <React.Fragment>
            <FormSection>
              <MainEventId {...inputProps} />
              <Name {...inputProps} />
              <ProductName {...inputProps} />
              <Description {...inputProps} />
              <CodesPrefix {...inputProps} />
              <StartDate {...inputProps} />
              <StartHour {...inputProps} />
              <ExpirationDate {...inputProps} />
              <EndHour {...inputProps} />
              <TosConsumerUrl {...inputProps} />
              <TosMerchantUrl {...inputProps} />
              <LinkDistribution {...inputProps} />
              <Demo {...inputProps} />
              <AutoJoin {...inputProps} />
              <WaitingList {...inputProps} />
              <PrimaryProductMandatory {...inputProps} />
              <ManagerRestriction {...inputProps} />
            </FormSection>

            <FormSection title={intl.formatMessage(form.images)}>
              <Cover {...inputProps} />
              <Icon {...inputProps} />
            </FormSection>

            {values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
              <FormSection
                title={intl.formatMessage(product[inputProps.productType].info)}
              >
                <ProductLimitDate {...inputProps} />
                <ProductLifespan {...inputProps} />
                <GracePeriod {...inputProps} />
              </FormSection>
            )}

            <FormSection title={intl.formatMessage(form.details)}>
              <DynamicValue {...inputProps} />
              {values.subtype !== "SIMPLE_COUPON" && <Value {...inputProps} />}
              <Currency {...inputProps} />
              <ShotNumber {...inputProps} />
              <Rate {...inputProps} />
              <Quantum {...inputProps} />
            </FormSection>

            {values.subtype === PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE && (
              <FormSection title={intl.formatMessage(common.prizes)}>
                <Prizes {...inputProps} />
              </FormSection>
            )}

            {values.subtype !== PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN && (
              <FormSection
                collapsed
                title={intl.formatMessage(form.constraints)}
              >
                <MaxIssueNumber {...inputProps} />
                <MaxProductsPerUser {...inputProps} />
                <ExternalIdFormat {...inputProps} />
                <UseFrequency {...inputProps} />
                <UsePeriod {...inputProps} />
                <Reimbursement {...inputProps} />
                <EarningMinimumThreshold {...inputProps} />
              </FormSection>
            )}
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              disabled={
                error || errorGracePeriod || errorValidity || errorShotNumber
              }
            >
              {intl.formatMessage(props.campaign ? common.save : common.create)}
            </Button>
          </React.Fragment>
        )}
      </form>
      <Navigation />
    </React.Fragment>
  );
}
