import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  EmojiEvents,
  Event as EventIcon,
  Info as InfoIcon,
  Share,
} from "@material-ui/icons";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@material-ui/core";

import { common, confirm as confirmMsg } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, PRODUCT_TYPES, TENANTS } from "../utils/constants";
import styles from "../utils/styles";

import Ribbon from "./Ribbon";
import ProductAncot, { ProductAncotEvent } from "./custom/ProductAncot";
import ProductEkom from "./custom/ProductEkom";
import { ProductSelector } from "../components/Product";
import Confirm from "./Confirm";

const PlayButton = ({ campaignId, productId }) => (
  <Button
    component={RouterLink}
    to={CONSUMER_ROUTES.PLAY.replace(":campaignId", campaignId).replace(
      ":productId",
      productId
    )}
    startIcon={<EmojiEvents />}
    color="primary"
  >
    <FormattedMessage id="component.product.play" defaultMessage="Gioca" />
  </Button>
);

const CalendarButton = ({ campaignId }) => (
  <Button
    component={RouterLink}
    to={CONSUMER_ROUTES.CALENDAR.replace(":campaignId", campaignId)}
    startIcon={<EventIcon />}
    color="primary"
  >
    <FormattedMessage
      id="component.product.calendar"
      defaultMessage="Partecipa agli eventi"
    />
  </Button>
);

export function getShareUrl(baseUrl, permissionId) {
  return (
    baseUrl +
    CONSUMER_ROUTES.RECEIVE.slice(1).replace(":permissionId", permissionId)
  );
}

const DonateButton = ({ campaign, onUpdate, products }) => {
  const { baseUrl, bmapi, notifySuccess } = useBmapi();
  const intl = useIntl();
  const [confirm, setConfirm] = useState(false);
  const [product, setProduct] = useState(false);
  const [permission, setPermission] = useState(false);

  const shareUrl = getShareUrl(baseUrl, permission.short_code);

  const copy = useCallback(() => {
    return navigator.clipboard?.writeText(shareUrl).then(() =>
      notifySuccess(
        intl.formatMessage({
          id: "component.friend.urlCopied",
          defaultMessage: "Link copiato",
        })
      )
    );
  }, [intl, notifySuccess, shareUrl]);

  const share = useCallback(() => {
    setProduct(false);

    return navigator.share
      ? navigator.share({ url: shareUrl }).catch(copy)
      : copy();
  }, [copy, shareUrl]);

  const getPermission = () => {
    return bmapi
      .getSendProductPermission(product)
      .then(setPermission)
      .then(onUpdate);
  };

  const openConfirm = (cluster) => setProduct(products[cluster][0].id);

  useEffect(() => {
    setConfirm(!!product);
  }, [product]);

  useEffect(() => {
    if (permission) share();
  }, [permission, share]);

  return (
    <>
      <Confirm
        open={!!confirm}
        onConfirm={getPermission}
        onCancel={() => setProduct(false)}
        text={intl.formatMessage(confirmMsg.shareProduct)}
      />
      <ProductSelector
        campaign={campaign}
        products={products}
        onSelect={openConfirm}
      >
        {(onClick) => (
          <Button onClick={onClick} startIcon={<Share />} color="primary">
            <FormattedMessage
              id="component.product.donate"
              defaultMessage="Condividi"
            />
          </Button>
        )}
      </ProductSelector>
    </>
  );
};

export const CardTop = ({
  campaign,
  children,
  image,
  link,
  ratio,
  ribbon,
  subtitle,
  title,
}) => {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const [bg] = useState(
    image || campaign.image_url || bmapi.createBg(campaign.name)
  );

  return (
    <CardActionArea
      component={link ? RouterLink : undefined}
      to={link || undefined}
    >
      {(ribbon || campaign.demo) && (
        <Ribbon label={ribbon || intl.formatMessage(common.demo)} />
      )}
      <CardMedia
        className={classes.cardMedia}
        image={bg}
        style={ratio ? { paddingTop: `${ratio * 100}%` } : {}}
      />
      {(title !== false || subtitle !== false) && (
        <CardHeader
          title={typeof title === "string" ? title : campaign.name}
          titleTypographyProps={{ variant: "h5" }}
          subheader={
            typeof subtitle === "string" ? subtitle : campaign.business_name
          }
        />
      )}
      {children && <CardContent>{children}</CardContent>}
    </CardActionArea>
  );
};

export const PrimaryActions = ({
  campaign,
  canPlay,
  canShare,
  cta,
  firstProductId,
  hasEvents,
  link,
  onUpdate,
  products,
}) => {
  return (
    <Box>
      {hasEvents && (
        <CalendarButton
          campaignId={campaign.rules.main_event_id || campaign.id}
        />
      )}
      {cta}
      {link && canPlay && (
        <PlayButton campaignId={campaign.id} productId={firstProductId} />
      )}
      {canShare && (
        <DonateButton
          campaign={campaign}
          onUpdate={onUpdate}
          products={products}
        />
      )}
    </Box>
  );
};

export const SecondaryActions = ({
  campaign,
  canPlay,
  firstProductId,
  hasPrize,
  link,
}) => {
  const classes = styles.useStyles();

  return (
    <Box className={classes.cardSecondaryActions}>
      {link && (
        <IconButton component={RouterLink} to={link}>
          {hasPrize ? <EmojiEvents color="primary" /> : <InfoIcon />}
        </IconButton>
      )}
      {!link && canPlay && (
        <PlayButton campaignId={campaign.id} productId={firstProductId} />
      )}
    </Box>
  );
};

function Default({
  campaign,
  canPlay,
  canShare,
  cta,
  firstProductId,
  hasEvents,
  hasPrize,
  hasProducts,
  link = false,
  onUpdate,
  products,
  textDateInfo,
  textStatus,
}) {
  const classes = styles.useStyles();

  return (
    <Card className={classes.card}>
      <CardTop campaign={campaign} link={link}>
        {hasProducts && (
          <React.Fragment>
            <Typography variant="body2">{textStatus}</Typography>
            <Typography variant="body2">{textDateInfo}</Typography>
          </React.Fragment>
        )}
      </CardTop>

      <CardActions disableSpacing>
        <PrimaryActions
          campaign={campaign}
          canPlay={canPlay}
          canShare={canShare}
          cta={cta}
          firstProductId={firstProductId}
          hasEvents={hasEvents}
          link={link}
          onUpdate={onUpdate}
          products={products}
        />

        <SecondaryActions
          campaign={campaign}
          canPlay={canPlay}
          firstProductId={firstProductId}
          hasPrize={hasPrize}
          link={link}
        />
      </CardActions>
    </Card>
  );
}

export default function Product(props) {
  const { bmapi } = useBmapi();

  if (bmapi.tenant === TENANTS.EKOM) {
    return <ProductEkom {...props} />;
  }

  if (
    bmapi.settings.theme === TENANTS.ANCOT &&
    props.campaign.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS
  ) {
    return props.campaign.rules.main_event_id === "" ? (
      <ProductAncot {...props} />
    ) : (
      <ProductAncotEvent {...props} />
    );
  }

  return <Default {...props} />;
}
