import React, { useCallback, useEffect, useState } from "react";
import { useBmapi } from "../../utils/bmapi-context";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import { Add, Create, Delete, MoreVert } from "@material-ui/icons";
import {
  Box,
  Button,
  Card,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { common, confirm, jsc } from "../../messages";
import Title from "../../ui/Title";
import { Autocomplete } from "@material-ui/lab";
import { getErrorMessageString } from "../../utils/errors";
import { JSC_ROLES } from "../../utils/constants";
import Confirm from "../../ui/Confirm";

const useStyles = makeStyles(() =>
  createStyles({
    font: {
      fontSize: "1.2em",
    },
  })
);

function Administration() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const classes = useStyles();
  const [workers, setWorkers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [worker, setWorker] = React.useState("-");
  const [openDialog, setOpenDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [roleFilter, setRoleFilter] = React.useState(null);
  const [values, setValues] = useState({});

  useEffect(() => {
    startLoading();
    Promise.all([bmapi.getWorkers(), bmapi.getUserRole()])
      .then(([woks, users]) => {
        setWorkers(woks);
        setUsers(users);
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
  }, [bmapi, notifyError, startLoading, stopLoading]);

  const loadUsers = useCallback(() => {
    startLoading();
    Promise.all([bmapi.getUserRole()])
      .then(([users]) => {
        setUsers(users);
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
  }, [bmapi, notifyError, startLoading, stopLoading]);

  const handleValue = useCallback(
    (varName) => (e) => {
      //setDirty(true);
      ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
    },
    []
  );

  const handleClose = () => {
    setOpenDialog(false);
    setValues({});
    setEdit(false);
  };

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setWorker(user);
  };

  const handleConfirmUser = () => {
    if (!edit) {
      const user = { ...values, worker_id: worker.worker_id };
      bmapi
        .createUserRole(user)
        .then(() => {
          loadUsers();
        })
        .then(() => {
          notifySuccess(intl.formatMessage(jsc.userCreated));
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
      setOpenDialog(false);
      setValues({});
    } else {
      const user = { id: values.id, role: values.role };
      bmapi
        .updateUserRole(user)
        .then(() => {
          loadUsers();
        })
        .then(() => {
          notifySuccess(intl.formatMessage(jsc.userUpdate));
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
      setOpenDialog(false);
      setValues({});
      setEdit(false);
    }
  };

  const handleDeleteUser = () => {
    bmapi
      .deleteUserRole(worker.id)
      .then(() => {
        loadUsers();
        setDeleteRequest(false);
      })
      .then(() => {
        notifySuccess(intl.formatMessage(jsc.userDeleted));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const loadUser = () => {
    bmapi
      .getUserRoleById({ userrole: worker.id })
      .then((resp) => {
        if (!resp) {
          resp = [];
        } else {
          setValues({
            ...resp,
            name: workers.find((w) => w.worker_id === resp.worker_id)
              .worker_name,
          });
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  console.log(worker);

  return (
    <Container maxWidth="md">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>{intl.formatMessage(jsc.users)}</Box>
          {users.length > 0 && (
            <Button onClick={(event) => setFilterAnchorEl(event.currentTarget)}>
              <FormattedMessage id="common.filter" defaultMessage="Filtro" />
            </Button>
          )}
        </Box>
        <Menu
          anchorEl={filterAnchorEl}
          keepMounted={false}
          open={Boolean(filterAnchorEl)}
          onClose={() => setFilterAnchorEl(null)}
        >
          <MenuItem onClick={() => setRoleFilter(0)}>
            {intl.formatMessage(jsc.allUsers)}
          </MenuItem>
          {Object.entries(JSC_ROLES).map((option) => (
            <MenuItem
              key={option[0]}
              onClick={() => setRoleFilter(option[0])}
              //selected={option[0] === currentStore}
            >
              {option[1]}
            </MenuItem>
          ))}
        </Menu>
      </Title>

      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.font}>
                {intl.formatMessage(jsc.nameSurname)}
              </TableCell>
              <TableCell className={classes.font}>Email</TableCell>
              <TableCell className={classes.font}>
                {intl.formatMessage(jsc.role)}
              </TableCell>
              <TableCell className={classes.font}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((u) => (roleFilter ? roleFilter === u.role : u))
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.font}
                  >
                    {user.worker_id
                      ? workers.find((w) => w.worker_id == user.worker_id)
                          .worker_name
                      : "Admin"}
                  </TableCell>
                  <TableCell className={classes.font}>{user.email}</TableCell>
                  <TableCell className={classes.font}>
                    {JSC_ROLES[user.role]}
                  </TableCell>
                  <TableCell className={classes.font}>
                    <IconButton
                      onClick={(e) => {
                        handleClick(e, user);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setWorker(null);
        }}
        onClick={() => {
          setAnchorEl(null);
          //setWorker(null);
        }}
      >
        <MenuItem
          onClick={() => {
            loadUser();
            setEdit(true);
            setOpenDialog(true);
          }}
        >
          <ListItemIcon>
            <Create fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.modify)}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeleteRequest(true)}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.delete)}
          </Typography>
        </MenuItem>
      </Menu>

      {!!deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={handleDeleteUser}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteElement)}
          flag
        />
      )}

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>
          {edit
            ? intl.formatMessage(jsc.editUser)
            : intl.formatMessage(jsc.addUser)}
        </DialogTitle>
        <DialogContent>
          {edit ? (
            <TextField
              disabled={edit}
              value={values.name}
              margin="dense"
              label={intl.formatMessage(jsc.nameSurname)}
              fullWidth
              InputLabelProps={{
                shrink: values.name,
              }}
            />
          ) : (
            <Autocomplete
              onChange={(event, newValue) => {
                setWorker(workers.find((w) => w.id === newValue?.id));
              }}
              id="combo-box-demo"
              options={workers.filter(
                (w) => !users.find((u) => u.worker_id === w.worker_id)
              )}
              getOptionLabel={(option) =>
                option.worker_name + " " + "(" + option.fiscal_code + ")"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage(jsc.selectWorker)}
                />
              )}
            />
          )}
          <TextField
            disabled={edit}
            value={values.email}
            onChange={handleValue("email")}
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            InputLabelProps={{
              shrink: values.email,
            }}
          />
          <TextField
            value={values.role ? values.role : ""}
            onChange={handleValue("role")}
            select
            label={intl.formatMessage(jsc.role)}
            fullWidth
          >
            {Object.entries(JSC_ROLES).map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {intl.formatMessage(
                  jsc[option[1].replace(" ", "").toLowerCase()]
                )}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {intl.formatMessage(common.cancel)}
          </Button>
          <Button onClick={handleConfirmUser} color="primary">
            {edit
              ? intl.formatMessage(common.update)
              : intl.formatMessage(common.add)}
          </Button>
        </DialogActions>
      </Dialog>

      <FloatingActions>
        <Action
          icon={<Add />}
          label={intl.formatMessage(jsc.createUser)}
          action={() => setOpenDialog(true)}
        />
      </FloatingActions>
    </Container>
  );
}

export default Administration;
