import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";

import Confirm from "../ui/Confirm";
import FloatingActions, { Action } from "../ui/FloatingActions";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import {
  common,
  confirm,
  form,
  notifications,
  subscriptions,
} from "../messages";
import styles from "../utils/styles";
import PlanLimitAlert from "../ui/PlanLimitAlert";
// import LimitProgress from "../ui/LimitProgress";
import { BUSINESS_TYPES } from "../utils/constants";

const byName = (a, b) => a.name.localeCompare(b.name);

function LoopElement({ loop, onModify, onDelete, update }) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi, businesses, notifySuccess, notifyError } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [store, setStore] = useState("");
  const [stores, setStores] = useState([]);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const handleCloseClick = () => setExpanded(false);
  const handleExpandClick = () => setExpanded(true);

  const handleDelete = () => {
    setLoading(true);
    setAnchorEl(null);
    onDelete(loop.id);
  };

  const handleModify = () => {
    setAnchorEl(null);
    onModify(loop);
  };

  const handleAddStoreBtn = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleDeleteStore = () => {
    setSaving(true);
    return bmapi
      .deleteLoopBusiness(deleteRequest.id, loop.id)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.storeRemoved));
        setDeleteRequest(false);
        updateStores();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const handleAddStore = (e) => {
    e.preventDefault();
    setSaving(true);
    handleExpandClick();

    bmapi
      .addLoopBusiness(store, loop.id)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.storeAdded));
        setOpenDialog(false);
        updateStores();
        setStore("");
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortStores = (a, b) => a.name.localeCompare(b.name);

  const availableStores = 1;

  const updateStores = useCallback(() => {
    bmapi.getLoopBusinesses(loop.id).then((res) => {
      setStores(res);
      update();
    });
  }, [bmapi, loop.id, update]);

  useEffect(() => {
    if (expanded) updateStores();
  }, [bmapi, loop.id, updateStores, expanded]);

  return (
    <React.Fragment>
      {!!deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={handleDeleteStore}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteStore, deleteRequest)}
          flag
        />
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleAddStore}>
          <DialogTitle>
            <FormattedMessage
              id="component.manageLoops.addStore"
              defaultMessage="Aggiungi negozio"
            />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.loop)}
              value={loop.name}
              disabled
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(common.store)}
              value={store}
              onChange={(e) => setStore(e.target.value)}
              required
              fullWidth
              select
            >
              {businesses
                .filter((b) => b.type === BUSINESS_TYPES.MERCHANT)
                .sort(byName)
                .map(({ name, id }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} disabled={saving}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {intl.formatMessage(common.add)}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      <ListItem
        button
        onClick={expanded ? handleCloseClick : handleExpandClick}
      >
        <ListItemText
          primary={
            <React.Fragment>
              {loop.name}{" "}
              {loop.demo && (
                <Chip
                  component="span"
                  label="demo"
                  size="small"
                  className={classes.demoChip}
                />
              )}
            </React.Fragment>
          }
          primaryTypographyProps={{ variant: "h6" }}
          secondary={intl.formatMessage(
            {
              id: "component.manageLoops.storesNum",
              defaultMessage:
                "{stores, plural, one {# negozio} other {# negozi}}",
            },
            { stores: loop.business_ids?.length || 0 }
          )}
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{loop.name}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleAddStoreBtn}
                disabled={availableStores < 1}
              >
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLoops.addStore"
                    defaultMessage="Aggiungi negozio"
                  />
                  {availableStores < 1 && (
                    <small>
                      {" "}
                      [{intl.formatMessage(subscriptions.noSlots)}]
                    </small>
                  )}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLoops.editLoop"
                    defaultMessage="Modifica circuito"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLoops.deleteLoop"
                    defaultMessage="Elimina circuito"
                  />
                </Typography>
              </MenuItem>
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {!!stores.length && (
          <ListItem>
            <ListItemText
              primary={
                <React.Fragment>
                  {stores.sort(alphaSortStores).map((s) => (
                    <Typography variant="body2" key={s.name}>
                      {s.name}{" "}
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => setDeleteRequest(s)}
                      >
                        {intl.formatMessage(common.delete)}
                      </Button>
                    </Typography>
                  ))}
                </React.Fragment>
              }
            />
          </ListItem>
        )}
      </Collapse>
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => ({
  description: bs.description || "",
  name: bs.name || "",
});

export default function ManageLoops() {
  const intl = useIntl();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [loops, setLoops] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [values, setValues] = useState(initialValues());

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const updateLoops = (ls) => {
    setLoops(ls.filter((l) => l.status === 0) || []);
  };

  const handleDelete = (id) => {
    bmapi
      .deleteLoop(id)
      .then(() => bmapi.getLoops().then(updateLoops))
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLoops.loopRemoved",
            defaultMessage: "Circuito eliminato con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleModify = (loop) => {
    setValues(initialValues(loop));
    setCurrentId(loop.id);
    setOpenDialog(true);
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .saveLoop(values, currentId)
      .then(() => bmapi.getLoops().then((bs) => updateLoops(bs || [])))
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLoops.loopSaved",
            defaultMessage: "Circuito salvato con successo",
          })
        );
        clear();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const byName = (a, b) => a.name.localeCompare(b.name);

  const update = useCallback(() => {
    setLoading(true);
    bmapi
      .getLoops()
      .then((ls) => updateLoops(ls || []))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setLoading(false));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    update();
  }, [update]);

  const createLink = (str) => (
    <Link onClick={() => setOpenDialog(true)}>{str}</Link>
  );

  const limitReached = false;

  return (
    <Container maxWidth="sm">
      <Title>
        {intl.formatMessage({
          id: "pages.loops.title",
          defaultMessage: "Circuiti",
        })}
      </Title>
      {/* {bmapi.subscription &&
        bmapi.subscription.plan_limits.max_business > 0 && (
          <LimitProgress
            value={loops?.length || 0}
            limit={bmapi.subscription.plan_limits.max_business}
          />
        )} */}

      <Card>
        {(!loops || loading) && <LinearProgress />}
        <Dialog
          open={openDialog && (!limitReached || currentId)}
          onClose={clear}
        >
          <form onSubmit={handleCreate}>
            <DialogTitle>
              {currentId ? (
                <FormattedMessage
                  id="component.manageLoops.editLoop"
                  defaultMessage="Modifica circuito"
                />
              ) : (
                <FormattedMessage
                  id="component.manageLoops.createLoop"
                  defaultMessage="Crea circuito"
                />
              )}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(form.name)}
                value={values.name}
                onChange={handleValue("name")}
                required
                fullWidth
              />
              <TextField
                margin="dense"
                label={intl.formatMessage(form.description)}
                value={values.description}
                onChange={handleValue("description")}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={clear} disabled={saving}>
                <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {currentId ? (
                  <FormattedMessage id="common.save" defaultMessage="Salva" />
                ) : (
                  <FormattedMessage id="common.create" defaultMessage="Crea" />
                )}
              </Button>
            </DialogActions>
          </form>
          {saving && <LinearProgress />}
        </Dialog>

        <PlanLimitAlert
          open={openDialog && limitReached && !currentId}
          onClose={clear}
        />

        {loops &&
          (!loops.length ? (
            <CardContent>
              <Typography gutterBottom>
                <FormattedMessage
                  id="component.manageLoops.noLoopRegistered"
                  defaultMessage="Nessun circuito ancora registrato."
                />
              </Typography>
              <Typography>
                <FormattedMessage
                  id="component.manageLoops.emptyAction"
                  defaultMessage="Crea il primo circuito cliccando sul bottone a fondo pagina o <link>qui</link>."
                  values={{ link: createLink }}
                />
              </Typography>
            </CardContent>
          ) : (
            <List component="div" disablePadding>
              {loops.sort(byName).map((l, i) => (
                <React.Fragment key={l.id}>
                  {i !== 0 ? <Divider component="li" /> : null}
                  <LoopElement
                    loop={l}
                    onDelete={handleDelete}
                    onModify={handleModify}
                    update={update}
                  />
                </React.Fragment>
              ))}
            </List>
          ))}
        <FloatingActions>
          <Action
            icon={<Add />}
            label={intl.formatMessage({
              id: "component.manageLoops.createLoop",
              defaultMessage: "Crea circuito",
            })}
            action={() => setOpenDialog(true)}
          />
        </FloatingActions>
      </Card>
    </Container>
  );
}
