import { defineMessages } from "react-intl";

export const campaign = defineMessages({
  campaignEnded: {
    id: "component.product.dateInfoExpired",
    defaultMessage: "Campagna terminata",
  },
  couponDiscount: {
    id: "common.campaign.couponDiscount",
    defaultMessage: "Coupon sconto %",
  },
  couponMulti: {
    id: "common.campaign.couponMulti",
    defaultMessage: "Coupon multiuso",
  },
  couponShort: {
    id: "pages.dashboard.chart.coupon",
    defaultMessage: "Coupon",
  },
  couponSimple: {
    id: "common.campaign.couponSimple",
    defaultMessage: "Coupon semplice",
  },
  couponValue: {
    id: "common.campaign.couponValue",
    defaultMessage: "Coupon sconto fisso",
  },
  coursePass: {
    id: "common.campaign.coursePass",
    defaultMessage: "Corso scolastico",
  },
  eventStart: {
    id: "component.product.dateInfoEvent",
    defaultMessage: "Inizio evento {start_date, date}",
  },
  eventStartTime: {
    id: "component.product.dateInfoEventTime",
    defaultMessage: "Inizio evento {start_date, date} alle ore {start_hour}",
  },
  fidelity: {
    id: "common.campaign.fidelity",
    defaultMessage: "Fidelity",
  },
  fidelityShort: {
    id: "pages.dashboard.chart.fidelity",
    defaultMessage: "Fidelity",
  },
  instantwinShort: {
    id: "pages.dashboard.chart.instantwin",
    defaultMessage: "InstantWin",
  },
  multiwinShort: {
    id: "pages.dashboard.chart.multiwin",
    defaultMessage: "MultiWin",
  },
  giftcard: {
    id: "common.campaign.giftcard",
    defaultMessage: "Giftcard",
  },
  giftcardShort: {
    id: "pages.dashboard.chart.giftcard",
    defaultMessage: "Giftcard",
  },
  eventPass: {
    id: "common.campaign.eventPass",
    defaultMessage: "Pass evento",
  },
  eventPassShort: {
    id: "pages.dashboard.chart.eventPass",
    defaultMessage: "Eventi",
  },
  nextExpiration: {
    id: "component.product.dateInfoValidProducts",
    defaultMessage: `Prossima scadenza: {next_expiration, date}`,
  },
  noProductsAvailables: {
    id: "common.actions.noProductsAvailables",
    defaultMessage: "Disponibilità esaurita",
  },
  provisioning: {
    id: "common.campaign.provisioning",
    defaultMessage: "Pacchetto prestazioni",
  },
  willEnd: {
    id: "component.product.dateInfoOngoing",
    defaultMessage: `La campagna terminerà il {expiration_date, date}`,
  },
  balance: {
    id: "common.balance",
    defaultMessage: "Saldo",
  },
  receivedPoints: {
    id: "common.receivedPoints",
    defaultMessage: "Punti ricevuti",
  },
  usedPoints: {
    id: "common.usedPoints",
    defaultMessage: "Punti usati",
  },
  expiredPoints: {
    id: "common.expiredPoints",
    defaultMessage: "Punti scaduti",
  },
  receivedCards: {
    id: "common.receivedCards",
    defaultMessage: "Carte ricevute",
  },
  remainingValue: {
    id: "common.remainingValue",
    defaultMessage: "Valore residuo",
  },
  usedValue: {
    id: "common.usedValue",
    defaultMessage: "Valore usato",
  },
  sharedValue: {
    id: "common.sharedValue",
    defaultMessage: "Valore donato",
  },
  expiredValue: {
    id: "common.expiredValue",
    defaultMessage: "Valore scaduto",
  },
  availablesCoupons: {
    id: "common.availablesCoupons",
    defaultMessage: "Coupon disponibili",
  },
  receivedCoupons: {
    id: "common.receivedCoupons",
    defaultMessage: "Coupon ricevuti",
  },
  usedCoupons: {
    id: "common.usedCoupons",
    defaultMessage: "Coupon usati",
  },
  sharedCoupons: {
    id: "common.sharedCoupons",
    defaultMessage: "Coupon donati",
  },
  sharingExpiration: {
    id: "common.sharingExpiration",
    defaultMessage:
      "Scadenza condivisione: {expiration_date, date} {expiration_date, time, short}",
  },
  expiredCoupons: {
    id: "common.expiredCoupons",
    defaultMessage: "Coupon scaduti",
  },
  availablePasses: {
    id: "common.availablePasses",
    defaultMessage: "Pass disponibili",
  },
  receivedPasses: {
    id: "common.receivedPasses",
    defaultMessage: "Pass ricevuti",
  },
  usedPasses: {
    id: "common.usedPasses",
    defaultMessage: "Pass usati",
  },
  sharedPasses: {
    id: "common.sharedPasses",
    defaultMessage: "Pass donati",
  },
  expiredPasses: {
    id: "common.expiredPasses",
    defaultMessage: "Pass scaduti",
  },

  couponReceived: {
    id: "component.campaign.couponReceived",
    defaultMessage:
      "{counter, plural, one {Ricevuto coupon} other {Ricevuti # coupon}}",
  },
  couponReceivedInfo: {
    id: "component.campaign.couponReceivedInfo",
    defaultMessage: "{date, date} presso {store}",
  },
  couponUsed: {
    id: "component.campaign.couponUsed",
    defaultMessage:
      "{counter, plural, one {Coupon usato} other {Usati # coupon}}",
  },
  couponExpired: {
    id: "component.campaign.couponExpired",
    defaultMessage:
      "{counter, plural, one {Coupon scaduto} other {# coupon scaduti}}",
  },
  passReceived: {
    id: "component.campaign.passReceived",
    defaultMessage:
      "{counter, plural, one {Ricevuto pass} other {Ricevuti # pass}}",
  },
  passUsed: {
    id: "component.campaign.passUsed",
    defaultMessage: "{counter, plural, one {Pass usato} other {Usati # pass}}",
  },
  passExpired: {
    id: "component.campaign.passExpired",
    defaultMessage:
      "{counter, plural, one {Pass scaduto} other {# pass scaduti}}",
  },
  cardsReceived: {
    id: "component.campaign.cardsReceived",
    defaultMessage:
      "{counter, plural, one {Ricevuta card} other {Ricevute # card}}",
  },
  cardValueUsed: {
    id: "component.campaign.cardValueUsed",
    defaultMessage: "Utilizzati {value} {currency}",
  },
  emptyCard: {
    id: "component.campaign.emptyCard",
    defaultMessage:
      "{counter, plural, one {Card esaurita} other {# card esaurite}}",
  },
  cardExpired: {
    id: "component.campaign.cardExpired",
    defaultMessage:
      "{counter, plural, one {Card scaduta} other {# card scadute}}",
  },
  prizeReceived: {
    id: "component.campaign.prizeReceived",
    defaultMessage: "Ricevuto premio",
  },
  cardReceived: {
    id: "component.campaign.cardReceived",
    defaultMessage: "Ricevuta card",
  },
  pointsUsed: {
    id: "component.campaign.pointsUsed",
    defaultMessage:
      "{value, plural, one {Utilizzato 1 punto} other {Utilizzati # punti}}",
  },
  pointsReceived: {
    id: "component.campaign.pointsReceived",
    defaultMessage:
      "{value, plural, =0 {Effettuato un acquisto} one {Ottenuto 1 punto} other {Ottenuti # punti}}",
  },
  bonusReceived: {
    id: "component.campaign.bonusReceived",
    defaultMessage: "Ricevuto bonus",
  },
  friendBonusReceived: {
    id: "component.campaign.friendBonusReceived",
    defaultMessage: 'Ricevuto bonus "Porta un amico"',
  },
  weeklyBonusReceived: {
    id: "component.campaign.weeklyBonusReceived",
    defaultMessage: "Ricevuto bonus settimanale",
  },
  availablePlays: {
    id: "component.campaign.availablePlays",
    defaultMessage: "Giocate disponibili",
  },
  usedPlays: {
    id: "component.campaign.usedPlays",
    defaultMessage: "Giocate effettuate",
  },
  played: {
    id: "component.campaign.played",
    defaultMessage:
      "{counter, plural, one {Giocata effettuata} other {# giocate effettuate}}",
  },
  win: {
    id: "component.campaign.win",
    defaultMessage:
      "{counter, plural, one {Premio vinto} other {# premi vinti}}",
  },

  giftcardBalance: {
    id: "component.campaign.giftcardBalance",
    defaultMessage: "Valore disponibile: {balance} {currency}",
  },
  expiration: {
    id: "component.campaign.expiration",
    defaultMessage: "Scadenza: {expirationDate, date, short}",
  },
  eventPassInfoRestricted: {
    id: "component.campaign.eventPassInfoRestricted",
    defaultMessage: "{counter} pass utilizzabile presso {restrictions}",
  },
  eventPassInfo: {
    id: "component.campaign.eventPassInfo",
    defaultMessage:
      "{counter} pass {counter, plural, one {disponibile} other {disponibili}}",
  },
  couponValueInfoRestricted: {
    id: "component.campaign.couponValueInfoRestricted",
    defaultMessage:
      "{counter} coupon [{value} {currency}] utilizzabile presso {restrictions}",
  },
  couponValueInfo: {
    id: "component.campaign.couponValueInfo",
    defaultMessage:
      "{counter} coupon [{value} {currency}] {counter, plural, one {disponibile} other {disponibili}}",
  },
  couponInfoRestricted: {
    id: "component.campaign.couponInfoRestricted",
    defaultMessage: "{counter} coupon utilizzabile presso {restrictions}",
  },
  couponInfo: {
    id: "component.campaign.couponInfo",
    defaultMessage:
      "{counter} coupon {counter, plural, one {disponibile} other {disponibili}}",
  },

  prizeReceivedMessage: {
    id: "component.campaign.prizeReceivedMessage",
    defaultMessage: "Premio riscosso con successo",
  },

  participatingStores: {
    id: "component.campaign.participatingStores",
    defaultMessage: "Negozi aderenti all'iniziativa",
  },
  loading: {
    id: "component.campaign.loading",
    defaultMessage: "Loading...",
  },
  allStores: {
    id: "component.campaign.allStores",
    defaultMessage: "Tutti i negozi del circuito",
  },
  noStores: {
    id: "component.campaign.noStores",
    defaultMessage: "Nessun negozio ha ancora aderito alla campagna",
  },

  getPrizes: {
    id: "common.prizes.get",
    defaultMessage: "Premi",
  },
  redeemPrizeAction: {
    id: "component.campaign.redeemPrizeAction",
    defaultMessage: "Richiedi",
  },
  details: {
    id: "component.campaign.details",
    defaultMessage: "Dettagli campagna",
  },
  termOfService: {
    id: "component.campaign.termOfService",
    defaultMessage: "Leggi i <link>termini e condizioni del servizio</link>",
  },
  sharedProducts: {
    id: "component.campaign.sharedProducts",
    defaultMessage: "Prodotti condivisi",
  },
  newNotification: {
    id: "component.campaignNotifications.newNotification",
    defaultMessage: "Nuova notifica",
  },
  createdNotification: {
    id: "component.campaignNotifications.createdNotification",
    defaultMessage: "Nuova notifica creata con successo",
  },
});
