import React, { useState } from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import { langs } from "../utils/dictionaries";

export default function LanguageSelector() {
  const { bmapi, language } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = (event) => setAnchorEl(event.currentTarget);
  const close = () => setAnchorEl(null);

  const selectLang = (l) => {
    close();
    bmapi.setTenantLanguage(l.value);
  };

  const currentLang = langs.find((l) => l.value === language);

  return (
    bmapi.settings.languages.length > 1 && (
      <React.Fragment>
        <Button
          onClick={open}
          endIcon={<ArrowDropDown />}
          variant="contained"
          style={{ marginLeft: 10, border: "1px solid black" }}
          disableElevation
        >
          <currentLang.Flag
            title={currentLang.label}
            style={{ margin: "5px 0", width: 24 }}
          />
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
          {langs
            .filter((l) => bmapi.settings.languages.includes(l.value))
            .map((l) => (
              <MenuItem
                onClick={() => selectLang(l)}
                key={l.value}
                selected={l.value === language}
              >
                <l.Flag
                  style={{
                    margin: "5px 10px 5px 0",
                    width: 24,
                  }}
                />
                {l.label}
              </MenuItem>
            ))}
        </Menu>
      </React.Fragment>
    )
  );
}
