import React from "react";
import { useBmapi } from "../../utils/bmapi-context";
import { Container } from "@material-ui/core";
import { useIntl } from "react-intl";
import { getErrorMessageString } from "../../utils/errors";
import { jsc } from "../../messages";
import { useHistory } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";
import Title from "../../ui/Title";
import EventCreateForm from "../../ui/forms/EventCreateForm";

function EventBuilder() {
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const intl = useIntl();
  const history = useHistory();

  function onSubmit(data) {
    let link_attachments = data.link_attachments;
    delete data.link_attachments;
    bmapi
      .saveEventWorker(data)
      .then((res) => {
        uploadFiles(res, link_attachments);
      })
      .then(() => {
        setTimeout(goToInspection, parseFloat(link_attachments.length) * 1000);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  }

  function handleBack() {
    history.push(MANAGER_ROUTES.EVENT_INSPECTION);
  }

  function uploadFiles(res, link_attachments) {
    if (bmapi) {
      link_attachments?.map((option) => {
        bmapi
          .addMediaContent(res.id, option.name, option, "Event")
          .then(() => {
            notifySuccess(intl.formatMessage(jsc.eventWorkerCreate));
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      });
    }
  }

  function goToInspection() {
    window.location.href = MANAGER_ROUTES.EVENT_INSPECTION;
  }

  return (
    <div className="p-2 bg-white">
      <Container maxWidth="sm" className="tablistNone">
        <Title>{intl.formatMessage(jsc.eventCreate)}</Title>
        <EventCreateForm
          handleBack={handleBack}
          handleSubmit={onSubmit}
          style={{ background: "red" }}
        />
      </Container>
    </div>
  );
}

export default EventBuilder;
