import { useCallback, useState } from "react";

export function useForm(initialValues) {
  const [values, setValues] = useState(
    Object.keys(initialValues).reduce(
      (a, k) => ({
        ...a,
        [k]: initialValues[k],
        //typeof initialValues[k] === "object"
        //  ? initialValues[k].default
        //  : initialValues[k],
      }),
      {}
    )
  );

  const handleChange = useCallback((valueLabel) => {
    const updateValue = (val) => {
      setValues((v) => ({
        ...v,
        [valueLabel]:
          typeof initialValues[valueLabel].format === "function"
            ? initialValues[valueLabel].format(val)
            : val,
      }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (typeof f === "string") updateValue(JSON.parse(f));
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [values, handleChange];
}
