import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { DatePicker } from "../../ui/DatePicker";
import { useIntl } from "react-intl";
import {
  form,
  common,
  errors,
  confirm,
  navigation,
  account,
} from "../../messages";
import { wikiMuseo } from "../../messages/wikiMuseo";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MediaContentForm from "./MediaContentForm";
import { useBmapi } from "../../utils/bmapi-context";
import {
  checkNullArray,
  compareByTitle,
  //compareTranslations,
  convertToSlug,
  decodeFromArray,
  //getEmptyArray,
  getEmptyMap,
  //getFields,
  getMapFields,
  intlFormatMessage,
  isInArray,
  parseBmarkenDate,
} from "../../utils/utils";
import { getErrorMessageString } from "../../utils/errors";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ArrowBack as ArrowBackIcon,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import {
  ACTIVITIES,
  DISABILITIES,
  LANGUAGES,
  SUPPORTS,
  TRANSPORTS,
} from "../../utils/wikimuseoConstants";
import Confirm from "../../ui/Confirm";
import ChallengeCreate from "../../components/ChallengeCreate";
import AddressAutocomplete2 from "../../components/AddressAutocomplete2";
import CheckTranslations from "../../ui/CheckTranslations";
import FieldTranslation from "../../components/FieldTranslation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Prompt } from "react-router-dom";

const CustomButton = () => <span className="octicon octicon-star" />;

const CustomToolbar = ({ toolbar }) => (
  <div id={toolbar} className="mt25">
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" />
      <option value="2" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-clean" />
    <button className="ql-insertStar">
      <CustomButton />
    </button>
  </div>
);

function insertStar() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "★");
  this.quill.setSelection(cursorPosition + 1);
}

function modules(toolbar) {
  return {
    toolbar: {
      container: toolbar,
      handlers: {
        insertStar: insertStar,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };
}

export default function Tour() {
  const getEmptyPeriod = (arr) =>
    !arr || arr.length === 0 ? [{ from: null, to: null }] : arr;

  const [values, setValues] = useState({
    title: "",
    subtitle: "",
    description: "",
    duration: "",
    outdoor: false,
    with_guide: false,
    period: getEmptyPeriod(null),
    tour_languages: [],
    activities: [],
    accessibility: [],
    accessibility_support: [],
    tour_transports: [],
    points_of_interest: [],
    meeting_place: {},
    location: {},
    friendly_url: "",
    priority: "",
    private: false,
  });

  /*const [valuesLng, setValuesLng] = useState({
    title: [],
    subtitle: [],
    description: [],
    accessibility_description: [],
    accessibility_url: [],
    friendly_url: [],
  });*/

  const [valuesLng, setValuesLng] = useState({
    id: new Map(),
    title: new Map(),
    subtitle: new Map(),
    description: new Map(),
    accessibility_description: new Map(),
    accessibility_url: new Map(),
    friendly_url: new Map(),
  });

  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const history = useHistory();
  const [filter, setFilter] = useState("general");
  const [mediaContentValues, setMediaContentValues] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [localizedMedias, setLocalizedMedias] = useState(null);
  const [allowAudioUpload, setAllowAudioUpload] = useState(null);
  const [allow3DModelUpload, setAllow3DModelUpload] = useState(null);
  const [allowVideoUpload, setAllowVideoUpload] = useState(null);
  const [poiToRemove, setPoiToRemove] = React.useState(null);
  const [selectedPoi, setSelectedPoi] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [allPois, setAllPois] = useState([]);
  const [showPage, setShowPage] = useState(true); // false -> set true
  const [openDialogQuestion, setOpenDialogQuestion] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [questions, setQuestions] = useState(new Map());

  const showLocation = bmapi.getUserInfo().business.show_location;
  const showActivities = bmapi.getUserInfo().business.show_activities;
  const showOutdoor = bmapi.getUserInfo().business.show_outdoor;
  const showTourTransports = bmapi.getUserInfo().business.show_tour_transports;
  const showTourPrices = bmapi.getUserInfo().business.show_tour_prices;
  const attributionMandatory = bmapi.getUserInfo().business
    .attribution_mandatory;
  const subId = bmapi.getUserInfo().business.subscription_id;
  const [valueDescriptionRichEditor, setValueDescriptionRichEditor] = useState(
    ""
  );
  const [
    valueAccessibilityDescriptionRichEditor,
    setValueAccessibilityDescriptionRichEditor,
  ] = useState("");

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllowAudioUpload(sub[0].plan_limits.upload_audio);
  });

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllowVideoUpload(sub[0].plan_limits.upload_video);
  });

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllow3DModelUpload(sub[0].plan_limits.upload_3dmodel);
  });

  const handleValue = useCallback(
    (label) => (e) => {
      setDirty(true);
      if (label === "outdoor" || label === "with_guide") {
        ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.checked);
      } else if (label === "title") {
        ((val) => {
          setValues((v) => ({
            ...v,
            [label]: val,
            ["friendly_url"]: convertToSlug(val),
          }));
        })(e.target.value);
      } else {
        if (label == "private") {
          ((val) => setValues((v) => ({ ...v, [label]: val })))(
            e.target.checked
          );
        } else {
          ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
        }
      }
    },
    []
  );

  /*const handleValuesLng = useCallback(
    (varName) => (index) => (e) => {
      const value = [...valuesLng[varName]];
      value[index] = e.target.value;
      const newValuesLng = { ...valuesLng, [varName]: value };
      setValuesLng(newValuesLng);
    },
    [valuesLng]
  );*/

  const handleValuesLng = useCallback(
    (varName) => (lang) => (e) => {
      setDirty(true);
      const value = valuesLng[varName];
      if (
        varName === "accessibility_description" ||
        varName === "description"
      ) {
        value.set(lang, e);
      } else {
        value.set(lang, e.target.value);
      }
      const newValuesLng = { ...valuesLng, [varName]: value };
      setValuesLng(newValuesLng);
    },
    [valuesLng]
  );

  const handleDateValue = useCallback(
    (label, index) => (e) => {
      setDirty(true);
      const value = e;
      const newValues = { ...values };
      newValues.period[index][label] = value;
      setValues(newValues);
    },
    [values]
  );

  const handleAddDate = useCallback(
    (index) => () => {
      setDirty(true);
      const newValues = { ...values };
      newValues.period.splice(index + 1, 0, {
        from: null,
        to: null,
      });
      setValues(newValues);
    },
    [values]
  );

  const handleDeleteDate = useCallback(
    (index) => () => {
      setDirty(true);
      const newValues = { ...values };
      newValues.period.splice(index, 1);
      if (!newValues.period.length) {
        newValues.period.push({ from: null, to: null });
      }
      setValues(newValues);
    },
    [values]
  );

  const deleteFromArray = (field, value) => () => {
    let newArr = [];
    values[field].map((item) => {
      if (item !== value) {
        newArr.push(item);
      }
    });
    setDirty(true);
    setValues((v) => ({
      ...v,
      [field]: newArr,
    }));
  };

  const addPoi = () => {
    const newPois = [...values.points_of_interest];
    if (values.poi) {
      newPois.push(values.poi);
    }
    setDirty(true);
    setValues({
      ...values,
      points_of_interest: newPois,
    });
  };

  const removePoi = (index) => {
    const newPois = [...values.points_of_interest];
    newPois.splice(index, 1);
    setDirty(true);
    setValues({
      ...values,
      points_of_interest: newPois,
    });
  };

  const isSelected = (poi) => {
    const filtered = values.points_of_interest.filter((obj) => obj === poi.id);
    return filtered && filtered.length > 0;
  };

  const comparePoiOrder = (poi1, poi2) => {
    return (
      values.points_of_interest.indexOf(poi1.id) -
      values.points_of_interest.indexOf(poi2.id)
    );
  };

  const priorityUp = (i) => {
    const newPoiOrder = [...values.points_of_interest];
    let temp = newPoiOrder[i];
    newPoiOrder[i] = newPoiOrder[i - 1];
    newPoiOrder[i - 1] = temp;
    setDirty(true);
    setValues({
      ...values,
      points_of_interest: newPoiOrder,
    });
  };

  const priorityDown = (i) => {
    const newPoiOrder = [...values.points_of_interest];
    let temp = newPoiOrder[i];
    newPoiOrder[i] = newPoiOrder[i + 1];
    newPoiOrder[i + 1] = temp;
    setDirty(true);
    setValues({
      ...values,
      points_of_interest: newPoiOrder,
    });
  };

  const selectedPois = allPois.filter((poi) => isSelected(poi));
  selectedPois.sort(comparePoiOrder);

  const languageList = [
    bmapi.getUserInfo().business.default_language,
    ...availableLanguages,
  ];

  const handleAddQuestion = (poi) => {
    setSelectedPoi(poi);
    setOpenDialogQuestion(true);
  };

  const closeDialogueQuestion = () => {
    setOpenDialogQuestion(false);
  };

  const handleAddressSelected = (addr) => {
    setDirty(true);
    /*let address = {
      city: addr.city,
      state: addr.country,
      zip: addr.postalCode,
      province: addr.province,
      street: addr.route,
    };*/
    const newValues = { ...values, meeting_place: addr };
    setValues(newValues);
  };

  const handleLocationSelected = (addr) => {
    setDirty(true);
    /*let address = {
      city: addr.city,
      state: addr.country,
      zip: addr.postalCode,
      province: addr.province,
      street: addr.route,
    };*/
    const newValues = { ...values, location: addr };
    setValues(newValues);
  };

  const tabSwitch = (v) => {
    if (!dirty || (dirty && filter === "multimedia")) {
      setFilter(v);
    }
    if (v !== "general" && filter === "general" && dirty) {
      const res = window.confirm(intl.formatMessage(confirm.tabPrompt));
      if (res === true) {
        setFilter(v);
      }
    }
  };

  const checkTranslation = (poi) => {
    let check = [];
    const newQuestions = questions.get(poi.id);
    const quest = newQuestions
      .map((q) => q.localized_questions)
      .flat()
      .filter((q) => q.lang !== bmapi.getUserInfo().business.default_language)
      .map((q) => q.text !== "");
    for (let i = 0; i < availableLanguages.length; i++) {
      while (quest.length < availableLanguages.length) {
        quest.push(false);
      }
    }
    check.push(quest);
    const answ = newQuestions
      .map((q) => q.localized_questions)
      .flat()
      .filter((q) => q.lang !== bmapi.getUserInfo().business.default_language)
      .map((q) => q.answers.map((q) => q.text))
      .flat()
      .map((q) => q !== "");
    for (let i = 0; i < availableLanguages.length; i++) {
      while (answ.length < availableLanguages.length) {
        answ.push(false);
      }
    }
    check.push(answ);
    return check.flat();
  };

  useEffect(() => {
    const defaultLang = bmapi.getUserInfo().business.default_language;
    const availableLang = bmapi
      .getUserInfo()
      .business.available_languages.filter((lang) => lang !== defaultLang);
    setDefaultLanguage(defaultLang);
    availableLang.sort();
    setAvailableLanguages(availableLang);
    bmapi
      .getPOIs({
        lang: bmapi.getUserInfo().business.default_language,
        business: bmapi.getUserInfo().business.id,
      })
      .then((pois) => {
        let newPois = [];
        if (pois) {
          newPois = pois
            .filter((poisChildren) => poisChildren.parent_id !== "")
            .map((poi) => {
              return {
                id: poi.id,
                title: poi.localized_pois[0].title,
                address: { lat: poi.address.lat, lng: poi.address.lng },
              };
            });
          newPois.sort(compareByTitle);
        }
        setAllPois(newPois);
      });
    const tourId = new URLSearchParams(location.search).get("id");
    if (tourId) {
      if (bmapi) {
        loadTour(tourId);
      }
    } else {
      setShowPage(true);
      const newValues = {
        id: getEmptyMap(availableLang),
        title: getEmptyMap(availableLang),
        subtitle: getEmptyMap(availableLang),
        description: getEmptyMap(availableLang),
        accessibility_description: getEmptyMap(availableLang),
        accessibility_url: getEmptyMap(availableLang),
        friendly_url: getEmptyMap(availableLang),
        /*id: getEmptyArray(availableLang.length),
        title: getEmptyArray(availableLang.length),
        subtitle: getEmptyArray(availableLang.length),
        description: getEmptyArray(availableLang.length),
        accessibility_description: getEmptyArray(availableLang.length),
        accessibility_url: getEmptyArray(availableLang.length),
        friendly_url: getEmptyArray(availableLang.length),*/
      };
      setValuesLng(newValues);
    }
  }, [bmapi, loadTour]);

  const defaultTourField = (resp) => {
    let defaultTour = null;
    resp.localized_tours.map((defaultLanguageTour) => {
      if (
        defaultLanguageTour.lang ===
        bmapi.getUserInfo().business.default_language
      ) {
        defaultTour = defaultLanguageTour;
      }
    });
    let parsedPeriods = [];
    resp.period.map((p) => {
      const newTo = parseBmarkenDate(p.to);
      const newFrom = parseBmarkenDate(p.from);
      const dateObj = { from: newFrom, to: newTo };
      parsedPeriods.push(dateObj);
    });
    let tour = {
      id: resp.id,
      title: defaultTour.title,
      subtitle: defaultTour.subtitle,
      description: defaultTour.description,
      accessibility_description: defaultTour.accessibility_description,
      accessibility_url: defaultTour.accessibility_url,
      friendly_url: defaultTour.friendly_url,
      media_contents: defaultTour.media_contents,
      accessibility: checkNullArray(resp.accessibility),
      activities: checkNullArray(resp.activities),
      tour_transports: checkNullArray(resp.tour_transports),
      accessibility_support: checkNullArray(resp.accessibility_support),
      period: getEmptyPeriod(resp.period),
      points_of_interest: checkNullArray(resp.points_of_interest),
      prices: checkNullArray(resp.prices),
      tour_languages: checkNullArray(resp.tour_languages),
    };
    return tour;
  };

  const createLocalizedObj = () => {
    const localizedObj = [];

    for (let i = 0; i < availableLanguages.length; ++i) {
      /*const titleLng = valuesLng.title[i];
      const subtitleLng = valuesLng.subtitle[i];
      const descriptionLng = valuesLng.description[i];
      const accessibility_descriptionLng =
        valuesLng.accessibility_description[i];
      const accessibility_urlLng = valuesLng.accessibility_url[i];
      const friendly_urlLng = valuesLng.friendly_url[i];*/
      localizedObj.push({
        id: valuesLng.id.get(availableLanguages[i]) || "",
        title: valuesLng.title.get(availableLanguages[i]) || "",
        subtitle: valuesLng.subtitle.get(availableLanguages[i]) || "",
        description: valuesLng.description.get(availableLanguages[i]) || "",
        accessibility_description:
          valuesLng.accessibility_description.get(availableLanguages[i]) || "",
        accessibility_url:
          valuesLng.accessibility_url.get(availableLanguages[i]) || "",
        friendly_url: valuesLng.friendly_url.get(availableLanguages[i]) || "",
        /*id: valuesLng.id[i],
        title: titleLng,
        subtitle: subtitleLng,
        description: descriptionLng,
        accessibility_description: accessibility_descriptionLng,
        accessibility_url: accessibility_urlLng,
        friendly_url: friendly_urlLng,*/
        lang: availableLanguages[i],
      });
    }

    localizedObj.push({
      id: values.idDefaultLng,
      title: values.title,
      subtitle: values.subtitle,
      description: valueDescriptionRichEditor,
      accessibility_description: valueAccessibilityDescriptionRichEditor,
      accessibility_url: values.accessibility_url,
      friendly_url: values.friendly_url,
      lang: defaultLanguage,
    });
    return localizedObj;
  };

  const loadTour = useCallback(
    (tourId) => {
      bmapi
        .getTours({ tour: tourId, business: bmapi.getUserInfo().business.id })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
            setShowPage(false);
          } else {
            setShowPage(true);
            const rTour = resp[0];

            let tour = {
              ...rTour,
              accessibility: checkNullArray(rTour.accessibility),
              activities: checkNullArray(rTour.activities),
              tour_transports: checkNullArray(rTour.tour_transports),
              accessibility_support: checkNullArray(
                rTour.accessibility_support
              ),
              period: getEmptyPeriod(rTour.period),
              points_of_interest: checkNullArray(rTour.points_of_interest),
              min_group: rTour.min_group || "",
              min_visitors: rTour.min_visitors || "",
              tour_languages: checkNullArray(rTour.tour_languages),
              private: rTour.private,
            };
            tour.prices = checkNullArray(rTour.prices);
            tour.prices = tour.prices.length === 0 ? [0, 0, 0, 0] : tour.prices;
            tour.base_price = tour.prices[0] / 100 || "";
            tour.reduced_price = tour.prices[1] / 100 || "";
            tour.companion_price = tour.prices[2] / 100 || "";
            tour.remote_price = tour.prices[3] / 100 || "";

            const defaultLang = bmapi.getUserInfo().business.default_language;
            const newLocalizedTours = tour.localized_tours.filter(
              (tour) => tour.lang !== defaultLang
            );
            //newLocalizedTours.sort(compareTranslations);
            const newValues = {
              title: getMapFields(newLocalizedTours, "title", "lang"),
              subtitle: getMapFields(newLocalizedTours, "subtitle", "lang"),
              description: getMapFields(
                newLocalizedTours,
                "description",
                "lang"
              ),
              accessibility_description: getMapFields(
                newLocalizedTours,
                "accessibility_description",
                "lang"
              ),
              accessibility_url: getMapFields(
                newLocalizedTours,
                "accessibility_url",
                "lang"
              ),
              friendly_url: getMapFields(
                newLocalizedTours,
                "friendly_url",
                "lang"
              ),
              id: getMapFields(newLocalizedTours, "id", "lang"),
              /*title: getFields(newLocalizedTours, "title"),
              subtitle: getFields(newLocalizedTours, "subtitle"),
              description: getFields(newLocalizedTours, "description"),
              accessibility_description: getFields(
                newLocalizedTours,
                "accessibility_description"
              ),
              accessibility_url: getFields(
                newLocalizedTours,
                "accessibility_url"
              ),
              friendly_url: getFields(newLocalizedTours, "friendly_url"),
              id: getFields(newLocalizedTours, "id"),*/
            };
            setValuesLng(newValues);
            const newDefaultTour = tour.localized_tours.filter(
              (localizedTour) => localizedTour.lang === defaultLang
            );
            if (newDefaultTour.length === 1) {
              tour = {
                ...tour,
                idDefaultLng: newDefaultTour[0].id,
                title: newDefaultTour[0].title,
                subtitle: newDefaultTour[0].subtitle,
                description: newDefaultTour[0].description,
                accessibility_description:
                  newDefaultTour[0].accessibility_description,
                accessibility_url: newDefaultTour[0].accessibility_url,
                friendly_url:
                  newDefaultTour[0].friendly_url ||
                  (newDefaultTour[0].title &&
                    convertToSlug(newDefaultTour[0].title)),
              };
            }
            setValues(tour);
            setValueDescriptionRichEditor(tour.description);
            setValueAccessibilityDescriptionRichEditor(
              tour.accessibility_description
            );
            const localizedMedia = new Map();
            tour.localized_tours.map((localizedTour) => {
              localizedMedia.set(
                localizedTour.lang,
                localizedTour.media_contents === null
                  ? []
                  : localizedTour.media_contents
              );
            });
            setLocalizedMedias(localizedMedia);
            loadQuestionsList(tourId);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          setShowPage(false);
        });
    },
    [bmapi, intl, notifyError, loadQuestionsList]
  );

  const confirmTour = (event) => {
    event.preventDefault();
    setDirty(false);
    const localizedTours = createLocalizedObj();
    let tour = {
      ...values,
      accessibility: checkNullArray(values.accessibility),
      tour_languages: checkNullArray(values.tour_languages),
      activities: checkNullArray(values.activities),
      tour_transports: checkNullArray(values.tour_transports),
      accessibility_support: checkNullArray(values.accessibility_support),
      points_of_interest: checkNullArray(values.points_of_interest),
      localized_tours: localizedTours,
      duration: parseInt(values.duration),
      priority: parseInt(values.priority),
      ages_discount: [],
      period: !values.period ? [] : values.period,
      media_contents: [],
      prices: checkNullArray([
        +parseFloat(values.base_price) * 100 || 0,
        +parseFloat(values.reduced_price) * 100 || 0,
        +parseFloat(values.companion_price) * 100 || 0,
        +parseFloat(values.remote_price) * 100 || 0,
      ]),
      base_price: +parseFloat(values.base_price) * 100,
      min_group: +values.min_group,
      min_visitors: +values.min_visitors,
      private: values.private,
    };
    if (!tour.id) {
      bmapi
        .createTour(tour)
        .then((resp) => {
          history.push(MANAGER_ROUTES.TOUR + "?id=" + resp.id);
          tour = defaultTourField(resp);
          setValues(tour);
          loadTour(tour.id);
          notifySuccess(intl.formatMessage(account.saveConfirm));
          const localizedMedia = new Map();
          availableLanguages.map((lang) => {
            localizedMedia.set(lang, []);
          });
          localizedMedia.set(defaultLanguage, []);
          setLocalizedMedias(localizedMedia);
          setFilter("multimedia");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateTour(tour)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          history.push(MANAGER_ROUTES.MANAGE_TOUR_LIST);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const loadQuestionsList = useCallback(
    (tourId) => {
      bmapi
        .getQuestions({
          business: bmapi.getUserInfo().business.id,
          tour: tourId,
        })
        .then((resp) => {
          if (resp) {
            const questionMap = new Map();
            resp.map((question) => {
              const poiId = question.poi_id;
              const arr = questionMap.get(poiId);
              if (!arr) {
                questionMap.set(poiId, [question]);
              } else {
                arr.push(question);
                questionMap.set(poiId, arr);
              }
            });
            setQuestions(questionMap);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    },
    [bmapi, intl, notifyError]
  );

  return (
    <Container maxWidth="sm">
      <Prompt when={dirty} message={intl.formatMessage(confirm.exitPrompt)} />
      <Tabs
        value={filter}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => {
          tabSwitch(v);
          //setFilter(v);
        }}
      >
        <Tab
          label={intl.formatMessage(common.generalData)}
          value="general"
          style={{ minWidth: 0 }}
        />
        {values.id && (
          <Tab
            label={intl.formatMessage(common.multimediaData)}
            value="multimedia"
            style={{ minWidth: 0 }}
          />
        )}
      </Tabs>
      <Title>Pagina Percorsi</Title>

      {filter === "general" && (
        <form onSubmit={confirmTour}>
          {showPage && (
            <>
              <Box mb={2}>
                <Card>
                  <CardContent>
                    <FormControl fullWidth required margin="normal">
                      <InputLabel>{intl.formatMessage(form.title)}</InputLabel>
                      <Input
                        type="text"
                        value={values.title}
                        name="title"
                        onChange={handleValue("title")}
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              items={valuesLng.title}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("title")}
                              title={intl.formatMessage(form.title)}
                              language={defaultLanguage}
                            />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(form.subtitle)}
                      </InputLabel>
                      <Input
                        type="text"
                        value={values.subtitle}
                        name="subtitle"
                        onChange={handleValue("subtitle")}
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              items={valuesLng.subtitle}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("subtitle")}
                              title={intl.formatMessage(form.subtitle)}
                              language={defaultLanguage}
                            />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel
                        style={{ fontSize: "13px", margin: "-18px 0px" }}
                      >
                        {intl.formatMessage(form.description)}
                      </InputLabel>
                      <div className="flaggy">
                        <FieldTranslation
                          language={defaultLanguage}
                          items={valuesLng.description}
                          availableLanguages={availableLanguages}
                          onChange={handleValuesLng("description")}
                          title={intl.formatMessage(form.description)}
                          editor={true}
                        ></FieldTranslation>
                      </div>
                      <CustomToolbar toolbar="toolbar" />
                      <ReactQuill
                        onChange={setValueDescriptionRichEditor}
                        placeholder="Inserire la descrizione del punto di interese"
                        modules={modules("#toolbar")}
                        value={valueDescriptionRichEditor}
                        theme="snow"
                      />
                    </FormControl>

                    {values.period &&
                      values.period.map((date, i) => (
                        <>
                          <List margin="normal">
                            <DatePicker
                              label={intl.formatMessage(common.from)}
                              inputFormat="dd/MM/yyyy"
                              value={date.from}
                              onChange={handleDateValue("from", i)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                            <DatePicker
                              label={intl.formatMessage(common.to)}
                              inputFormat="dd/MM/yyyy"
                              value={date.to}
                              onChange={handleDateValue("to", i)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                            <ListItemSecondaryAction>
                              <IconButton onClick={handleAddDate(i)}>
                                <AddIcon />
                              </IconButton>
                              <IconButton onClick={handleDeleteDate(i)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </List>
                        </>
                      ))}
                    <TextField
                      margin="normal"
                      name="duration"
                      label={intl.formatMessage(wikiMuseo.duration)}
                      value={values.duration}
                      onChange={handleValue("duration")}
                      fullWidth
                      type="number"
                      min="0"
                      step="1"
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(common.language)}
                      </InputLabel>
                      <Select
                        name="tour_languages"
                        multiple
                        fullWidth
                        value={values.tour_languages}
                        onChange={handleValue("tour_languages")}
                        input={<Input />}
                        renderValue={(selected) => (
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((lang) => (
                              <Chip
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={deleteFromArray(
                                  "tour_languages",
                                  lang
                                )}
                                key={lang}
                                label={intlFormatMessage(
                                  common[decodeFromArray(LANGUAGES, lang)],
                                  lang,
                                  intl
                                )}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {LANGUAGES.map(
                          (language) =>
                            !isInArray(values.tour_languages, language) && (
                              <MenuItem
                                key={language.value}
                                value={language.value}
                              >
                                {intlFormatMessage(
                                  common[language.label],
                                  language.label,
                                  intl
                                )}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    {showOutdoor && (
                      <FormControl margin="normal">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.outdoor}
                              onChange={handleValue("outdoor")}
                              color="primary"
                            />
                          }
                          label={intl.formatMessage(wikiMuseo.outdoor)}
                        />
                      </FormControl>
                    )}
                    {showActivities && (
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.type)}
                        </InputLabel>
                        <Select
                          name="activities"
                          multiple
                          fullWidth
                          value={values.activities}
                          onChange={handleValue("activities")}
                          input={<Input />}
                          renderValue={(selected) => (
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                              {selected.map((activity) => (
                                <Chip
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onDelete={deleteFromArray(
                                    "activities",
                                    activity
                                  )}
                                  key={activity}
                                  label={intlFormatMessage(
                                    wikiMuseo[
                                      decodeFromArray(ACTIVITIES, activity)
                                    ],
                                    activity,
                                    intl
                                  )}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {ACTIVITIES.map(
                            (activity) =>
                              !isInArray(values.activities, activity.value) && (
                                <MenuItem
                                  key={activity.value}
                                  value={activity.value}
                                >
                                  {intlFormatMessage(
                                    wikiMuseo[activity.label],
                                    activity.label,
                                    intl
                                  )}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    )}
                    <FormControl margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.with_guide}
                            onChange={handleValue("with_guide")}
                            color="primary"
                          />
                        }
                        label={intl.formatMessage(wikiMuseo.guidedTour)}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(wikiMuseo.disabilityType)}
                      </InputLabel>
                      <Select
                        name="disabilities"
                        multiple
                        fullWidth
                        value={values.accessibility}
                        onChange={handleValue("accessibility")}
                        input={<Input />}
                        renderValue={(selected) => (
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((disab) => (
                              <Chip
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={deleteFromArray(
                                  "accessibility",
                                  disab
                                )}
                                key={disab}
                                label={intlFormatMessage(
                                  wikiMuseo[
                                    decodeFromArray(DISABILITIES, disab)
                                  ],
                                  disab,
                                  intl
                                )}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {DISABILITIES.map(
                          (disability) =>
                            !isInArray(
                              values.accessibility,
                              disability.value
                            ) && (
                              <MenuItem
                                key={disability.value}
                                value={disability.value}
                              >
                                {intlFormatMessage(
                                  wikiMuseo[disability.label],
                                  disability.label,
                                  intl
                                )}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      {/*<InputLabel>
                        {intl.formatMessage(wikiMuseo.accessibilityDescription)}
                      </InputLabel>
                      <Input
                        type="text"
                        value={values.accessibility_description}
                        name="accessibility_description"
                        onChange={handleValue("accessibility_description")}
                        multiline
                        shrink="true"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ display: "none" }}
                          >
                            X
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.accessibility_description}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng(
                                "accessibility_description"
                              )}
                              title={intl.formatMessage(
                                wikiMuseo.accessibilityDescription
                              )}
                            ></FieldTranslation>
                          </InputAdornment>
                        }
                      />*/}
                      <InputLabel
                        style={{ fontSize: "13px", margin: "-18px 0px" }}
                      >
                        {intl.formatMessage(wikiMuseo.accessibilityDescription)}
                      </InputLabel>
                      <div className="flaggy">
                        <FieldTranslation
                          language={defaultLanguage}
                          items={valuesLng.accessibility_description}
                          availableLanguages={availableLanguages}
                          onChange={handleValuesLng(
                            "accessibility_description"
                          )}
                          title={intl.formatMessage(
                            wikiMuseo.accessibilityDescription
                          )}
                          editor={true}
                        ></FieldTranslation>
                      </div>
                      <CustomToolbar toolbar="toolbar2" />
                      <ReactQuill
                        onChange={setValueAccessibilityDescriptionRichEditor}
                        theme="snow"
                        placeholder="Inserire la descrizione accessibilità del punto di interese"
                        modules={modules("#toolbar2")}
                        value={valueAccessibilityDescriptionRichEditor}
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(wikiMuseo.accessibilityUrl)}
                      </InputLabel>
                      <Input
                        type="text"
                        value={values.accessibility_url}
                        name="accessibility_url"
                        onChange={handleValue("accessibility_url")}
                        multiline
                        shrink="true"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            style={{ display: "none" }}
                          >
                            X
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.accessibility_url}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("accessibility_url")}
                              title={intl.formatMessage(
                                wikiMuseo.accessibilityUrl
                              )}
                            ></FieldTranslation>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(wikiMuseo.support)}
                      </InputLabel>
                      <Select
                        name="accessibility_support"
                        multiple
                        fullWidth
                        value={values.accessibility_support}
                        onChange={handleValue("accessibility_support")}
                        input={<Input />}
                        renderValue={(selected) => (
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((support) => (
                              <Chip
                                onMouseDown={(event) => {
                                  event.stopPropagation(
                                    "accessibility_support",
                                    support
                                  );
                                }}
                                onDelete={deleteFromArray(
                                  "accessibility_support",
                                  support
                                )}
                                key={support}
                                label={intlFormatMessage(
                                  wikiMuseo[decodeFromArray(SUPPORTS, support)],
                                  support,
                                  intl
                                )}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {SUPPORTS.map(
                          (support) =>
                            !isInArray(
                              values.accessibility_support,
                              support.value
                            ) && (
                              <MenuItem
                                key={support.value}
                                value={support.value}
                              >
                                {intlFormatMessage(
                                  wikiMuseo[support.label],
                                  support.label,
                                  intl
                                )}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    {showTourTransports && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.mode)}
                        </InputLabel>
                        <Select
                          name="tour_transports"
                          multiple
                          fullWidth
                          value={values.tour_transports}
                          onChange={handleValue("tour_transports")}
                          input={<Input />}
                          renderValue={(selected) => (
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                              {selected.map((trans) => (
                                <Chip
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onDelete={deleteFromArray(
                                    "tour_transports",
                                    trans
                                  )}
                                  key={trans}
                                  label={intlFormatMessage(
                                    wikiMuseo[
                                      decodeFromArray(TRANSPORTS, trans)
                                    ],
                                    trans,
                                    intl
                                  )}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {TRANSPORTS.map(
                            (trans) =>
                              !isInArray(
                                values.tour_transports,
                                trans.value
                              ) && (
                                <MenuItem key={trans.value} value={trans.value}>
                                  {intlFormatMessage(
                                    wikiMuseo[trans.label],
                                    trans.label,
                                    intl
                                  )}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    )}
                    <FormControl fullWidth margin="normal" required>
                      <InputLabel>Friendly URL</InputLabel>
                      <Input
                        type="text"
                        value={values.friendly_url}
                        name="friendly_url"
                        onChange={handleValue("friendly_url")}
                        multiline
                        disabled
                        shrink="true"
                        /*endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.friendly_url}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("friendly_url")}
                              title="Friendly URL"
                            />
                          </InputAdornment>
                        }*/
                      />
                    </FormControl>
                    <TextField
                      margin="normal"
                      name="priority"
                      label={intl.formatMessage(wikiMuseo.priority)}
                      value={values.priority}
                      onChange={handleValue("priority")}
                      fullWidth
                      type="number"
                      min="0"
                      step="1"
                      onInput={(e) => {
                        e.target.value = e.target.value.substring(0, 10);
                      }}
                    />
                    <FormControl
                      style={{
                        border: "solid 1px grey",
                        padding: "10px",
                        margin: "10px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.private}
                            color="primary"
                            onChange={handleValue("private")}
                            key="private"
                            name="private"
                          />
                        }
                        label={intl.formatMessage(form.withAuthentication, {
                          entity: "Tour",
                        })}
                      />
                      {intl.formatMessage(form.messageAuthentication, {
                        entity: "tour",
                      })}
                    </FormControl>
                  </CardContent>
                </Card>
              </Box>

              {showLocation && (
                <>
                  <Box mb={2}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" margin="normal">
                          {intl.formatMessage(common.location)}
                        </Typography>
                        <AddressAutocomplete2
                          //label={intl.formatMessage(common.location)}
                          googlePlacesKey="AIzaSyDmTGCjqNH942kSPubb1OblDbDsT2YaIrI"
                          types="(cities)"
                          onChange={handleLocationSelected}
                          address={values.location}
                        />
                      </CardContent>
                      <CardContent>
                        <Typography variant="h6" margin="normal">
                          {intl.formatMessage(wikiMuseo.meetingPlace)}
                        </Typography>
                        <AddressAutocomplete2
                          //label={intl.formatMessage(wikiMuseo.meetingPlace)}
                          googlePlacesKey="AIzaSyDmTGCjqNH942kSPubb1OblDbDsT2YaIrI"
                          types="address"
                          onChange={handleAddressSelected}
                          address={values.meeting_place}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}
            </>
          )}

          <Box>
            <Card>
              <CardContent>
                <TextField
                  margin="normal"
                  label={intl.formatMessage(navigation.pois)}
                  value={values.poi}
                  onChange={handleValue("poi")}
                  fullWidth
                  select
                  defaultValue=""
                >
                  {!!allPois &&
                    allPois.map(
                      (poi) =>
                        !isSelected(poi) && (
                          <MenuItem key={poi.id} value={poi.id}>
                            {poi.title}
                          </MenuItem>
                        )
                    )}
                </TextField>
                <Button onClick={addPoi}>
                  {intl.formatMessage(common.add)}
                </Button>
                {selectedPois && selectedPois.length > 0 && (
                  <List>
                    {selectedPois.map((poi, i) => (
                      <ListItem key={poi.id} value={poi.id}>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              justify="flex-start"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => priorityUp(i)}
                                  disabled={i === 0}
                                >
                                  <ArrowUpward fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => priorityDown(i)}
                                  disabled={i === selectedPois.length - 1}
                                >
                                  <ArrowDownward fontSize="inherit" />
                                </IconButton>
                              </Grid>
                              <Grid item>{poi.title}</Grid>
                            </Grid>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => handleAddQuestion(poi)}>
                            <Badge
                              color="primary"
                              badgeContent={
                                !questions || !questions.get(poi.id)
                                  ? 0
                                  : questions.get(poi.id).length
                              }
                              showZero
                            >
                              <QuestionAnswerIcon />
                            </Badge>
                            <CheckTranslations
                              translations={
                                !questions || !questions.get(poi.id)
                                  ? []
                                  : checkTranslation(poi)
                              }
                              type="questions"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setShowDeleteAlert(true);
                              setPoiToRemove(i);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </CardContent>
              <Confirm
                open={showDeleteAlert}
                onConfirm={() => {
                  removePoi(poiToRemove);
                  setShowDeleteAlert(false);
                }}
                onCancel={() => {
                  setPoiToRemove(null);
                  setShowDeleteAlert(false);
                }}
                text={intl.formatMessage(confirm.deleteElement)}
              />
            </Card>

            {showTourPrices && (
              <Box mt={2} mb={2}>
                <Card>
                  <Accordion margin="normal">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" margin="normal">
                        {intl.formatMessage(wikiMuseo.prices)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.priceBase)}
                        </InputLabel>
                        <Input
                          value={values.base_price}
                          onChange={handleValue("base_price")}
                          min="0"
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.priceReduced)}
                        </InputLabel>
                        <Input
                          value={values.reduced_price}
                          onChange={handleValue("reduced_price")}
                          min="0"
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.priceCompanion)}
                        </InputLabel>
                        <Input
                          value={values.companion_price}
                          min="0"
                          onChange={handleValue("companion_price")}
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.priceRemote)}
                        </InputLabel>
                        <Input
                          value={values.remote_price}
                          onChange={handleValue("remote_price")}
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.minVisitors)}
                        </InputLabel>
                        <Input
                          type="number"
                          value={values.min_visitors}
                          onChange={handleValue("min_visitors")}
                          startAdornment={
                            <InputAdornment position="start">N.</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                    <AccordionDetails>
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(wikiMuseo.minPeople)}
                        </InputLabel>
                        <Input
                          type="number"
                          min="0"
                          value={values.min_group}
                          onChange={handleValue("min_group")}
                          startAdornment={
                            <InputAdornment position="start">N.</InputAdornment>
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </Card>
              </Box>
            )}

            <ChallengeCreate
              openDialogQuestion={openDialogQuestion}
              closeDialogueQuestion={closeDialogueQuestion}
              languageList={languageList}
              availableLanguages={availableLanguages}
              selectedPoi={selectedPoi}
              tourId={values.id}
              questions={selectedPoi ? questions.get(selectedPoi.id) : []}
              loadQuestionsList={loadQuestionsList}
              defaultLanguage={defaultLanguage}
            />

            <CardActions>
              <Button variant="contained" type="submit" color="primary">
                {intl.formatMessage(values.id ? common.update : common.create)}
              </Button>
              {values.id && (
                <>
                  <Button
                    onClick={() => {
                      history.push(MANAGER_ROUTES.MANAGE_TOUR_LIST);
                    }}
                    variant="contained"
                  >
                    {intl.formatMessage(common.cancel)}
                  </Button>
                </>
              )}
            </CardActions>
          </Box>
        </form>
      )}

      {filter === "multimedia" && (
        <MediaContentForm
          media_contents={localizedMedias}
          setMediaContentValues={setMediaContentValues}
          mediaContentValues={mediaContentValues}
          id={values.id}
          selectedPois={selectedPois}
          loadData={loadTour}
          availableLanguages={availableLanguages}
          defaultLanguage={defaultLanguage}
          allowAudioUpload={allowAudioUpload}
          allow3DModelUpload={allow3DModelUpload}
          allowVideoUpload={allowVideoUpload}
          entity_type="Tour"
          available_types={[
            "image",
            "map",
            "audio",
            "video",
            "3dmodel",
            "externalContent",
          ]}
          attributionMandatory={attributionMandatory}
        />
      )}

      <FormControl margin="normal">
        <Button
          onClick={() => history.push(MANAGER_ROUTES.MANAGE_TOUR_LIST)}
          startIcon={<ArrowBackIcon />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </FormControl>
    </Container>
  );
}
