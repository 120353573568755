import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Prompt } from "react-router-dom";
import { addMonths, endOfDay, startOfDay } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { DatePicker } from "../../ui/DatePicker";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import Title from "../../ui/Title";
import { common, confirm } from "../../messages";
import { useCampaigns } from "../../utils/campaigns";
import { PRODUCT_SUBTYPES } from "../../utils/constants";

const PAGE_SIZE = 10;

function Winner({ expandAll, issuePrize, onSelect, selected, update, winner }) {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [expanded, setExpanded] = useState(expandAll);
  const [info, setInfo] = useState(false);

  const handleConfirm = () => {
    return bmapi
      .setWinStatus(
        {
          data_requested: winner.data_requested,
          prize_issued: true,
          send_issue_email: true,
        },
        winner.id
      )
      .then(update)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleIssue = () => {
    issuePrize(winner)
      .then(update)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const contact = () => {
    bmapi
      .setWinStatus(
        {
          data_requested: true,
          prize_issued: winner.prize_issued,
        },
        winner.id
      )
      .then(update);
    // window.open(`mailto:${winner.email}`, "_blank");
  };

  function handleCloseClick() {
    setExpanded(false);
  }

  const loadInfo = useCallback(() => {
    setInfo(false);

    return Promise.all([
      bmapi.getCampaign(winner.prize_id),
      bmapi.getUser(winner.user_id),
    ])
      .then(([{ campaign }, user]) => setInfo({ campaign, user }))
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError, winner.prize_id, winner.user_id]);

  function handleExpandClick() {
    setExpanded(true);
  }

  const copyEmail = () => {
    navigator.clipboard.writeText(winner.email).then(() =>
      notifySuccess(
        intl.formatMessage({
          id: "component.prize.emailCopied",
          defaultMessage: "Indirizzo email copiato",
        })
      )
    );
  };

  const handleChange = (e) => {
    onSelect(winner.id, e.target.checked);
  };

  useEffect(() => {
    if (expanded) loadInfo();
  }, [expanded, loadInfo]);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selected}
            onChange={handleChange}
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          primary={winner.email}
          secondary={
            winner.prize_issued
              ? "Assegnato"
              : winner.data_requested
              ? "Contattato"
              : "Da assegnare"
          }
        />
        <IconButton onClick={expanded ? handleCloseClick : handleExpandClick}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <div>
              <Typography>Premio: {info?.campaign?.name || ""}</Typography>
              <Typography>
                Nome: {info?.user?.complete_name || ""}{" "}
                {info?.user?.last_name || ""}
              </Typography>
              <Typography>
                Email: {winner.email || ""} [
                <Link onClick={copyEmail}>copia</Link>]
              </Typography>
              <Typography>Telefono: {info?.user?.mobile || ""}</Typography>
              <Typography>
                Vincita: {intl.formatDate(new Date(winner.created_at))}{" "}
                {intl.formatTime(new Date(winner.created_at))}
              </Typography>
              <Typography>
                Contatto:{" "}
                {winner.data_requested_at === "0001-01-01T00:00:00Z"
                  ? "-"
                  : `${intl.formatDate(
                      new Date(winner.data_requested_at)
                    )} ${intl.formatTime(new Date(winner.data_requested_at))}`}
              </Typography>
              <Typography>
                Assegnazione:{" "}
                {winner.prize_issued_at === "0001-01-01T00:00:00Z"
                  ? "-"
                  : `${intl.formatDate(
                      new Date(winner.prize_issued_at)
                    )} ${intl.formatTime(new Date(winner.prize_issued_at))}`}
              </Typography>
            </div>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Button variant="contained" onClick={copyEmail}>
                Copia email
              </Button>
              <Button
                variant="contained"
                onClick={contact}
                style={{ marginLeft: 10 }}
              >
                Contatta
              </Button>
              <Button
                variant="contained"
                onClick={winner.prize_issued ? handleConfirm : handleIssue}
                style={{ marginLeft: 10 }}
              >
                {winner.prize_issued ? "Rimanda email" : "Assegna premio"}
              </Button>
            </Box>
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  );
}

const hasPrizes = (campaign) =>
  [
    PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN,
    PRODUCT_SUBTYPES.MULTIWIN_COUPON,
    PRODUCT_SUBTYPES.MULTIWIN_STAMP,
  ].includes(campaign.campaign_data.rules.subtype);

export default function ManagePrizes() {
  const intl = useIntl();
  const { campaigns, loadCampaigns } = useCampaigns();
  const { bmapi, notifyError, startLoading, stopLoading, userId } = useBmapi();
  const [winners, setWinners] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [section, setSection] = useState("new");
  const [filter, setFilter] = useState("");
  const [processing, setProcessing] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [from, setFrom] = useState(addMonths(new Date(), -1));
  const [to, setTo] = useState(new Date());
  const [page, setPage] = useState(1);
  const [selection, setSelection] = useState([]);

  const handleSelect = (id, flag) => {
    setSelection((sel) => (flag ? [...sel, id] : sel.filter((w) => w !== id)));
  };

  const selectAll = () => {
    setSelection(
      selection.length === PAGE_SIZE
        ? []
        : (currentWinners || [])
            .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
            .map((w) => w.id)
    );
  };

  const issueSelected = () => {
    if (selection.length) {
      startLoading();
      setProcessing(true);

      selection
        .map((wid) => winners.find((w) => w.id === wid))
        .reduce((p, x) => p.then(() => issuePrize(x)), Promise.resolve())
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(() => {
          update().then(() => {
            stopLoading();
            setSelection([]);
            setProcessing(false);
          });
        });
    }
  };

  const issuePrize = (winner) => {
    return bmapi.issueCampaign(winner.prize_id, {
      user: winner.email,
      quantity: 1,
      manager_id: userId,
      win_id: winner.id,
    });
  };

  const reset = () => {
    setWinners(false);
    setSection("new");
    setFilter("");
    setExpandAll(false);
    setFrom(addMonths(new Date(), -1));
    setTo(new Date());
    setPage(1);
    setSelection([]);
  };

  const update = useCallback(() => {
    if (campaign) {
      setLoading(true);
      return bmapi
        .getWinners(campaign)
        .then((ws) => setWinners(ws || []))
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(() => setLoading(false));
    }
  }, [bmapi, campaign, intl, notifyError]);

  useEffect(() => {
    reset();
  }, [campaign]);

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (campaigns && campaigns.filter(hasPrizes).length === 1) {
      setCampaign(campaigns.filter(hasPrizes)[0].campaign_id);
    }
  }, [campaigns]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    setPage(1);
  }, [to, from, section]);

  useEffect(() => {
    setSelection([]);
  }, [to, from, page, section]);

  const wins = {
    new: (winners || []).filter((w) => !w.prize_issued && !w.data_requested),
    contacted: (winners || []).filter(
      (w) => !w.prize_issued && w.data_requested
    ),
    assigned: (winners || []).filter((w) => w.prize_issued),
  };

  const currentWinners = wins[section].filter((w) => {
    return (
      (!filter || w.email.includes(filter)) &&
      (section !== "assigned" ||
        ((!from || new Date(w.created_at) > startOfDay(from)) &&
          (!to || new Date(w.created_at) < endOfDay(to))))
    );
  });

  return (
    <Container maxWidth="sm">
      <Prompt
        when={processing}
        message={intl.formatMessage(confirm.processingPrompt)}
      />

      <Title>
        {intl.formatMessage({
          id: "pages.managePrizes.title",
          defaultMessage: "Assegnazione premi",
        })}
      </Title>
      <Box mb={3}>
        <Card>
          <CardContent>
            <TextField
              label={intl.formatMessage(common.campaign)}
              value={campaign}
              onChange={(e) => setCampaign(e.target.value)}
              fullWidth
              select
            >
              {(campaigns || []).filter(hasPrizes).map((campaign) => (
                <MenuItem
                  value={campaign.campaign_id}
                  key={campaign.campaign_id}
                >
                  {campaign.name}
                </MenuItem>
              ))}
            </TextField>
          </CardContent>
        </Card>
      </Box>

      {!!campaign && (
        <>
          <Box mb={3}>
            <Tabs
              value={section}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, v) => setSection(v)}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab value="new" label={`Da assegnare [${wins.new.length}]`} />
              <Tab
                value="contacted"
                label={`Contattati [${wins.contacted.length}]`}
              />
              <Tab
                value="assigned"
                label={`Assegnati [${wins.assigned.length}]`}
              />
            </Tabs>
          </Box>
          <Box mb={3}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Filtra per email"
                  />
                  <Typography display="inline">
                    Filtrati {currentWinners.length}/{wins[section].length}
                  </Typography>
                </Box>
              </CardContent>
              {section === "assigned" && (
                <CardContent>
                  <DatePicker
                    label={intl.formatMessage(common.from)}
                    value={from}
                    onChange={setFrom}
                    fullWidth
                    margin="normal"
                    autoOk
                    clearable
                  />
                  <DatePicker
                    label={intl.formatMessage(common.to)}
                    value={to}
                    onChange={setTo}
                    fullWidth
                    margin="normal"
                    autoOk
                    clearable
                  />
                </CardContent>
              )}
              <CardContent>
                <Button
                  onClick={() => setExpandAll((f) => !f)}
                  variant="contained"
                >
                  {expandAll ? "Chiudi tutti" : "Espandi tutti"}
                </Button>
                <Button
                  onClick={selectAll}
                  variant="contained"
                  style={{ marginLeft: 10 }}
                >
                  {selection.length === PAGE_SIZE
                    ? "Deseleziona tutti"
                    : "Seleziona tutti"}
                </Button>
                {section !== "assigned" && (
                  <Button
                    onClick={issueSelected}
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={selection.length === 0}
                  >
                    Assegna selezionati
                  </Button>
                )}
              </CardContent>
            </Card>
          </Box>
          <Card>
            {(!currentWinners || loading) && <LinearProgress />}
            {Array.isArray(currentWinners) && currentWinners.length === 0 ? (
              <CardContent>
                <Typography>
                  {intl.formatMessage({
                    id: "pages.managePrizes.noWinner",
                    defaultMessage: "Nessun vincitore",
                  })}
                </Typography>
              </CardContent>
            ) : (
              <List component="div" disablePadding>
                {(currentWinners || [])
                  .slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page)
                  .map((winner, i) => (
                    <React.Fragment key={winner.id}>
                      {i !== 0 && <Divider component="li" />}
                      <Winner
                        expandAll={expandAll}
                        issuePrize={issuePrize}
                        onSelect={handleSelect}
                        selected={selection.includes(winner.id)}
                        update={update}
                        winner={winner}
                      />
                    </React.Fragment>
                  ))}
              </List>
            )}
          </Card>
          <Box mt={3}>
            <Pagination
              count={Math.ceil(currentWinners.length / PAGE_SIZE)}
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </Box>
        </>
      )}
    </Container>
  );
}
