import React, { lazy, Suspense, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useIntl } from "react-intl";
import { common } from "../messages/common.js";
import { form } from "../messages/form.js";

const ProvinceList = lazy(() =>
  import(/* webpackChunkName: 'ProvinceList' */ "./ProvinceList.js")
);

/*let autocompleteWidget = { current: null };

function getAddress(places) {
  const addr = {};
  places.map((place) => {
    if (place.types.includes("route")) {
      addr.route = place.short_name;
    }
    if (place.types.includes("locality")) {
      addr.city = place.short_name;
    }
    if (place.types.includes("postal_code")) {
      addr.postalCode = place.short_name;
    }
    if (place.types.includes("administrative_area_level_2")) {
      addr.province = place.short_name;
    }
    if (place.types.includes("country")) {
      addr.country = place.short_name;
    }
  });
  return addr;
}
*/
export default function AddressAutocomplete2({
  //googlePlacesKey,
  //label,
  types,
  onChange,
  address,
  filter,
  required,
  margin,
}) {
  //const [showProvinceSymbol, setShowProvinceSymbol] = React.useState(false);
  //const [loaded, setLoaded] = React.useState(false);
  const intl = useIntl();
  const [province, setProvince] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [route, setRoute] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [lat, setLat] = React.useState("");
  const [lng, setLng] = React.useState("");

  //const provincesList = require(`../configurations/address/provinces-ita.json`);

  const handleValue = useCallback(
    (varName) => (e) => {
      let addressField = varName;
      switch (varName) {
        case "city":
          setCity(e.target.value);
          break;
        case "postalCode":
          setPostalCode(e.target.value);
          addressField = "zip";
          break;
        case "route":
          setRoute(e.target.value);
          addressField = "street";
          break;
        /*case "country":
          setRoute(e.target.value);
          addressField = "state";
          break;*/
        case "province":
          //setShowProvinceSymbol(!showProvinceSymbol);
          setProvince(e.target.value);
          break;
        case "lat":
          setLat(e.target.value);
          addressField = "lat";
          break;
        case "lng":
          setLng(e.target.value);
          addressField = "lng";
          break;
      }
      const addr = {
        city: city,
        state: country,
        zip: postalCode,
        province: province,
        street: route,
        lat: lat ? parseFloat(lat) : 0,
        lng: lng ? parseFloat(lng) : 0,
      };
      if (varName === "lat" || varName === "lng") {
        addr[addressField] = e.target.value ? parseFloat(e.target.value) : 0;
      } else {
        addr[addressField] = e.target.value;
      }
      if (onChange) {
        onChange(addr);
      }
    },
    [
      /*showProvinceSymbol, */ city,
      country,
      onChange,
      postalCode,
      route,
      province,
      lat,
      lng,
    ]
  );

  /*
  const setProvinceView = () => {
    setShowProvinceSymbol(!showProvinceSymbol);
  };

  React.useEffect(() => {
    if (window.google) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", "google-maps");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googlePlacesKey}&libraries=places`;
    script.addEventListener("load", () => setLoaded(true));
    document.querySelector("head").appendChild(script);
  }, [googlePlacesKey]);
  */

  const checkPostalCodeIT = (value) => {
    return !isNaN(parseFloat(value)) && value.length === 5;
  };

  React.useEffect(() => {
    setCity(address?.city || "");
    setProvince(address?.province || "");
    setPostalCode(address?.zip || "");
    setRoute(address?.street || "");
    setLat(address?.lat || "");
    setLng(address?.lng || "");
    //setCountry(filter?.country?.toUpperCase() || address?.state || "");
    setCountry("IT");
  }, [address, filter]);

  /*
  React.useEffect(() => {
    if (!autocompleteWidget.current && loaded) {
      const options =
        filter && filter.country
          ? { componentRestrictions: { country: filter.country.toLowerCase() } }
          : {};
      const input = document.getElementById("addressIds");
      // docs: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      const addrTypes = [types === "address" ? "address" : "(cities)"];
      const widget = new window.google.maps.places.Autocomplete(input, {
        options: options,
        types: addrTypes,
        fields: ["address_components", "geometry"],
      });
      autocompleteWidget.current = widget;
      widget.addListener("place_changed", () => {
        const addr = getAddress(widget.getPlace().address_components);
        setProvince(addr.province);
        setCity(addr.city);
        setCountry(addr.country);
        if (types === "address") {
          setPostalCode(addr.postalCode);
          setRoute(addr.route);
        } else {
          setPostalCode("");
          setRoute("");
        }
        if (onChange) {
          onChange(addr);
        }
        console.log(addr);
      });
    }
  }, [loaded, types, onChange, filter]);
  */

  return (
    <Box>
      <Suspense fallback={<div>...{intl.formatMessage(common.loading)}</div>}>
        <ProvinceList
          required={required}
          province={province}
          handleValue={handleValue("province")}
          margin={margin}
        ></ProvinceList>
      </Suspense>
      <TextField
        value={city}
        label="Città"
        fullWidth
        onChange={handleValue("city")}
        required={required}
        margin={margin}
      />
      {types === "address" && (
        <>
          <TextField
            value={postalCode}
            label="Cap"
            fullWidth
            onChange={handleValue("postalCode")}
            type="number"
            required={required}
            onInput={(e) => {
              e.target.value = e.target.value
                .replace(/\D/g, "")
                .substring(0, 5);
            }}
            error={!!postalCode && !checkPostalCodeIT(postalCode)}
            helperText={
              !!postalCode &&
              !checkPostalCodeIT(postalCode) &&
              intl.formatMessage(form.postalCodeWrongFormat)
            }
            margin={margin}
          />
          <TextField
            value={route}
            label="Via/Piazza e numero"
            fullWidth
            onChange={handleValue("route")}
            required={required}
            margin={margin}
          />
          <TextField
            value={lat}
            label={intl.formatMessage(form.latitude)}
            fullWidth
            onChange={handleValue("lat")}
            type="number"
            inputProps={{ step: "0.1", lang: "en-US" }}
            required={required}
            margin={margin}
          />
          <TextField
            value={lng}
            label={intl.formatMessage(form.longitude)}
            fullWidth
            onChange={handleValue("lng")}
            type="number"
            inputProps={{ step: "0.1", lang: "en-US" }}
            required={required}
            margin={margin}
          />
        </>
      )}
      {/*
      <TextField
        value={country}
        label="Nazione"
        fullWidth
        onChange={handleValue("country")}
      />*/}
    </Box>
  );
}

AddressAutocomplete2.propTypes = {
  /**
   * Address label
   */
  label: PropTypes.string,
  /**
   * Address type
   */
  types: PropTypes.string,
  /**
   * Google Places API key
   * examples: ["address"], ["(cities)"]
   */
  googlePlacesKey: PropTypes.string,
  /**
   * Address selection handler
   */
  onChanged: PropTypes.func,
  /**
   * Prefilled address
   */
  address: PropTypes.object,
  /**
   * Filter (only attribute "country" is implemented, ex: {country: "it"})
   */
  filter: PropTypes.object,
};

AddressAutocomplete2.defaultProps = {
  label: "Address",
  addressType: "address",
  onSelected: undefined,
};
