import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography/Typography";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import {
  reportChecker,
  backDays,
  byCreationDate,
  checkBasicReportData,
} from "../../utils/utils";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import { DatePicker } from "../../ui/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { common, jsc } from "../../messages";
import Title from "../../ui/Title";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { getErrorMessageString } from "../../utils/errors";
import { NOT_VALID_DATE } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function ReportInspection() {
  const [reports, setReports] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);
  const [factories, setFactories] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [startDate, setStartDate] = React.useState(getDefault("startDate"));
  const [endDate, setEndDate] = React.useState(getDefault("endDate"));
  const [worker, setWorker] = React.useState({ id: "-" });
  const [factory, setFactory] = React.useState({ id: "-" });
  const [department, setDepartment] = React.useState(getDefault("department"));
  const [team, setTeam] = React.useState(getDefault("team"));
  const [workerDetail, setWorkerDetail] = React.useState(null);
  const [dialog, setDialog] = React.useState(null);
  const [dialogEdit, setEditDialog] = React.useState(null);
  const [date, setDate] = React.useState(new Date());
  const [timestamp, setTimestamp] = React.useState(
    new Date().toLocaleTimeString().substring(0, 5)
  );
  const [info, setInfo] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const [hideEditButton, setHideEditButton] = React.useState(false);
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [history, setHistory] = React.useState(null);
  const [searchDone, setSearchDone] = React.useState(false);
  const classes = useStyles();
  const intl = useIntl();
  const [linkAttachments, setLinkAttachments] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    loadTable();
    // eslint-disable-next-line
  }, []);

  function loadTable() {
    startLoading();
    Promise.all([
      bmapi.getUserInfo(),
      bmapi.getReports(),
      bmapi.getWorkers(),
      bmapi.getFactories(),
      bmapi.getDepartments(),
      bmapi.getTeams(),
      bmapi.getCompanies(),
    ])
      .then(([info, reps, woks, facs, deps, tms, cmps]) => {
        let workerDepartment;
        facs.unshift({ id: "-", name: intl.formatMessage(jsc.anyFactory) });
        tms.unshift({ id: "-", name: intl.formatMessage(jsc.anyTeam) });
        reps.unshift({
          id: "-",
          description: intl.formatMessage(jsc.anyReport),
        });
        deps.unshift({
          id: "-",
          name: intl.formatMessage(jsc.anyDepartment),
        });
        let workersTemp = woks.sort((a, b) =>
          a.worker_name > b.worker_name ? 1 : -1
        );
        workersTemp.unshift({
          id: "-",
          worker_name: intl.formatMessage(jsc.anyWorker),
        });
        setWorkers(workersTemp);
        setFactories(facs);
        setDepartments(deps);
        setInfo(info);
        if (info.worker_id && info.role != "TM") {
          setFactory(
            facs.find(
              (y) =>
                y.id ==
                woks.find((x) => x.worker_id == info.worker_id).factory_id
            )
          );
          workerDepartment = deps.find(
            (y) =>
              y.id ==
              woks.find((x) => x.worker_id == info.worker_id).department_id
          );
        } else {
          if (facs.length == 1) setFactory(facs[0]);
          workerDepartment = deps[0];
        }
        setDepartment(workerDepartment);
        setTeams(tms);
        setCompanies(cmps);
        return [reps, workerDepartment, startDate, endDate];
      })
      .then((data) => {
        setReports(reportChecker(initData(data[0], data[1], data[2], data[3])));
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
  }

  const columns = [
    {
      name: intl.formatMessage(jsc.department),
      selector: (row) =>
        departments.find((t) => t.id === row.department_id)?.name,
    },
    {
      name: intl.formatMessage(jsc.dateTime),
      selector: (row) =>
        moment(row.report_date, "YYYYMMDD").format("DD/MM/YYYY") +
        " " +
        intl.formatMessage(jsc.atTime) +
        " " +
        row.timestamp,
    },
  ];

  function initData(reps, department, startDate, endDate) {
    let data = {
      reports: reps,
      teams: teams,
      workers: workers,
      factories: factories,
      departments: departments,
    };
    let filters = {
      factory: factory,
      department: department,
      team: team,
      worker: worker,
      startDate: startDate,
      endDate: endDate,
    };
    return { data: data, filters: filters };
  }

  function updateFilters(factory, department, team) {
    if (factory != undefined && factory.id != undefined) {
      setFactory(factories.find((f) => f.id === factory.id));
    } else {
      setFactory({ id: "-" });
    }
    if (department != undefined && department.id != undefined) {
      setDepartment(departments.find((t) => t.id === department.id));
    } else {
      setDepartment({ id: "-" });
    }
    if (team) {
      if (department.id == "-") {
        setTeam("-");
      } else {
        setTeam(teams.find((t) => t.id === team));
      }
    }
  }

  const handleInputFile = (event) => {
    let files = event.target.files;
    let validList = [...files].filter(
      (s) => s.type.includes("image") || s.type.includes("pdf")
    );
    if (validList.length == files.length) {
      linkAttachments.push(...files);
      setLinkAttachments(linkAttachments.slice(0, linkAttachments.length));
    } else {
      alert(intl.formatMessage(jsc.noFilePermission));
    }
  };

  function getDefault(item) {
    if (
      localStorage &&
      localStorage.getItem("jsc_report_filters") != undefined
    ) {
      return JSON.parse(localStorage.getItem("jsc_report_filters"))[item];
    }
    if (item == "startDate") return backDays(7);
    if (item == "endDate") return backDays(0);
    return { id: "-" };
  }

  function openDialog(e) {
    if (!dialog) {
      bmapi
        .getReport(e.id)
        .then((data) => {
          setWorkerDetail(data);
          setDialog("open");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      setDialog(null);
    }
  }

  function openEditDialog() {
    if (!dialogEdit) {
      setValueRichEditor(workerDetail.report_desc);
      setDate(workerDetail.report_date);
      setTimestamp(workerDetail.timestamp);
      //setLinkAttachments(convertMediasToAttachments(workerDetail.medias));
      setEditDialog("open");
    } else {
      setEditDialog(null);
    }
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  function resetFilter(e) {
    e.preventDefault();
    setWorker({ id: "-" });
    if (info.role == "TM") {
      setFactory({ id: "-" });
      setDepartment({ id: "-" });
    }
    setTeam({ id: "-" });
    setTimestamp(getDefault("startDate"));
    setSearchDone(true);
    console.log(companies + teams + departments + factories);
  }

  const updateTable = () => {
    bmapi.getReports().then(function (reports) {
      let data = {
        reports: reports,
        workers: workers,
        teams: teams,
        factories: factories,
        departments: departments,
      };
      let filters = {
        factory: factory,
        department: department,
        team: team,
        worker: worker,
        startDate: startDate,
        endDate: endDate,
      };
      reports = reportChecker({ data: data, filters: filters });
      setReports(reports);
      localStorage.setItem("jsc_report_filters", JSON.stringify(filters));
    });
  };

  const onSubmit = (e) => {
    setErrorRichEditor("");
    e.preventDefault();
    var data = {
      report_desc: valueRichEditor,
      date: date,
      timestamp: timestamp,
    };
    let updateAll = checkBasicReportData(data, workerDetail);
    if (updateAll) {
      bmapi
        .updateReport(workerDetail.id, data)
        .then(() => {
          const promise1 = Promise.resolve(loadAttachment());
          promise1.then((value) => {
            console.log(value);
            notifySuccess(intl.formatMessage(jsc.reportUpdate));
            setEditDialog(null);
            setDialog(null);
            loadTable();
          });
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      const promise1 = Promise.resolve(loadAttachment());
      promise1.then((value) => {
        console.log(value);
        notifySuccess(intl.formatMessage(jsc.reportUpdate));
        setEditDialog(null);
        setDialog(null);
        loadTable();
      });
    }
  };

  function loadAttachment() {
    linkAttachments?.map((option) => {
      bmapi
        .addMediaContent(workerDetail.id, option.name, option, "Report")
        .then(() => {
          //notifySuccess(intl.formatMessage(jsc.eventWorkerUpdate));
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    });
  }

  const removeAttachment = (e) => {
    //id, mediaContentId, entity_type
    let mediaId = workerDetail.medias[e].id;
    bmapi
      .deleteMediaContent(workerDetail.id, mediaId, "Report")
      .then(() => {
        notifySuccess(intl.formatMessage(jsc.removeAttachment));
        workerDetail.medias = workerDetail.medias.filter(
          (x) => x.id != mediaId
        );
        setWorkerDetail(workerDetail);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      ["clean"],
    ],
    //htmlEditButton: { debug: true, syntax: true },
  };

  function showHistory() {
    bmapi
      .getReportHistory({ report: workerDetail.id })
      .then((data) => {
        data = data.sort(byCreationDate).filter((x) => x.version != 1);
        setHistory(data);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    setHideEditButton(true);
  }

  function filterData(type, data, factory, department, team) {
    if (type != "woks" && factory && factory != "-" && factory.id != "-") {
      data = data.filter((d) => d.factory_id == factory.id || d.id == "-");
    }
    if (
      (type == "tms" || type == "woks") &&
      department &&
      department != "-" &&
      department.id != "-"
    ) {
      data = data.filter(
        (d) => d.department_id == department.id || d.id == "-"
      );
    }
    if (type == "woks" && team && team != "-" && team.id != "-") {
      data = data.filter((d) => d.team_id == team.id || d.id == "-");
    }
    return data;
  }

  return (
    <Container maxWidth="md" className="tablistNone">
      <Title>{intl.formatMessage(jsc.reportInspection)}</Title>
      {/* dialog report */}
      {dialog != null && (
        <Dialog
          open={!!dialog}
          maxWidth="sm"
          fullWidth
          onClose={() => setDialog(null)}
          style={{
            padding: "30px",
          }}
        >
          <DialogTitle>
            {intl.formatMessage(jsc.reportDetail)}
            <span
              style={{
                fontSize: "15px",
                position: "absolute",
                right: "20px",
                top: "22px",
              }}
            >
              {" ("}
              {history && !attachment
                ? intl.formatMessage(jsc.history)
                : intl.formatMessage(jsc.lastVersion) +
                  moment(workerDetail.updated_at).format("DD/MM/YYYY HH:mm:ss")}
              {")"}
            </span>
          </DialogTitle>
          <DialogContent style={{ background: "#dbd9d96b" }}>
            {/* cronologia  */}
            {history && !attachment && history.length == 0 && (
              <p>{intl.formatMessage(jsc.noHistory)}</p>
            )}
            {history &&
              !attachment &&
              history.length != 0 &&
              history.map((element) => {
                return (
                  <React.Fragment key={element.id}>
                    <Accordion
                      expanded={expanded === element.id}
                      onChange={handleChange(element.id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={element.id + "bh-content"}
                        id={element.id + "bh-header"}
                      >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          {intl.formatMessage(jsc.update)}
                          {" : "}
                          {new Date(element.created_at).toLocaleString()}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Card>
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.factory)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {
                                      factories.find(
                                        (w) => w.id == workerDetail.factory_id
                                      ).name
                                    }
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.department)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {
                                      departments.find(
                                        (w) =>
                                          w.id == workerDetail.department_id
                                      ).name
                                    }
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.dateTime)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {moment(
                                      workerDetail.report_date,
                                      "YYYYMMDD"
                                    ).format("DD/MM/YYYY") +
                                      " - " +
                                      workerDetail.timestamp}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.reportDescription)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {parse(element.report_desc)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "500",
                                      width: "200px",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.dateInsertion)}
                                    <br></br>
                                    {intl.formatMessage(jsc.dateNotarization)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {moment(element.created_at).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                    <br></br>
                                    {workerDetail.bc_sync_at.indexOf(
                                      NOT_VALID_DATE
                                    ) < 0
                                      ? moment(element.bc_sync_at).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : intl.formatMessage(jsc.noTrasmit)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    {intl.formatMessage(jsc.reportAttachments)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      {element.medias
                                        ? element.medias.map((elem) => {
                                            if (elem.title.indexOf("pdf") < 0) {
                                              return (
                                                <li>
                                                  <a
                                                    href="#"
                                                    onClick={() => {
                                                      setHideEditButton(true);
                                                      setAttachment(elem.url);
                                                    }}
                                                  >
                                                    {elem.title}
                                                  </a>
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li>
                                                  <a
                                                    href={elem.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                      setHideEditButton(true);
                                                      setAttachment(elem.URL);
                                                    }}
                                                  >
                                                    {elem.title}
                                                  </a>
                                                </li>
                                              );
                                            }
                                          })
                                        : intl.formatMessage(jsc.noFile)}
                                    </ul>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Card>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                );
              })}
            {attachment && (
              <Card>
                <img src={attachment} style={{ width: "100%" }} />{" "}
              </Card>
            )}
            {!(history || attachment) && (
              <Card>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      {!history && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.factory)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {
                                factories.find(
                                  (w) => w.id == workerDetail.factory_id
                                ).name
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.department)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {
                                departments.find(
                                  (w) => w.id == workerDetail.department_id
                                ).name
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.dateTime)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {moment(
                                workerDetail.report_date,
                                "YYYYMMDD"
                              ).format("DD/MM/YYYY") +
                                " - " +
                                workerDetail.timestamp}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                                minWidth: "230px",
                              }}
                            >
                              {intl.formatMessage(jsc.reportDescription)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {parse(workerDetail.report_desc)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.dateInsertion)}
                              <br></br>
                              {intl.formatMessage(jsc.dateNotarization)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {moment(workerDetail.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                              <br></br>
                              {workerDetail.bc_first_sync_at.indexOf(
                                NOT_VALID_DATE
                              ) < 0
                                ? moment(workerDetail.bc_first_sync_at).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : intl.formatMessage(jsc.noTrasmit)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.dateLastInsertion)}
                              <br></br>
                              {intl.formatMessage(jsc.dateLastNotarization)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {moment(workerDetail.updated_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                              <br></br>
                              {workerDetail.bc_sync_at.indexOf(NOT_VALID_DATE) <
                              0
                                ? moment(workerDetail.bc_sync_at).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : intl.formatMessage(jsc.noTrasmit)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {intl.formatMessage(jsc.reportAttachments)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              <ul style={{ padding: "0px" }}>
                                {workerDetail.medias
                                  ? workerDetail.medias.map((elem) => {
                                      if (elem.title.indexOf("pdf") < 0) {
                                        return (
                                          <li>
                                            <a
                                              href="#"
                                              onClick={() => {
                                                setHideEditButton(true);
                                                setAttachment(elem.url);
                                              }}
                                            >
                                              {elem.title}
                                            </a>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li>
                                            <a
                                              href={elem.url}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {elem.title}
                                            </a>
                                          </li>
                                        );
                                      }
                                    })
                                  : intl.formatMessage(jsc.noAttachment)}
                              </ul>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Card>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => showHistory()}
              variant="contained"
              color="primary"
              style={{
                margin: "10px",
                background: "black",
                position: "absolute",
                left: "0",
              }}
            >
              {intl.formatMessage(jsc.reportHistory)}
            </Button>
            {info.role != "VI" && !hideEditButton && !history && (
              <Button
                onClick={() => openEditDialog(null)}
                variant="contained"
                color="primary"
                style={{ margin: "10px", background: "rgb(29, 64, 175)" }}
              >
                {intl.formatMessage(common.modify)}
              </Button>
            )}
            <Button
              onClick={() => {
                setHideEditButton(false);
                if (attachment) {
                  setAttachment(null);
                } else if (history) {
                  setHistory(null);
                } else setDialog(null);
              }}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* dialog modifica report */}
      {dialogEdit != null && (
        <Dialog
          open={!!dialogEdit}
          maxWidth="sm"
          fullWidth
          onClose={() => setEditDialog(null)}
          style={{
            padding: "30px",
          }}
        >
          <DialogTitle>{intl.formatMessage(jsc.reportModify)}</DialogTitle>
          <DialogContent style={{ background: "#dbd9d96b" }}>
            <Card>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage(jsc.factory)}</TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {
                          factories.find((w) => w.id == workerDetail.factory_id)
                            .name
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {intl.formatMessage(jsc.department)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {
                          departments.find(
                            (w) => w.id == workerDetail.department_id
                          ).name
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {intl.formatMessage(jsc.dateTime)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        <DatePicker
                          margin="normal"
                          fullWidth
                          minDate={backDays(
                            7,
                            moment(workerDetail.report_date, "YYYYMMDD")
                          )}
                          maxDate={backDays(
                            0,
                            moment(workerDetail.report_date, "YYYYMMDD")
                          )}
                          label={intl.formatMessage(jsc.eventDate)}
                          format="yyyy-MM-dd"
                          value={moment(
                            workerDetail.report_date,
                            "YYYYMMDD"
                          ).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setDate(e);
                            workerDetail.report_date = e.toISOString();
                          }}
                        />
                        <TextField
                          id="time"
                          label={intl.formatMessage(jsc.reportTime)}
                          type="time"
                          defaultValue={workerDetail.timestamp}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={(e) => setTimestamp(e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {intl.formatMessage(jsc.reportDescription)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        <ReactQuill
                          className={errorRichEditor != "" ? "red1d" : ""}
                          theme="snow"
                          modules={modules}
                          value={valueRichEditor}
                          placeholder={intl.formatMessage(jsc.messageText)}
                          onChange={setValueRichEditor}
                          error={errorRichEditor == ""}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {intl.formatMessage(jsc.dateInsertion)}
                        <br></br>
                        {intl.formatMessage(jsc.dateNotarization)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {moment(workerDetail.created_at).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                        <br></br>
                        {workerDetail.bc_first_sync_at.indexOf(NOT_VALID_DATE) <
                        0
                          ? moment(workerDetail.bc_sync_at).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : intl.formatMessage(jsc.noTrasmit)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {intl.formatMessage(jsc.reportAttachments)}
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleInputFile}
                        />
                        <label htmlFor="contained-button-file">
                          <Button
                            color="primary"
                            variant="contained"
                            component="span"
                            className={classes.upload}
                          >
                            {intl.formatMessage(common.upload)}
                          </Button>
                        </label>
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {workerDetail.medias &&
                          workerDetail.medias.length > 0 &&
                          workerDetail.medias?.map((option, index) => (
                            <div style={{ display: "flex" }} key={option.title}>
                              <p style={{ width: "90%" }}> {option.title}</p>
                              <MenuItem key={option.id}>
                                <DeleteIcon
                                  style={{ padding: "0px" }}
                                  onClick={() => removeAttachment(index)}
                                />
                              </MenuItem>
                            </div>
                          ))}
                        {linkAttachments &&
                          linkAttachments.length > 0 &&
                          linkAttachments?.map((option) => (
                            <div style={{ display: "flex" }} key={option.title}>
                              <p style={{ width: "90%" }}> {option.name}</p>
                              <MenuItem key={option.id}>
                                <DeleteIcon style={{ padding: "0px" }} />
                              </MenuItem>
                            </div>
                          ))}
                        {workerDetail.medias &&
                          workerDetail.medias.length == 0 &&
                          linkAttachments &&
                          linkAttachments.length == 0 &&
                          intl.formatMessage(jsc.noFile)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              style={{ margin: "10px", background: "rgb(29, 64, 175)" }}
            >
              {intl.formatMessage(common.update)}
            </Button>
            <Button
              onClick={() => {
                setHideEditButton(false);
                if (attachment) {
                  setAttachment(null);
                } else if (history) {
                  setHistory(null);
                } else setEditDialog(null);
              }}
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* main */}
      <div>
        <div className="border border-gray-400 bg-white rounded p-4 flex flex-col justify-between leading-normal">
          {/* toolbox */}
          <div
            className="mb-1"
            style={{
              background: "white",
              borderRadius: "30px",
              padding: "15px",
              marginBottom: "15px",
            }}
          >
            <div style={{ width: "100%" }}>
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterFactory)}
                value={factory.id}
                onChange={(e) => {
                  setSearchDone(true);
                  updateFilters({ id: e.target.value });
                }}
                fullWidth
                select
                disabled={factories.length == 1 || info?.role != "TM"}
                style={{ paddingTop: "3px" }}
              >
                {factories.map((evt) => (
                  <MenuItem value={evt.id} key={evt.id}>
                    {evt.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterDepartment)}
                value={department.id ? department.id : department}
                onChange={(e) => {
                  setSearchDone(true);
                  updateFilters("", { id: e.target.value });
                }}
                fullWidth
                select
                disabled={info?.role != "TM"}
                style={{ paddingTop: "3px" }}
              >
                {filterData("deps", departments, factory).map((dep) => (
                  <MenuItem value={dep.id} key={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ display: "inline-block" }}>
                <div>{intl.formatMessage(jsc.filterFrom)}</div>
                <div>
                  <TextField
                    id="dateStart"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textField}
                    onChange={(e) => {
                      setSearchDone(true);
                      setStartDate(
                        e.currentTarget.value == ""
                          ? setSearchDone(false)
                          : e.currentTarget.value
                      );
                    }}
                    value={startDate}
                  />
                </div>
              </div>
              <div style={{ display: "inline-block" }}>
                <div>{intl.formatMessage(jsc.filterTo)}</div>
                <div>
                  <TextField
                    id="dateEnd"
                    type="date"
                    InputLabelProps={{
                      shrink: false,
                    }}
                    className={classes.textField}
                    onChange={(e) => {
                      setSearchDone(true);
                      setEndDate(
                        e.currentTarget.value == ""
                          ? setSearchDone(false)
                          : e.currentTarget.value
                      );
                    }}
                    value={endDate}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "flex-start",
                  margin: "-35px 0px 15px",
                }}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        {intl.formatMessage(jsc.searchIntro)}
                      </Typography>
                      {intl.formatMessage(jsc.searchAgain)}
                      <b>{intl.formatMessage(jsc.mandatory)}</b>
                    </React.Fragment>
                  }
                >
                  <span>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSearchDone(false);
                        updateTable();
                      }}
                      variant="contained"
                      disabled={!searchDone}
                      style={{ marginRight: "10px" }}
                    >
                      {intl.formatMessage(jsc.search)}
                    </Button>
                  </span>
                </HtmlTooltip>
                <Button
                  onClick={(e) => resetFilter(e)}
                  style={{
                    background: "#1d40af",
                    color: "white",
                    marginRight: "10px",
                  }}
                  variant="contained"
                  disabled={!true}
                >
                  {intl.formatMessage(jsc.filterClear)}
                </Button>
              </div>
            </div>
          </div>
          {/* tabella */}
          <div className="tb_container">
            <DataTable
              columns={columns}
              data={reports}
              pagination
              noDataComponent={intl.formatMessage(jsc.noResults)}
              style={{ border: "1px solid blue", borderRadius: "10px" }}
              onRowClicked={(e) => openDialog(e)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ReportInspection;
