import React, { useCallback, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Fab,
  Toolbar,
  IconButton,
  AppBar,
  AccordionSummary,
  Accordion,
  useTheme,
  InputLabel,
} from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import Title from "../../ui/Title";
import { useCampaigns } from "../../utils/campaigns";
import LimitProgress from "../../ui/LimitProgress";
import { BUSINESS_TYPES, PLANS, USER_ROLES } from "../../utils/constants";
import {
  common,
  confirm,
  form,
  notifications,
  subscriptions,
} from "../../messages";
import { getPermName, rolesLabels } from "../../components/ManageStores";
import Confirm from "../../ui/Confirm";
import AddressAutocomplete2 from "../../components/AddressAutocomplete2";
import FormSection from "../../ui/forms/input/FormSection";
import { fromString } from "css-color-converter";
import { useForm } from "../../utils/form";
import {
  BrightnessLow,
  CheckCircle,
  Error,
  ExpandMore,
  Menu,
} from "@material-ui/icons";
import Logo from "../../ui/Logo";
import {
  checkFiscalCodeIT,
  checkVatNumberIT,
  getSubscriptionLogo,
  isAlphanumeric,
  isSubscriptionCompleted,
  validateEmail,
} from "../../utils/utils";

export default function ManageSubscription() {
  const DEFAULT_LOGO =
    "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg";
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [businesses, setBusinesses] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteLogoRequest, setDeleteLogoRequest] = useState(false);
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const { campaigns, loadCampaigns } = useCampaigns();
  const [subscriptionCompleted, setSubscriptionCompleted] = useState(true);

  const theme = useTheme();
  const vatNumberRef = useRef();
  const fiscalCodeRef = useRef();
  const invoiceCodeRef = useRef();
  const invoiceEmailRef = useRef();

  /*
  const [values, handleChange] = useForm({
    discountCode: "",
    company_name: "",
    company_email: "", // TODO: aggiungere alle api email referente?
    invoice_code: "",
    vat_number: "",
    invoice_address: {},
    
    friendly_url: {
      default: "",
      format: (v) => slugify(v.replace(" ", "-"), { lower: true }),
    },
    logo_big: "",
    billingPeriod:
      new URLSearchParams(location.search).get("billingPeriod") || 12,
    plan: new URLSearchParams(location.search).get("plan") || "SMSTD",
    primary_color: fromString(bmapi.themeConf.primary).toHexString(),
    store_quantity: 1,
  });
  */
  const sub = bmapi.subscription;

  const [values, handleChange] = useForm({
    company_name: sub.company_name,
    invoice_code: sub.invoice_code,
    vat_number: sub.vat_number,
    invoice_address: sub.invoice_address,
    friendly_url: sub.friendly_url,
    primary_color:
      sub.primary_color || fromString(bmapi.themeConf.primary).toHexString(),
    fiscal_code: sub.fiscal_code,
    invoice_email: sub.invoice_email,
  });

  const [slugExists, setSlugExists] = useState(false);
  const [slugValid, setSlugValid] = useState(false);
  const [checkTimeout, setCheckTimeout] = useState(false);
  const [subLogoShown, setSubLogoShown] = useState(DEFAULT_LOGO);
  const [subLogoId, setSubLogoId] = useState("");
  const slugInput = useRef();

  const permToName = (code) => getPermName(code, intl);

  const handleRevokeManager = () => {
    setSaving(true);
    return bmapi
      .deleteSubscriptionPermission(deleteRequest.id)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerRemoved));
        setDeleteRequest(false);
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .createSubscriptionsManager(email.trim(), role)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerAdded));
        setOpenDialog(false);
        setEmail("");
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortManager = (a, b) => {
    return permToName(a.permission) !== permToName(b.permission)
      ? permToName(a.permission).localeCompare(permToName(b.permission))
      : a.email.localeCompare(b.email);
  };

  const update = useCallback(() => {
    return Promise.all([
      bmapi.getUserBusiness(),
      //bmapi.getSubscriptionStats(),
      bmapi.getManagers(),
    ])
      .then(([bs, /*stats,*/ ps]) => {
        setBusinesses(
          bs.filter(
            (b) => b.type === BUSINESS_TYPES.MERCHANT && b.status === 0
          ) || []
        );
        setPermissions(ps);
        //console.log(stats);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    clearTimeout(checkTimeout);
    const to = setTimeout(checkSlug, 500);
    setCheckTimeout(to);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.friendly_url]);

  useEffect(() => {
    setSubscriptionCompleted(isSubscriptionCompleted(bmapi.subscription));
    setLogo(bmapi.subscription);
  }, [bmapi.subscription]);

  const checkSlug = () => {
    setSlugExists(false);
    if (slugInput.current.validity.valid && values.friendly_url) {
      bmapi
        .getSubscriptionsBySlug(values.friendly_url, bmapi.subscription.id)
        .then((s) => {
          setSlugValid(!s.length);
          setSlugExists(!!s.length);
        });
    } else {
      setSlugValid(false);
    }
  };

  const isEmailCorrect = (email) => !email || validateEmail(email);
  const isInvoiceCodeCorrect = (invoiceCode) =>
    !invoiceCode || (isAlphanumeric(invoiceCode) && invoiceCode.length === 7);
  const isVatNumberCorrect = (vatNumber) =>
    !vatNumber || checkVatNumberIT(vatNumber);
  const isFiscalCodeCorrect = (fiscalCode) =>
    !fiscalCode || checkFiscalCodeIT(fiscalCode);

  const onSubmit = (event) => {
    event.preventDefault();
    let msg;
    if (!values.friendly_url) {
      msg = subscriptions.missingFriendlyUrlError;
    } else if (!values.company_name) {
      msg = subscriptions.missingCompanyNameError;
    } else if (!values.vat_number && !values.fiscal_code) {
      msg = subscriptions.missingVatNumFiscCodeError;
      vatNumberRef.current.focus();
    } else if (!isVatNumberCorrect(values.vat_number)) {
      msg = form.vatNumberWrongFormat;
      vatNumberRef.current.focus();
    } else if (!isFiscalCodeCorrect(values.fiscal_code)) {
      msg = form.fiscalCodeWrongFormat;
      fiscalCodeRef.current.focus();
    } else if (!values.invoice_code && !values.invoice_email) {
      msg = subscriptions.missingInvoiceCodeEmailError;
      invoiceCodeRef.current.focus();
    } else if (!isInvoiceCodeCorrect(values.invoice_code)) {
      msg = form.invoiceCodeWrongFormat;
      invoiceCodeRef.current.focus();
    } else if (!isEmailCorrect(values.invoice_email)) {
      msg = form.emailWrongFormat;
      invoiceEmailRef.current.focus();
    }
    if (msg) {
      notifyError(intl.formatMessage(msg));
      return;
    }
    bmapi.updateSubscription(bmapi.subscription.id, values);
    notifySuccess(intl.formatMessage(subscriptions.updateSuccess));
  };

  const setLogo = (subscription) => {
    const sub = subscription?.length ? subscription[0] : subscription;
    const { logoId, logoUrl } = getSubscriptionLogo(sub);
    setSubLogoShown(logoUrl || DEFAULT_LOGO);
    setSubLogoId(logoId);
  };

  const handleInputFile = (event) => {
    const file = event.target.files[0];
    const newValues = {
      external: false,
      lang: "",
    };
    bmapi
      .addSubscriptionMediaContent(bmapi.subscription?.id, newValues, file)
      .then(() => bmapi.getSubscriptions({ id: bmapi.subscription?.id }))
      .then((subscriptions) => {
        setLogo(subscriptions);
        notifySuccess(intl.formatMessage(common.addElement));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const deleteLogo = () => {
    if (!subLogoId) {
      return;
    }
    bmapi
      .deleteSubscriptionMediaContent(bmapi.subscription?.id, subLogoId)
      .then(() => bmapi.getSubscriptions({ id: bmapi.subscription?.id }))
      .then((subscriptions) => {
        setLogo(subscriptions);
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => setDeleteLogoRequest(false));
  };

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(subscriptions.subscription)}</Title>

      {!subscriptionCompleted && (
        <Box py={2}>
          <Typography variant="subtitle1">
            {intl.formatMessage(subscriptions.completeConfigMessage)}
          </Typography>
        </Box>
      )}

      <Box mb={2}>
        {!!deleteLogoRequest && (
          <Confirm
            open={!!deleteLogoRequest}
            onConfirm={deleteLogo}
            onCancel={() => setDeleteLogoRequest(false)}
            text={intl.formatMessage(confirm.deleteLogo)}
          />
        )}
        {!!deleteRequest && (
          <Confirm
            open={!!deleteRequest}
            onConfirm={handleRevokeManager}
            onCancel={() => setDeleteRequest(false)}
            text={intl.formatMessage(confirm.deleteManager, deleteRequest)}
            flag
          />
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <form onSubmit={handleCreateManager}>
            <DialogTitle>
              {intl.formatMessage({
                id: "component.manageSubscription.addManager",
                defaultMessage: "Aggiungi manager",
              })}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(common.email)}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                fullWidth
              />
              <TextField
                margin="dense"
                label={intl.formatMessage(common.role)}
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
                fullWidth
                select
                disabled={
                  bmapi.subscription &&
                  !bmapi.subscription.plan_limits.different_roles
                }
                helperText={
                  bmapi.subscription &&
                  !bmapi.subscription.plan_limits.different_roles
                    ? intl.formatMessage(subscriptions.notAvailableinTrial)
                    : false
                }
              >
                {Object.entries(rolesLabels)
                  .filter(([role]) => role.startsWith("SUBSCRIPTION_"))
                  .map(([role, label]) => [role, intl.formatMessage(label)])
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([role, label]) => (
                    <MenuItem value={USER_ROLES[role]} key={USER_ROLES[role]}>
                      {label}
                    </MenuItem>
                  ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} disabled={saving}>
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {intl.formatMessage(common.create)}
              </Button>
            </DialogActions>
          </form>
          {saving && <LinearProgress />}
        </Dialog>

        <FormSection
          title={intl.formatMessage(subscriptions.configuration)}
          collapsed={!subscriptionCompleted}
        >
          <form onSubmit={onSubmit}>
            <Box>
              <Box py={2}>
                <Typography variant="subtitle2">
                  {intl.formatMessage(subscriptions.webAppConf)}
                </Typography>
              </Box>
              <TextField
                name="friendly_url"
                label={intl.formatMessage(form.friendly_url)}
                value={values.friendly_url}
                onChange={handleChange("friendly_url")}
                key="friendly_url"
                fullWidth
                margin="normal"
                required={values.plan !== PLANS.TRIAL}
                inputRef={slugInput}
                disabled={values.plan === PLANS.TRIAL}
                error={!!values.friendly_url && !slugValid}
                helperText={
                  values.plan === PLANS.TRIAL ? (
                    intl.formatMessage(form.friendly_url_disabled)
                  ) : slugValid ? (
                    <span>
                      {bmapi.settings.subscriptionsURL}
                      <b>{values.friendly_url}</b>
                    </span>
                  ) : slugExists ? (
                    intl.formatMessage(form.friendly_url_exists)
                  ) : (
                    intl.formatMessage(form.friendly_url_help)
                  )
                }
                InputProps={{
                  endAdornment: !!values.friendly_url && (
                    <InputAdornment position="end">
                      {slugValid ? (
                        <CheckCircle style={{ color: "#4caf50" }} />
                      ) : (
                        <Error style={{ color: "#f44336" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  pattern: "^[a-z0-9](-?[a-z0-9]){7,19}$",
                  maxLength: 20,
                }}
              />
              <Typography variant="caption" display="block" gutterBottom>
                {intl.formatMessage(form.friendly_url_info)}
              </Typography>
              <Box py={2}>
                <Box pb={4}>
                  <InputLabel>Logo</InputLabel>
                </Box>
                <Box display="flex">
                  <Box>
                    <Logo
                      logo={subLogoShown}
                      alt={
                        values.company_name || intl.formatMessage(form.logo_add)
                      }
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                    {!subLogoId && (
                      <>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleInputFile}
                        />
                        <label htmlFor="contained-button-file">
                          <Button color="primary" component="span">
                            {intl.formatMessage(common.uploadLogo)}
                          </Button>
                        </label>
                      </>
                    )}
                    {!!subLogoId && (
                      <Button
                        color="primary"
                        component="span"
                        onClick={() => setDeleteLogoRequest(true)}
                      >
                        {intl.formatMessage(common.deleteLogo)}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box pt={4}>
                  <TextField
                    name="primary_color"
                    label={intl.formatMessage(form.primary_color)}
                    value={values.primary_color}
                    onChange={handleChange("primary_color")}
                    key="primary_color"
                    fullWidth
                    margin="normal"
                    type="color"
                  />
                </Box>
              </Box>
            </Box>

            <Box pb={4}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle2">
                    {intl.formatMessage(form.preview)}
                  </Typography>
                </AccordionSummary>
                <Box p={2}>
                  <Typography variant="caption" display="block" gutterBottom>
                    {intl.formatMessage(subscriptions.previewSubtitle)}
                  </Typography>
                </Box>
                <Box pb={2}>
                  <AppBar
                    position="static"
                    style={{
                      background: values.primary_color,
                      color: theme.palette.getContrastText(
                        values.primary_color
                      ),
                    }}
                  >
                    <Toolbar>
                      <div style={{ flexGrow: 1 }}>
                        <Logo
                          logo={subLogoShown}
                          alt={
                            values.company_name ||
                            intl.formatMessage(form.logo_add)
                          }
                        />
                      </div>
                      <IconButton edge="end" color="inherit">
                        <Menu />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <Container maxWidth="sm">
                    <Title>Title</Title>
                    <Typography>
                      BMarkEn is an ideal tool to allow Merchants, Retailers,
                      Producers to match two basic needs: how to promote their
                      products/services and how to retain customers.
                    </Typography>
                    <Box align="center" mt={3}>
                      <Fab
                        variant="extended"
                        style={{
                          background: values.primary_color,
                          color: theme.palette.getContrastText(
                            values.primary_color
                          ),
                        }}
                      >
                        <BrightnessLow style={{ marginRight: 10 }} />
                        Action
                      </Fab>
                    </Box>
                  </Container>
                </Box>
              </Accordion>
            </Box>

            <Box pb={4}>
              <Box py={2}>
                <Typography variant="subtitle2">
                  {intl.formatMessage(subscriptions.companyInfo)}
                </Typography>
              </Box>
              <TextField
                name="company_name"
                label={intl.formatMessage(form.company_name)}
                value={values.company_name}
                onChange={handleChange("company_name")}
                key="company_name"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="vat_number"
                label={intl.formatMessage(form.vatNumber)}
                value={values.vat_number}
                onChange={handleChange("vat_number")}
                key="vat_number"
                fullWidth
                margin="normal"
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, "")
                    .substring(0, 11);
                }}
                error={!isVatNumberCorrect(values.vat_number)}
                helperText={
                  !isVatNumberCorrect(values.vat_number) &&
                  intl.formatMessage(form.vatNumberWrongFormat)
                }
                inputRef={vatNumberRef}
              />
              <TextField
                name="fiscal_code"
                label={intl.formatMessage(form.fiscalCode)}
                value={values.fiscal_code}
                onChange={handleChange("fiscal_code")}
                key="fiscal_code"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 16 }}
                error={!isFiscalCodeCorrect(values.fiscal_code)}
                helperText={
                  !isFiscalCodeCorrect(values.fiscal_code) &&
                  intl.formatMessage(form.fiscalCodeWrongFormat)
                }
                inputRef={fiscalCodeRef}
              />
              <TextField
                name="invoice_code"
                label={intl.formatMessage(form.invoiceCode)}
                value={values.invoice_code}
                onChange={handleChange("invoice_code")}
                key="invoice_code"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 7 }}
                error={!isInvoiceCodeCorrect(values.invoice_code)}
                helperText={
                  !isInvoiceCodeCorrect(values.invoice_code) &&
                  intl.formatMessage(form.invoiceCodeWrongFormat)
                }
                inputRef={invoiceCodeRef}
              />
              <TextField
                name="invoice_email"
                label={intl.formatMessage(form.invoiceEmail)}
                value={values.invoice_email}
                onChange={handleChange("invoice_email")}
                key="invoice_email"
                fullWidth
                margin="normal"
                error={!isEmailCorrect(values.invoice_email)}
                helperText={
                  !isEmailCorrect(values.invoice_email) &&
                  intl.formatMessage(form.emailWrongFormat)
                }
                inputRef={invoiceEmailRef}
              />
            </Box>
            <Box pb={4}>
              <Box py={2}>
                <Typography variant="subtitle2" gutterBottom>
                  {intl.formatMessage(form.invoiceAddress)}
                </Typography>
              </Box>
              <AddressAutocomplete2
                types="address"
                onChange={handleChange("invoice_address")}
                address={values.invoice_address}
                required={true}
                margin="normal"
              />
            </Box>
            <Box pt={4}>
              <Button
                style={{ width: "100%" }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {intl.formatMessage(common.save)}
              </Button>
            </Box>
          </form>
        </FormSection>

        <Box mb={10}></Box>
        <FormSection
          title={intl.formatMessage(subscriptions.managers)}
          collapsed={true}
        >
          <Box my={2}>
            {(permissions || [])
              .filter((perm) => perm.resource_id === bmapi.subscription.id)
              .sort(alphaSortManager)
              .map((m) => (
                <Typography variant="body2" key={m.email} gutterBottom>
                  <strong>{getPermName(m.permission, intl)}</strong>: {m.email}
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => setDeleteRequest(m)}
                  >
                    {intl.formatMessage(common.delete)}
                  </Button>
                </Typography>
              ))}
          </Box>
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            {intl.formatMessage({
              id: "component.manageSubscription.addManager",
              defaultMessage: "Aggiungi manager",
            })}
          </Button>
        </FormSection>
      </Box>

      <FormSection
        title={intl.formatMessage(subscriptions.limits)}
        collapsed={true}
      >
        {bmapi.subscription.plan_limits.max_business > 0 && (
          <Box mt={2}>
            <Typography>{intl.formatMessage(common.stores)}</Typography>
            <LimitProgress
              value={businesses?.length || 0}
              limit={bmapi.subscription.plan_limits.max_business}
            />
          </Box>
        )}
        {bmapi.subscription.plan_limits.max_campaigns > 0 && (
          <Box mt={2}>
            <Typography>{intl.formatMessage(common.campaigns)}</Typography>
            <LimitProgress
              value={campaigns?.length || 0}
              limit={bmapi.subscription.plan_limits.max_campaigns}
            />
          </Box>
        )}
      </FormSection>
    </Container>
  );
}
