import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import styles from "../utils/styles";

export default function Confirm({
  children,
  disabled = false,
  onConfirm,
  onCancel,
  title,
  text,
  flag = false,
  open,
  keepMounted = false,
}) {
  const [acceptFlag, setAcceptFlag] = useState(!flag || false);
  const classes = styles.useStyles();

  const handleConfirm = () => onConfirm();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
      keepMounted={keepMounted}
    >
      {title ? (
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      ) : null}
      {text ? (
        <DialogContent>
          <DialogContentText component="div" color="textPrimary">
            {text}
          </DialogContentText>
        </DialogContent>
      ) : null}

      {!!flag && (
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptFlag}
                onChange={(_, f) => setAcceptFlag(f)}
                name="flag"
                color="primary"
              />
            }
            classes={{
              label: classes.confirmLabel,
            }}
            label={
              <Typography variant="caption" style={{ lineHeight: 1 }}>
                {typeof flag === "boolean" ? (
                  <FormattedMessage
                    id="common.sure"
                    defaultMessage="Sono sicuro"
                  />
                ) : (
                  flag
                )}
              </Typography>
            }
          />
        </DialogContent>
      )}
      {children ? <DialogContent>{children}</DialogContent> : null}
      <DialogActions>
        <Button onClick={onCancel}>
          <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          disabled={!acceptFlag || disabled}
          variant="contained"
        >
          <FormattedMessage id="common.confirm" defaultMessage="Conferma" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
