import { defineMessages } from "react-intl";

export const storeLabels = defineMessages({
  editBusiness: {
    id: "component.manageStores.editStore",
    defaultMessage: "Modifica negozio",
  },
  noBusinessRegistered: {
    id: "component.manageStores.noStoreRegistered",
    defaultMessage: "Nessun negozio ancora registrato.",
  },
  emptyAction: {
    id: "component.manageStores.emptyAction",
    defaultMessage:
      "Crea il primo negozio cliccando sul bottone a fondo pagina o <link>qui</link>.",
  },
  pageTitle: {
    id: "pages.stores.title",
    defaultMessage: "Negozi",
  },
  createBusiness: {
    id: "component.manageStores.createStore",
    defaultMessage: "Crea negozio",
  },
});
