import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";
import useDocumentTitle from "../../utils/documentTitle";

export default function Friend() {
  const history = useHistory();
  const intl = useIntl();
  const { baseUrl, bmapi, notifySuccess } = useBmapi();
  useDocumentTitle(
    intl.formatMessage({
      id: "pages.friend.title",
      defaultMessage: "Invita un amico",
    })
  );

  const inviteUrl =
    baseUrl +
    CONSUMER_ROUTES.FRIEND_CODE.slice(1).replace(
      ":friendCode",
      bmapi.userData?.friend_code
    );

  const copyUrl = () => {
    return navigator.clipboard.writeText(inviteUrl).then(() =>
      notifySuccess(
        intl.formatMessage({
          id: "component.friend.urlCopied",
          defaultMessage: "Link copiato",
        })
      )
    );
  };

  const shareUrl = () => {
    if (navigator.share) {
      return navigator
        .share({
          text: intl.formatMessage({
            id: "component.friend.inviteText",
            defaultMessage:
              "Ti mando l’invito a iscriverti e partecipare al concorso. Per accedere clicca qui",
          }),
          url: inviteUrl,
        })
        .catch(copyUrl);
    }
    return copyUrl();
  };

  return (
    <Container maxWidth="sm">
      <Title backUrl={CONSUMER_ROUTES.HOME}>
        {intl.formatMessage({
          id: "pages.friend.title",
          defaultMessage: "Invita un amico",
        })}
      </Title>
      <Typography gutterBottom>
        {intl.formatMessage({
          id: "pages.friend.info",
          defaultMessage:
            "Utilizza questo codice per invitare amici a registrarsi e guadagnare punti aggiuntivi",
        })}
      </Typography>
      <Card>
        <CardContent align="center">
          <Typography gutterBottom style={{ fontSize: 70 }}>
            {bmapi.userData?.friend_code}
          </Typography>
          <Typography gutterBottom>
            <Button onClick={shareUrl} variant="contained">
              {navigator.share ? "Condividi" : "Copia link invito"}
            </Button>
          </Typography>
          <Typography>{inviteUrl}</Typography>
        </CardContent>
      </Card>
      <FormControl margin="normal">
        <Button
          onClick={() => history.push(CONSUMER_ROUTES.HOME)}
          startIcon={<ArrowBackIcon />}
        >
          {intl.formatMessage({
            id: "common.backHome",
            defaultMessage: "Torna alla home",
          })}
        </Button>
      </FormControl>
    </Container>
  );
}
