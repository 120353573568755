import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Delete,
  Alarm,
  HelpOutline,
  ArrowBack,
  ArrowForward,
  FilterList,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons";
import {
  ORDER_STATUS,
  ACTIONS,
  //OUTCOMES,
} from "../../utils/ecommerceConstants";
import {
  intlFormatMessage,
  parseBmarkenDate,
  priceText,
} from "../../utils/utils";
import { format } from "date-fns";
import { DatePicker } from "../../ui/DatePicker";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { account, common, confirm } from "../../messages";
import { ecommerce } from "../../messages/ecommerce";
import Confirm from "../../ui/Confirm";
import styles from "../../utils/styles";

const DELIVERY_MODE = [
  { value: "take_away", label: "Take away" },
  { value: "", label: "Take away" },
  { value: "on_site", label: "Sul posto" },
];

export default function ManageOrders() {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const classes = styles.useStyles();

  /*const initialOutcome = OUTCOMES.filter((obj) => obj.value === "CONTACTED")
    .map((obj) => intlFormatMessage(ecommerce[obj.label], obj.label, intl))
    .toString();*/

  const [orders, setOrders] = useState([]);
  //const [openCustomerContact, setOpenCustomerContact] = React.useState(false);
  //const [openConfirmContact, setOpenConfirmContact] = React.useState(false);
  const [openPostponed, setOpenPostponed] = React.useState(false);
  const [openLegenda, setOpenLegenda] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderSelected, setOrderSelected] = useState(null);
  //const [contactTime, setContactTime] = React.useState(null);
  //const [phone, setPhone] = useState(null);
  const [orderToDelete, setOrderToDelete] = React.useState(null);
  const [orderIdDesc, setOrderIdDesc] = useState(null);
  const [itemIdDesc, setItemIdDesc] = useState(null);
  const [statusFilter, setStatusFilter] = React.useState("x");
  const [dateFilter, setDateFilter] = React.useState(new Date());
  //const [outcome, setOutcome] = React.useState(initialOutcome);

  const breakPoints = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const mediaQuery = (order) => {
    let common = {
      order: (
        <Typography style={{ fontWeight: "bold" }} variant="h6">
          {intl.formatMessage(ecommerce.orderShort) +
            " " +
            order.id.substr(-5, 5)}
        </Typography>
      ),
      icon: <div align="right">{getStatusIcon(order)}</div>,
      mode: (
        <Typography align={breakPoints ? "right" : "left"} variant="h6">
          {getLabel(order.delivery_mode)}
        </Typography>
      ),
      delivery: sameDate(order),
    };
    if (breakPoints) {
      return (
        <>
          <Grid item xs={3}>
            {common.order}
          </Grid>
          <Grid item xs={5}>
            {common.delivery}
          </Grid>
          <Grid item xs={3}>
            {common.mode}
          </Grid>
          <Grid item xs={1}>
            {common.icon}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6}>
            {common.order}
          </Grid>
          <Grid item xs={5}>
            {common.mode}
          </Grid>
          <Grid item xs={1}>
            {common.icon}
          </Grid>
          <Grid item xs={11}>
            {common.delivery}
          </Grid>
        </>
      );
    }
  };

  const sameDate = (o) => {
    const plannedDelivery = format(
      new Date(parseBmarkenDate(o?.planned_delivery)),
      "HH:mm"
    );
    const newDelivery =
      o?.slot_id.substring(16, 18) + ":" + o?.slot_id.substring(18, 20);
    if (plannedDelivery === newDelivery) {
      return (
        <Typography variant="h6">{fixHour(o.planned_delivery)}</Typography>
      );
    } else {
      return (
        <Typography variant="h6">
          {fixHour(o.planned_delivery)}{" "}
          <span style={{ color: "red" }}>
            ({o.slot_id.substring(16, 18) + ":" + o.slot_id.substring(18, 20)})
          </span>
        </Typography>
      );
    }
  };
  const fixHour = (time) => format(new Date(time), "dd/MM/yy HH:mm");

  const byStatus = (o) => {
    if (statusFilter === 0) return o.status === statusFilter;
    else if (statusFilter === 1) return o.status === statusFilter;
    else if (statusFilter === 2) return o.status === statusFilter;
    else if (statusFilter === 3) return o.status === statusFilter;
    else if (statusFilter === 4) return o.status === statusFilter;
    else if (statusFilter === 5) return o.status === statusFilter;
    else if (statusFilter === "x")
      return o.status === 0 || o.status === 1 || o.status === 2;
  };

  const byDate = (o) => o.date === format(dateFilter, "yyyyMMdd");

  const isDisabled = (order) => {
    return order.status === 4 || order.status === 5;
  };

  //funzione da passare a bmapi come callback per la ricezione di una notifica di tipo "order"
  const manageOrderNotification = (notification) => {
    console.log("ORDER CALLBACK CALLED: ", notification);

    //si può chiamare l'api per gli ordini o aggiungere il nuovo ordine alla lista in pagina
    //notification.data contiene alcuni campi utili
    loadOrderList();
  };

  //imposto il callback per le notifiche
  useEffect(() => {
    bmapi.setOrderNotificationCallback(manageOrderNotification);
  });

  useEffect(() => {
    loadOrderList();
  }, [bmapi, loadOrderList]);

  const loadOrderList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEOrder({
          business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setOrders(resp);
          } else {
            setOrders(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  /*const getPhone = (order) => {
    bmapi
      .getUserPhone({
        business: bmapi.getUserInfo().business.id,
        ecomorder: order.id,
      })
      .then((resp) => {
        //notifySuccess(intl.formatMessage(account.saveConfirm));
        setPhone(resp.telephone);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };*/

  const getStatusIcon = useCallback((order) => {
    let status = order.status;
    const filtered = ORDER_STATUS.filter((obj) => obj.value === status);
    return filtered && filtered.length ? filtered[0].icon_color : "";
  }, []);

  const getLabel = useCallback((code) => {
    const filtered = DELIVERY_MODE.filter((obj) => obj.value === code);
    return filtered && filtered.length ? filtered[0].label : "";
  }, []);

  const viewDescription = (itemId, orderId) => {
    setLoading(true);
    bmapi
      .getEProduct({
        item: itemId,
        business: bmapi.getUserInfo().business.id,
      })
      .then((resp) => {
        const order = orders.find((order) => order.id === orderId);
        const item = order.items.find((item) => item.item_id === itemId);
        item.description = resp[0].description;
        //item.show_description = true;
        setOrders(orders);
        setLoading(false);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
    setOrderIdDesc(orderId);
    setItemIdDesc(itemId);
  };

  const hideDescription = (itemId, orderId) => {
    setLoading(true);
    bmapi
      .getEProduct({
        item: itemId,
        business: bmapi.getUserInfo().business.id,
      })
      .then(() => {
        const order = orders.find((order) => order.id === orderId);
        const item = order.items.find((item) => item.item_id === itemId);
        item.description = "";
        setOrders(orders);
        setLoading(false);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
    setOrderIdDesc(orderId);
    setItemIdDesc(itemId);
  };

  const handleStatusFilter = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleDateFilter = (event) => {
    setDateFilter(event);
  };

  /*const handleOutcomes = (event) => {
    setOutcome(event.target.value);
  };*/

  const handleDialog = (type, bool) => {
    //if (type === "contact") setOpenCustomerContact(bool);
    //if (type === "confirm") setOpenConfirmContact(bool);
    if (type === "postponed") {
      setOpenPostponed(bool);
    } else if (type === "legenda") {
      setOpenLegenda(bool);
    }
  };

  /*const handleCustomerContacted = () => {
    let newContacts = [];
    let contacts = {
      timestamp: addHours(contactTime, 2).toISOString(),
      outcome: outcome,
    };
    newContacts.push(contacts);
    let order = {
      ...orderSelected,
      customer_contacts: newContacts,
    };
    // update contacts list based on orderSelected
    let newOrderSelected = {
      ...orderSelected,
    };
    if (orderSelected.customer_contacts) {
      let contactsOrderSelected = [
        ...orderSelected.customer_contacts,
        order.customer_contacts[0],
      ];
      newOrderSelected.customer_contacts = contactsOrderSelected;
    } else {
      let contactsOrderSelected = [order.customer_contacts[0]];
      newOrderSelected.customer_contacts = contactsOrderSelected;
    }
    setOrderSelected(newOrderSelected);
    //
    //setContactTime(null);
    //setOpenConfirmContact(false);
    //setOutcome(initialOutcome);
    bmapi
      .updateOrder(order)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        loadOrderList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };*/

  const handleSlot = (order, action) => {
    const availableSlot = {
      hour: action
        ? order.slot_id.substring(16, 20)
        : order.slot_id.substring(12, 16),
      options: action ? "next-available" : "direction-backward",
    };
    bmapi
      .getSlot(availableSlot)
      .then((resp) => {
        const rSlot = resp[0];
        const newOrder = {
          ...orderSelected,
          slot_id: rSlot.id,
        };
        setOrderSelected(newOrder);
        //notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleNewSlot = (order) => {
    const newTime =
        order.slot_id.substring(16, 18) + ":" + order.slot_id.substring(18, 20),
      date = format(new Date(order.planned_delivery), "yyyy-MM-dd"),
      isoDate = `${date}T${newTime}:00.000Z`;
    const newOrder = {
      ...orderSelected,
      postponed_to: isoDate,
      customer_contacts: [],
    };
    bmapi
      .updateOrder(newOrder)
      .then(() => {
        setOrderSelected(null);
        setOpenPostponed(false);
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        loadOrderList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleStatus = (order, val, action) => {
    let newstatus = {
      ...order,
    };
    if (order.status === 4 && action === "undo") {
      newstatus.status = 0;
    } else {
      newstatus.status = val === 4 ? val : order.status + val;
    }
    bmapi
      .updateOrderStatus(newstatus)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        loadOrderList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={0}>
        <Grid item xs={1}>
          <FilterList style={{ marginTop: "30px" }} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              margin={statusFilter === "x" ? "dense" : "none"}
              label={intl.formatMessage(ecommerce.status)}
              value={statusFilter}
              onChange={handleStatusFilter}
              fullWidth
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              {ORDER_STATUS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.icon_small}{" "}
                  {intlFormatMessage(
                    ecommerce[status.label],
                    status.label,
                    intl
                  )}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <DatePicker
            margin="dense"
            fullWidth
            label={intl.formatMessage(common.date)}
            inputFormat="dd/MM/yyyy"
            onChange={handleDateFilter}
            value={dateFilter}
            format="dd/MM/yy"
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      {orders
        .filter(byStatus)
        .filter(byDate)
        .map((order) => (
          <Box mt={2} key={order.id}>
            <Card>
              <CardContent style={{ paddingBottom: 0 }}>
                <Grid container spacing={0}>
                  {mediaQuery(order)}
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={10} sm={9}>
                    <Typography gutterBottom style={{ fontWeight: "bold" }}>
                      {intl.formatMessage(ecommerce.item)}
                    </Typography>
                    {order.items.map((item) => (
                      <>
                        <Typography key={item.item_id} gutterBottom>
                          {item.quantity}x {item.name}
                          <IconButton
                            style={{
                              backgroundColor: "transparent",
                              padding: "0",
                            }}
                            disableRipple
                            onClick={() =>
                              item.description
                                ? hideDescription(item.item_id, order.id)
                                : viewDescription(item.item_id, order.id)
                            }
                            disabled={
                              loading &&
                              order.id === orderIdDesc &&
                              item.item_id === itemIdDesc
                            }
                          >
                            {item.description ? <ExpandLess /> : <ExpandMore />}
                            {loading &&
                              order.id === orderIdDesc &&
                              item.item_id === itemIdDesc && (
                                <CircularProgress
                                  size={24}
                                  className={classes.fabProgress}
                                />
                              )}
                          </IconButton>
                        </Typography>
                        {<Typography>{item.description}</Typography>}
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                      align="right"
                    >
                      {intl.formatMessage(ecommerce.price)}
                    </Typography>
                    {order.items.map((item) => (
                      <Typography
                        key={item.item_id}
                        gutterBottom
                        align="right"
                        style={{
                          fontWeight:
                            order.items.length > 1 ? "default" : "bold",
                        }}
                      >
                        €
                        {
                          priceText(
                            order.items.length > 1
                              ? item.price * item.quantity
                              : order.amount
                          ).split(".")[0]
                        }
                        ,
                        {
                          priceText(
                            order.items.length > 1
                              ? item.price * item.quantity
                              : order.amount
                          ).split(".")[1]
                        }
                      </Typography>
                    ))}
                  </Grid>
                  {order.items.length > 1 && (
                    <>
                      <Grid item xs={10} sm={9}>
                        <Typography gutterBottom style={{ fontWeight: "bold" }}>
                          {intl.formatMessage(ecommerce.total)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                          align="right"
                        >
                          €{priceText(order.amount).split(".")[0]},
                          {priceText(order.amount).split(".")[1]}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Divider />
                <Grid container spacing={8}>
                  <Grid item xs={2} sm={2}>
                    <IconButton
                      onClick={() => handleStatus(order, -1, "undo")}
                      disabled={isDisabled(order)}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleStatus(order, 1)}
                      disabled={isDisabled(order)}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <IconButton
                      onClick={() => {
                        setShowDeleteAlert(true);
                        setOrderToDelete(order);
                      }}
                      disabled={isDisabled(order)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <IconButton
                      onClick={() => {
                        handleDialog("postponed", true);
                        setOrderSelected(order);
                      }}
                      disabled={order.status !== 0 && order.status !== 1}
                    >
                      <Alarm />
                    </IconButton>
                  </Grid>

                  {/*  
                     <Grid item xs={2}>
                     <IconButton
                      onClick={() => {
                        handleDialog("contact", true), setOrderSelected(order);
                        getPhone(order);
                      }}
                      disabled={!order.share_phone || isDisabled(order)}
                    >
                      <Phone />
                    </IconButton>
                    </Grid>*/}

                  <Grid item xs={2} sm={2}>
                    <IconButton onClick={() => handleDialog("legenda", true)}>
                      <HelpOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => handleStatus(orderToDelete, 4)}
        onCancel={() => {
          setOrderToDelete(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      {/* <Dialog
        open={openCustomerContact}
        onClose={() => {
          handleDialog("contact", false);
          setOrderSelected(null);
          //setPhone(null);
          setOutcome(initialOutcome);
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogContent>
          <Typography gutterBottom variant="h6">
            {phone}{" "}
            <Button
              href={`tel://${phone}`}
              //href="tel://3317595880"
              variant="contained"
              onClick={() => {
                handleDialog("confirm", true), setContactTime(new Date());
              }}
            >
              <Phone />
            </Button>
          </Typography>
          {orderSelected?.customer_contacts && (
            <Typography>
              {intl.formatMessage(ecommerce.callList) + ":"}
            </Typography>
          )}
          {orderSelected?.customer_contacts?.map((contact) => (
            <List key={contact.outocome} style={{ padding: 0 }}>
              <ListItem style={{ padding: 2 }}>
                <ListItemText>
                  {format(
                    addHours(parseBmarkenDate(contact.timestamp), -2),
                    "dd-MM-yyyy" + " " + "HH:mm"
                  )}{" "}
                  {contact.outcome}
                </ListItemText>
              </ListItem>
            </List>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialog("contact", false);
              setOrderSelected(null);
              setPhone(null);
              setOutcome(initialOutcome);
            }}
            variant="outlined"
            color="primary"
          >
            {intl.formatMessage(common.close)}
          </Button>
          <Button
            onClick={() => {
              handleDialog("confirm", true);
              //setOrderSelected(order),
              setContactTime(new Date());
            }}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage(ecommerce.callRecord)}
          </Button>
        </DialogActions>
      </Dialog>*/}

      {/*<Dialog
        open={openConfirmContact}
        onClose={() => {
          handleDialog("confirm", false);
          setOutcome(initialOutcome);
        }}
        maxWidth={"xs"}
      >
        <DialogContent>
          <Typography gutterBottom>
            {intl.formatMessage(ecommerce.callOutcome) + ":"}
          </Typography>
          <FormControl>
            <RadioGroup
              name="outcome"
              value={outcome}
              onChange={handleOutcomes}
            >
              {OUTCOMES.map((outcome) => (
                <FormControlLabel
                  key={outcome.value}
                  value={intlFormatMessage(
                    ecommerce[outcome.label],
                    outcome.label,
                    intl
                  )}
                  control={<Radio />}
                  label={intlFormatMessage(
                    ecommerce[outcome.label],
                    outcome.label,
                    intl
                  )}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialog("confirm", false);
              //setContactTime(null);
              //setOutcome(initialOutcome);
            }}
            variant="text"
            color="primary"
          >
            {intl.formatMessage(common.close)}
          </Button>
          <Button
            onClick={handleCustomerContacted}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage(common.confirm)}
          </Button>
        </DialogActions>
      </Dialog> */}

      {orderSelected && (
        <Dialog
          open={openPostponed}
          onClose={() => {
            handleDialog("postponed", false);
            setOrderSelected(null);
          }}
          fullWidth
        >
          <DialogContent>
            <ButtonGroup color="black" size="large">
              <Button onClick={() => handleSlot(orderSelected, 0)}>
                <ArrowBack />
              </Button>
              <Button>
                {orderSelected.slot_id.substring(16, 18) +
                  ":" +
                  orderSelected.slot_id.substring(18, 20)}
              </Button>
              <Button onClick={() => handleSlot(orderSelected, 1)}>
                <ArrowForward />
              </Button>
            </ButtonGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleNewSlot(orderSelected);
              }}
              color="primary"
              variant="contained"
            >
              {intl.formatMessage(common.confirm)}
            </Button>
            <Button
              onClick={() => {
                handleDialog("postponed", false);
                setOrderSelected(null);
              }}
              variant="contained"
            >
              {intl.formatMessage(common.cancel)}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={openLegenda}
        onClose={() => handleDialog("legenda", false)}
        maxWidth={"xs"}
      >
        <DialogContent>
          <Grid container spacing={3}>
            {ACTIONS.map((action) => (
              <>
                <Grid item xs={2}>
                  {action.icon}
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {intlFormatMessage(
                      ecommerce[action.label],
                      action.label,
                      intl
                    )}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDialog("legenda", false)}
            variant="contained"
          >
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      {/* <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleAddNewOrder}>
          <Add />
          Aggiungi ordine
        </Fab>
      </FloatingActions>*/}
    </Container>
  );
}
