import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";

import Confirm from "../ui/Confirm";
import FloatingActions, { Action } from "../ui/FloatingActions";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { BUSINESS_TYPES, USER_ROLES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import {
  common,
  confirm,
  form,
  notifications,
  roles,
  storeTypes,
  subscriptions,
} from "../messages";
import styles from "../utils/styles";
import PlanLimitAlert from "../ui/PlanLimitAlert";
import LimitProgress from "../ui/LimitProgress";
import { museumLabels } from "../messages/businessMuseums";
import { storeLabels } from "../messages/businessStores";
import { Language } from "../ui/forms/profile";
import { langs } from "../utils/dictionaries";
import { decodeFromArray } from "../utils/utils";
import MediaContentForm from "../pages/manager/MediaContentForm";

export const rolesLabels = {
  ASSISTANT: roles.storeAssistant,
  BUSINESS_MANAGER: roles.storeManager,
  CASHIER: roles.operator,
  EDITOR: roles.campaignManager,
  REPORTER: roles.viewer,
  SUBSCRIPTION_MANAGER: roles.subscriptionManager,
  SUBSCRIPTION_PLAN_MANAGER: roles.subscriptionPlanManager,
  SUBSCRIPTION_STAMP_MANAGER: roles.subscriptionStampManager,
};

export const getPermName = (code, intl) =>
  intl.formatMessage(
    rolesLabels[Object.entries(USER_ROLES).find((r) => r[1] === code)[0]]
  );

function BusinessElement({
  business,
  managers,
  onModify,
  onDelete,
  status,
  update,
  businessLabels,
  setFilter,
}) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(USER_ROLES.BUSINESS_MANAGER);
  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const permToName = (code) => getPermName(code, intl);

  const handleDelete = () => {
    setLoading(true);
    setAnchorEl(null);
    onDelete(business.id);
  };

  const handleModify = () => {
    setAnchorEl(null);
    onModify(business);
  };

  const handleAddManager = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleRevokeManager = () => {
    setSaving(true);
    return bmapi
      .revokeUserPermission(deleteRequest.id, deleteRequest.email)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerRemoved));
        setDeleteRequest(false);
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .createManager(email.trim(), business.id, role)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerAdded));
        setOpenDialog(false);
        setEmail("");
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortManager = (a, b) => {
    return permToName(a.permission) !== permToName(b.permission)
      ? permToName(a.permission).localeCompare(permToName(b.permission))
      : a.email.localeCompare(b.email);
  };

  const availableManagers = bmapi.subscription
    ? bmapi.subscription.plan_limits.max_managers_per_business - managers.length
    : 10000;

  return (
    <React.Fragment>
      <Confirm
        open={!!deleteRequest}
        onConfirm={handleRevokeManager}
        onCancel={() => setDeleteRequest(false)}
        text={intl.formatMessage(confirm.deleteManager, deleteRequest)}
        flag
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleCreateManager}>
          <DialogTitle>
            <FormattedMessage
              id="component.manageStores.addManager"
              defaultMessage="Aggiungi manager"
            />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.store)}
              value={business.name}
              disabled
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.email)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(common.role)}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              fullWidth
              select
              disabled={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
              }
              helperText={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
                  ? intl.formatMessage(subscriptions.notAvailableinTrial)
                  : false
              }
            >
              {Object.entries(rolesLabels)
                .filter(([role]) => !role.startsWith("SUBSCRIPTION_"))
                .map(([role, label]) => [role, intl.formatMessage(label)])
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map(([role, label]) => (
                  <MenuItem value={USER_ROLES[role]} key={USER_ROLES[role]}>
                    {label}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} disabled={saving}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {intl.formatMessage(common.create)}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      <ListItem>
        <ListItemText
          primary={
            <React.Fragment>
              {business.name}{" "}
              {business.demo && (
                <Chip
                  component="span"
                  label="demo"
                  size="small"
                  className={classes.demoChip}
                />
              )}
            </React.Fragment>
          }
          primaryTypographyProps={{ variant: "h6" }}
          secondary={
            <>
              {business.category && (
                <strong>
                  [
                  {storeTypes[business.category]
                    ? intl.formatMessage(storeTypes[business.category])
                    : business.category}
                  ]{" "}
                </strong>
              )}
              {business.description || (
                <i>
                  <FormattedMessage
                    id="component.manageStores.noDescription"
                    defaultMessage="Nessuna descrizione"
                  />
                </i>
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setFilter("general");
              }}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{business.name}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleAddManager}
                disabled={availableManagers < 1}
              >
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageStores.addManager"
                    defaultMessage="Aggiungi manager"
                  />
                  {availableManagers < 1 && (
                    <small>
                      {" "}
                      [{intl.formatMessage(subscriptions.noSlots)}]
                    </small>
                  )}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage {...businessLabels.editBusiness} />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageStores.deleteStore"
                    defaultMessage="Elimina negozio"
                  />
                </Typography>
              </MenuItem>
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
      {!!managers.length && (
        <ListItem>
          <ListItemText
            primary={
              <React.Fragment>
                {managers.sort(alphaSortManager).map((m) => (
                  <Typography variant="body2" key={m.email}>
                    <strong>{permToName(m.permission)}</strong>:{" "}
                    {status[m.email]?.is_registered ? (
                      `${m.email} ✅ `
                    ) : (
                      <i>[{m.email}] </i>
                    )}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(m)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </ListItem>
      )}
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => ({
  type: bs.type || BUSINESS_TYPES.MERCHANT,
  address: bs.complete_address || "",
  business_group_id: bs.business_group_id || "DEFAULT",
  category: bs.category || "",
  city: bs.city || "",
  demo: bs.demo || false,
  description: bs.description || "",
  independent: false,
  media_contents: bs.media_contents || [],
  mobile: bs.mobile || "",
  name: bs.name || "",
  province: bs.province || "",
  region: bs.region || "",
  vat_number: bs.vat_number || "",
  zip: bs.zip || "",
  default_language: bs.default_language || "",
  available_languages: bs.available_languages || [],
  private: !!bs.private,
  show_location: !!bs.show_location,
  show_activities: !!bs.show_activities,
  show_outdoor: !!bs.show_outdoor,
  show_tour_transports: !!bs.show_tour_transports,
  show_tour_prices: !!bs.show_tour_prices,
});

export default function ManageStores({ type }) {
  const intl = useIntl();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [mediaContentValues, setMediaContentValues] = useState(null);
  const [values, setValues] = useState(initialValues({ type }));
  const [status, setStatus] = useState({});
  const [filter, setFilter] = useState("general");
  const businessLabels =
    type && type === BUSINESS_TYPES.MUSEUM ? museumLabels : storeLabels;

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleLanguage = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
    },
    []
  );

  const handleDefaultLanguage = (e) => {
    const val = e.target.value;
    const newValues = { ...values };
    const index = values.available_languages.indexOf(val);
    if (index > -1) {
      const newLang = [...values.available_languages];
      newLang.splice(index, 1);
      newValues.available_languages = newLang;
    }
    newValues.default_language = val;
    setValues(newValues);
  };

  const updateBusinesses = useCallback(
    (bs, businessId) => {
      let bsFiltered =
        (bs || []).filter(
          (b) =>
            (type === b.type ||
              (!type && b.type === BUSINESS_TYPES.MERCHANT)) &&
            b.status === 0
        ) || [];
      const businessMediaUpdate = bsFiltered
        .map((bs) => bs)
        .find((bs) => bs.id === businessId);
      // remove type loop - requires arr
      setBusinesses(bsFiltered);
      // update values / update media_contents - requires obj
      setValues(initialValues(businessMediaUpdate || bsFiltered[0]));
    },
    [type]
  );

  const handleDelete = (id) => {
    bmapi
      .deleteBusiness(id)
      .then(() => bmapi.getBusinesses().then(updateBusinesses))
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.manageStores.storeRemoved",
            defaultMessage: "Negozio eliminato con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleModify = (business) => {
    setValues(initialValues(business));
    setCurrentId(business.id);
    setOpenDialog(true);
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setSaving(true);
    bmapi
      .saveBusiness(values, currentId)
      .then(() =>
        Promise.all([bmapi.getBusinesses(), bmapi.getManagers()]).then(
          ([bs, ps]) => {
            updateBusinesses(bs);
            setPermissions(ps);
          }
        )
      )
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "component.manageStores.storeSaved",
            defaultMessage: "Negozio salvato con successo",
          })
        );
        clear();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const byName = (a, b) => a.name.localeCompare(b.name);

  const update = useCallback(
    (businessId) => {
      setLoading(true);
      Promise.all([bmapi.getBusinesses(), bmapi.getManagers()])
        .then(([bs, ps]) => {
          updateBusinesses(bs, businessId);
          setPermissions(ps);
          setLoading(false);
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    },
    [bmapi, intl, notifyError, updateBusinesses]
  );

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (permissions) {
      permissions.forEach((perm) =>
        setStatus((st) =>
          st[perm.email]
            ? st
            : {
                ...st,
                [perm.email]: bmapi
                  .checkManagerRegistration(perm.id)
                  .then((res) =>
                    setStatus((s) => ({ ...s, [perm.email]: res }))
                  )
                  .catch(console.error),
              }
        )
      );
    }
  }, [bmapi, intl, notifyError, permissions]);

  const createLink = (str) => (
    <Link onClick={() => setOpenDialog(true)}>{str}</Link>
  );

  const limitReached =
    bmapi.subscription &&
    bmapi.subscription.plan_limits.max_business > 0 &&
    bmapi.subscription.plan_limits.max_business <= businesses.length;

  const deleteLanguage = (lang) => () => {
    const index = values.available_languages.indexOf(lang);
    if (index > -1) {
      const newLang = [...values.available_languages];
      newLang.splice(index, 1);
      setValues({ ...values, available_languages: newLang });
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(businessLabels.pageTitle)}</Title>
      {bmapi.subscription &&
        bmapi.subscription.plan_limits.max_business > 0 && (
          <LimitProgress
            value={businesses?.length || 0}
            limit={bmapi.subscription.plan_limits.max_business}
          />
        )}

      <Card>
        {(!businesses || loading) && <LinearProgress />}
        <Dialog
          open={openDialog && (!limitReached || currentId)}
          onClose={clear}
          fullWidth
        >
          <form onSubmit={handleCreate}>
            <DialogTitle>
              {currentId ? (
                <FormattedMessage {...businessLabels.editBusiness} />
              ) : (
                <FormattedMessage {...businessLabels.createBusiness} />
              )}
            </DialogTitle>
            <DialogContent>
              <Tabs
                value={filter}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={(_, v) => setFilter(v)}
              >
                <Tab
                  label={intl.formatMessage(common.generalData)}
                  value="general"
                  style={{ minWidth: 0 }}
                />
                {currentId && (
                  <Tab
                    label={intl.formatMessage(common.multimediaData)}
                    value="multimedia"
                    style={{ minWidth: 0 }}
                  />
                )}
              </Tabs>
              {filter === "general" && (
                <React.Fragment>
                  <TextField
                    autoFocus
                    margin="normal"
                    label={intl.formatMessage(form.name)}
                    value={values.name}
                    onChange={handleValue("name")}
                    required
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    label={intl.formatMessage(form.description)}
                    value={values.description}
                    onChange={handleValue("description")}
                    fullWidth
                  />
                  {(!type || type !== BUSINESS_TYPES.MUSEUM) && (
                    <TextField
                      margin="dense"
                      label={intl.formatMessage(form.category)}
                      value={values.category}
                      onChange={handleValue("category")}
                      fullWidth
                      select={!!bmapi.settings.businessCategories}
                    >
                      {!!bmapi.settings.businessCategories && (
                        <MenuItem value="">
                          {intl.formatMessage(form.noCategory)}
                        </MenuItem>
                      )}
                      {(bmapi.settings.businessCategories || []).map(
                        (category) => (
                          <MenuItem value={category} key={category}>
                            {storeTypes[category]
                              ? intl.formatMessage(storeTypes[category])
                              : category}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  )}
                  {type === BUSINESS_TYPES.MUSEUM && (
                    <>
                      <Language
                        value={values.default_language}
                        onChange={handleDefaultLanguage}
                        label={intl.formatMessage(form.defaultLanguage)}
                        required
                        excludesAutomatic={true}
                      />
                      <FormControl fullWidth>
                        <InputLabel>
                          {intl.formatMessage(form.otherSupportedLanguages)}
                        </InputLabel>
                        <Select
                          multiple
                          fullWidth
                          value={values.available_languages}
                          onChange={handleLanguage("available_languages")}
                          input={<Input />}
                          renderValue={(selected) => (
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                              {selected.map((lang) => (
                                <Chip
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onDelete={deleteLanguage(lang)}
                                  key={lang}
                                  label={decodeFromArray(langs, lang)}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {langs
                            .filter(
                              (l) =>
                                bmapi.settings.languages.includes(l.value) &&
                                l.value !== values.default_language
                            )
                            .map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        margin="normal"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.demo}
                              color="primary"
                              onChange={handleValue("demo")}
                              key="demo"
                              name="demo"
                            />
                          }
                          label={intl.formatMessage(form.demoStore)}
                        />
                      </FormControl>
                      <FormControl
                        style={{
                          border: "solid 1px grey",
                          padding: "10px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.private}
                              color="primary"
                              onChange={handleValue("private")}
                              key="private"
                              name="private"
                            />
                          }
                          label={intl.formatMessage(form.withAuthentication, {
                            entity: "Business",
                          })}
                        />
                        {intl.formatMessage(form.messageAuthentication, {
                          entity: "business",
                        })}
                      </FormControl>
                      <FormControl margin="normal" fullWidth>
                        <Typography variant="h6">
                          {intl.formatMessage(form.optionalPoiFields)}
                        </Typography>
                      </FormControl>
                      <FormControl
                        margin="normal"
                        fullWidth
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.show_location}
                              color="primary"
                              onChange={handleValue("show_location")}
                              key="show_location"
                              name="show_location"
                            />
                          }
                          label={intl.formatMessage(form.withLocation)}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.show_activities}
                              color="primary"
                              onChange={handleValue("show_activities")}
                              key="show_activities"
                              name="show_activities"
                            />
                          }
                          label={intl.formatMessage(form.withActivityType)}
                        />
                      </FormControl>
                      <FormControl
                        margin="normal"
                        fullWidth
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.show_outdoor}
                              color="primary"
                              onChange={handleValue("show_outdoor")}
                              key="show_outdoor"
                              name="show_outdoor"
                            />
                          }
                          label={intl.formatMessage(form.withOutdoor)}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.show_tour_transports}
                              color="primary"
                              onChange={handleValue("show_tour_transports")}
                              key="show_tour_transports"
                              name="show_tour_transports"
                            />
                          }
                          label={intl.formatMessage(form.withTourTransport)}
                        />
                      </FormControl>
                      <FormControl
                        margin="normal"
                        fullWidth
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.show_tour_prices}
                              color="primary"
                              onChange={handleValue("show_tour_prices")}
                              key="show_tour_prices"
                              name="show_tour_prices"
                            />
                          }
                          label={intl.formatMessage(form.withTourPrices)}
                        />
                      </FormControl>
                    </>
                  )}
                  {(!type || type !== BUSINESS_TYPES.MUSEUM) && (
                    <FormControl margin="normal" fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.demo}
                            color="primary"
                            onChange={handleValue("demo")}
                            key="demo"
                            name="demo"
                          />
                        }
                        label={intl.formatMessage(form.demoStore)}
                      />
                    </FormControl>
                  )}
                  {/* <TextField
              margin="dense"
              label={intl.formatMessage(form.address)}
              value={values.address}
              onChange={handleValue("address")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.city)}
              value={values.city}
              onChange={handleValue("city")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.zip)}
              value={values.zip}
              onChange={handleValue("zip")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.province)}
              value={values.province}
              onChange={handleValue("province")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.region)}
              value={values.region}
              onChange={handleValue("region")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.mobile)}
              value={values.mobile}
              onChange={handleValue("mobile")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.vatNumber)}
              value={values.vat_number}
              onChange={handleValue("vat_number")}
              fullWidth
            /> */}
                </React.Fragment>
              )}
              {filter === "multimedia" && (
                <MediaContentForm
                  media_contents={values.media_contents}
                  setMediaContentValues={setMediaContentValues}
                  mediaContentValues={mediaContentValues}
                  id={currentId}
                  loadData={update}
                  availableLanguages={[]}
                  defaultLanguage={values.default_language}
                  entity_type="Business"
                  available_types={["image"]}
                />
              )}
            </DialogContent>

            <DialogActions>
              {filter != "multimedia" && (
                <React.Fragment>
                  <Button onClick={clear} disabled={saving}>
                    <FormattedMessage
                      id="common.cancel"
                      defaultMessage="Annulla"
                    />
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={saving}
                  >
                    {currentId ? (
                      <FormattedMessage
                        id="common.save"
                        defaultMessage="Salva"
                      />
                    ) : (
                      <FormattedMessage
                        id="common.create"
                        defaultMessage="Crea"
                      />
                    )}
                  </Button>
                </React.Fragment>
              )}
              {filter === "multimedia" && (
                <Button onClick={clear} disabled={saving}>
                  <FormattedMessage id="common.close" defaultMessage="Chiudi" />
                </Button>
              )}
            </DialogActions>
          </form>
          {saving && <LinearProgress />}
        </Dialog>

        <PlanLimitAlert
          open={!!(openDialog && limitReached && !currentId)}
          onClose={clear}
        />

        {businesses &&
          (!businesses.length ? (
            <CardContent>
              <Typography gutterBottom>
                <FormattedMessage {...businessLabels.noBusinessRegistered} />
              </Typography>
              <Typography>
                <FormattedMessage
                  {...businessLabels.emptyAction}
                  values={{ link: createLink }}
                />
              </Typography>
            </CardContent>
          ) : (
            <List component="div" disablePadding>
              {businesses.sort(byName).map((b, i) => (
                <React.Fragment key={b.id}>
                  {i !== 0 ? <Divider component="li" /> : null}
                  <BusinessElement
                    business={b}
                    managers={(permissions || []).filter(
                      (p) => p.resource_id === b.id
                    )}
                    onDelete={handleDelete}
                    onModify={handleModify}
                    status={status}
                    update={update}
                    businessLabels={businessLabels}
                    setFilter={setFilter}
                  />
                </React.Fragment>
              ))}
            </List>
          ))}

        <FloatingActions>
          <Action
            icon={<Add />}
            label={intl.formatMessage(businessLabels.createBusiness)}
            action={() => setOpenDialog(true)}
          />
        </FloatingActions>
      </Card>
    </Container>
  );
}
