import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { addDays, addMonths, endOfMonth, format, startOfMonth } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import { common, form, navigation } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { BUSINESS_TYPES, PRODUCT_TYPES, TX_TYPES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

const DFLT_BUSINESS_ID = ".";
const byName = (a, b) => a.name.localeCompare(b.name);
const byCampaignName = (a, b) => a.campaign.name.localeCompare(b.campaign.name);

const transactionsType = {
  [PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD]: TX_TYPES.DECREASE,
  [PRODUCT_TYPES.CAMPAIGN_COUPON]: TX_TYPES.BURN,
};

const parseTransactions = (campaigns) => (transactions) => {
  return transactions.filter(
    (t) => t.type === transactionsType[campaigns[t.campaign_id]?.type]
  );
};

function Report({ query, store }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();
  const [transactions, setTransactions] = useState(false);

  const campaigns = useMemo(() => {
    return (store.campaigns || []).reduce(
      (acc, curr) => ({ ...acc, [curr.campaign.id]: curr.campaign }),
      {}
    );
  }, [store]);

  const loadTransactions = () => {
    bmapi
      .getTransactionsByBusiness(store.business_id, {
        // TODO
        __typeXXX: transactionsType[store.campaign.type],
        from: query.from,
        to: query.to,
      })
      .then(parseTransactions(campaigns))
      .then(setTransactions);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{store.business_name}</Typography>
          <Typography variant="h6">
            {intl.formatNumber(store.total / 100, {
              style: "currency",
              currency: "EUR",
            })}
          </Typography>
        </Box>
      </CardContent>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage(common.campaign)}</TableCell>
              <TableCell align="right">
                {intl.formatMessage(common.expense)}
              </TableCell>
              <TableCell align="right">
                {intl.formatMessage(form.reimbursement)}
              </TableCell>
              <TableCell align="right">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.campaigns.map((row) => (
              <TableRow key={row.campaign.id}>
                <TableCell component="th" scope="row">
                  {row.campaign.name}
                </TableCell>
                <TableCell align="right">
                  {intl.formatNumber(row.total / 100, {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatNumber(row.reimbursement / 100, {
                    style: "currency",
                    currency: "EUR",
                  })}{" "}
                </TableCell>
                <TableCell align="right">
                  {row.campaign.rules.reimbursement_rate / 100}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {store.total > 0 &&
        (transactions ? (
          <Box my={3}>
            <CardContent>
              <Typography variant="overline">
                {intl.formatMessage(common.transactions)}
              </Typography>
            </CardContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{intl.formatMessage(common.date)}</TableCell>
                    <TableCell>{intl.formatMessage(common.campaign)}</TableCell>
                    <TableCell align="right">
                      {intl.formatMessage(common.expense)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((row) => (
                    <TableRow key={row.timestamp}>
                      <TableCell component="th" scope="row">
                        {intl.formatDate(row.timestamp)}
                      </TableCell>
                      <TableCell>{campaigns[row.campaign_id].name}</TableCell>
                      <TableCell align="right">
                        {intl.formatNumber(row.value / 100, {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <CardContent>
            <Button onClick={loadTransactions} variant="contained">
              {intl.formatMessage({
                id: "page.reporting.showTransactions",
                defaultMessage: "Mostra transazioni",
              })}
            </Button>
          </CardContent>
        ))}
    </Card>
  );
}

const formatReimbursement = (campaigns) => {
  return campaigns
    .sort(byCampaignName)
    .map((campaign) =>
      campaign.business_partials.map((business) => ({
        ...business,
        campaign: campaign.campaign,
      }))
    )
    .flat()
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.business_id]: {
          ...curr,
          total: (acc[curr.business_id]?.total || 0) + curr.reimbursement,
          campaigns: [...(acc[curr.business_id]?.campaigns || []), curr],
        },
      };
    }, {});
};

export default function Reporting() {
  const {
    bmapi,
    businesses,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const [reimbursement, setReimbursement] = useState(false);
  const [businessId, setBusinessId] = useState(
    businesses?.length === 1 ? businesses[0].id : DFLT_BUSINESS_ID
  );
  const [from, setFrom] = useState(startOfMonth(addMonths(new Date(), -1)));
  const [to, setTo] = useState(endOfMonth(addMonths(new Date(), -1)));

  const query = useMemo(
    () => ({
      from: format(from, "yyyy-MM-dd"),
      to: format(addDays(to, 1), "yyyy-MM-dd"),
      businessId: businessId !== DFLT_BUSINESS_ID ? businessId : "",
    }),
    [businessId, from, to]
  );

  const update = useCallback(() => {
    startLoading();
    setReimbursement(false);

    bmapi
      .getReimbursement(query)
      .then(formatReimbursement)
      .then(setReimbursement)
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, query, startLoading, stopLoading]);

  const submit = (e) => {
    e.preventDefault();
    update();
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.reporting)}</Title>
      <Box mb={2}>
        <Card>
          <CardContent>
            <form onSubmit={submit}>
              <TextField
                name="businessId"
                label={intl.formatMessage(common.store)}
                value={businessId}
                onChange={(e) => setBusinessId(e.target.value)}
                select
                fullWidth
                margin="normal"
                required
              >
                {(businesses?.length > 1
                  ? [
                      { id: DFLT_BUSINESS_ID, name: "Tutti i negozi" },
                      ...businesses.sort(byName),
                    ]
                  : businesses || []
                )
                  .filter((b) => b.type !== BUSINESS_TYPES.LOOP)
                  .map((b) => (
                    <MenuItem key={b.id} value={b.id}>
                      {b.name}
                    </MenuItem>
                  ))}
              </TextField>

              <DatePicker
                label={intl.formatMessage(common.from)}
                value={from}
                onChange={setFrom}
                fullWidth
                margin="normal"
                required
              />
              <DatePicker
                label={intl.formatMessage(common.to)}
                value={to}
                onChange={setTo}
                fullWidth
                margin="normal"
                required
              />
              <FormControl fullWidth margin="normal">
                <Button variant="contained" color="primary" type="submit">
                  {intl.formatMessage(common.update)}
                </Button>
              </FormControl>
            </form>
          </CardContent>
        </Card>
      </Box>
      {reimbursement &&
        (Object.entries(reimbursement).length ? (
          <Box mb={3}>
            <Title>{intl.formatMessage(common.stores)}</Title>
            {Object.keys(reimbursement).map((id) => (
              <Box mb={2} key={id}>
                <Report store={reimbursement[id]} query={query} />
              </Box>
            ))}
          </Box>
        ) : (
          <Card>
            <CardContent>
              {intl.formatMessage({
                id: "page.reporting.noReimbursement",
                defaultMessage: "Nessun negozio con rimborsi",
              })}
            </CardContent>
          </Card>
        ))}
    </Container>
  );
}
