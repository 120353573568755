import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import QRCodeGenerator from "qrcode";

import {
  Button,
  Card,
  Container,
  FormControl,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES, USER_PREFIX } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function QrCode() {
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const [image, setImage] = useState(false);

  useEffect(() => {
    startLoading();

    QRCodeGenerator.toDataURL(`${USER_PREFIX}${bmapi.getUserInfo().user_id}`, {
      scale: 16,
    })
      .then(setImage)
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  return (
    <Container maxWidth="sm">
      <Title backUrl={CONSUMER_ROUTES.HOME}>
        <FormattedMessage
          id="pages.qrcode.title"
          defaultMessage="Il mio QR Code"
        />
      </Title>
      <Typography gutterBottom>
        <FormattedMessage
          id="pages.qrcode.info"
          defaultMessage="Utilizza questo QR Code quando ti viene richiesto dal negozio per ottenere nuove promo o card"
        />
      </Typography>
      <Card>
        {image && (
          <img src={image} alt="QR Code" style={{ maxWidth: "100%" }} />
        )}
      </Card>
      <FormControl margin="normal">
        <Button
          onClick={() => history.push(CONSUMER_ROUTES.HOME)}
          startIcon={<ArrowBackIcon />}
        >
          <FormattedMessage
            id="common.backHome"
            defaultMessage="Torna alla home"
          />
        </Button>
      </FormControl>
    </Container>
  );
}
