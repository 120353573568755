import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../../utils/bmapi-context";
import { Box, Button, Divider, MenuItem, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { common, jsc } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import FormSection from "./input/FormSection";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import { backDays } from "../../utils/utils";
import { modules } from "../../utils/quill";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "45%",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "30px",
    display: "block",
  },
  upload: {
    width: "100%",
    marginTop: "8px",
  },
}));

export default function EventCreateForm({ handleSubmit, handleBack }) {
  const intl = useIntl();
  const [eventType, setEventType] = React.useState("-");
  const classes = useStyles();
  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const [workers, setWorkers] = React.useState([]);
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [departments, setDepartments] = React.useState([]);
  const [factories, setFactories] = React.useState([]);
  const [factory, setFactory] = React.useState(getDefault("factory"));
  const [department, setDepartment] = React.useState(getDefault("department"));
  const [info, setInfo] = React.useState({});
  const [worker, setWorker] = React.useState("-");
  const [eventTypes, setEventTypes] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState("07:30");
  const [endTime, setEndTime] = React.useState("12:30");
  const [linkAttachments, setLinkAttachments] = React.useState([]);

  useEffect(() => {
    startLoading();
    console.log(info + departments + factories);
    Promise.all([
      bmapi.getUserInfo(),
      bmapi.getEventTypes(),
      bmapi.getWorkers(),
      bmapi.getDepartments(),
      bmapi.getFactories(),
    ])
      .then(([info, evTs, woks, deps, facs]) => {
        facs.unshift({ id: "-", name: intl.formatMessage(jsc.anyFactory) });
        deps.unshift({
          id: "-",
          name: intl.formatMessage(jsc.selectDepartment),
        });
        evTs.unshift({
          id: "-",
          description: intl.formatMessage(jsc.selectEvent),
        });
        setInfo(info);
        setDepartments(deps);
        setFactories(facs);
        setEventTypes(evTs);
        if (info.worker_id != "" && info.role != "TM") {
          setWorkers(
            woks
              .filter(
                (w) =>
                  w.department_id ==
                  woks.find((x) => x.worker_id == info.worker_id).department_id
              )
              .sort((a, b) => (a.worker_name > b.worker_name ? 1 : -1))
          );
        } else {
          setWorkers(
            woks.sort((a, b) => (a.worker_name > b.worker_name ? 1 : -1))
          );
        }
        if (info.role != "TM") {
          setFactory(
            facs.find(
              (y) =>
                y.id ==
                woks.find((x) => x.worker_id == info.worker_id).factory_id
            )
          );
          setDepartment(
            deps.find(
              (y) =>
                y.id ==
                woks.find((x) => x.worker_id == info.worker_id).department_id
            )
          );
        }
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    setErrorRichEditor("");
    e.preventDefault();
    var data = {
      worker_id: worker.id,
      department_id: department.id,
      company_id: department.company_id,
      factory_id: department.factory_id,
      event_type_id: eventType.id,
      event_desc: valueRichEditor,
      date: date,
      start_at: startTime,
      end_at: endTime,
      link_attachments: linkAttachments,
    };
    handleSubmit(data);
  };

  const handleInputFile = (event) => {
    let files = event.target.files;
    let validList = [...files].filter(
      (s) => s.type.includes("image") || s.type.includes("pdf")
    );
    if (validList.length == files.length) {
      linkAttachments.push(...files);
      setLinkAttachments(linkAttachments.slice(0, linkAttachments.length));
    } else {
      notifyError(intl.formatMessage(jsc.noFilePermission));
    }
  };

  function filterData(type, data) {
    if (factory && factory != "-" && factory.id != "-") {
      data = data.filter((d) => d.factory_id == factory.id || d.id == "-");
    }
    if (
      type == "woks" &&
      department &&
      department != "-" &&
      department.id != "-"
    ) {
      data = data.filter(
        (d) => d.department_id == department.id || d.id == "-"
      );
    }
    return data;
  }

  function updateFilters(factory, department) {
    if (factory != undefined && factory.id != undefined) {
      if (
        department != undefined &&
        department.id != undefined &&
        department.id != "-"
      ) {
        setFactory(
          factories.find(
            (f) =>
              f.id == departments.find((t) => t.id === department.id).factory_id
          )
        );
      } else {
        setFactory(factories.find((f) => f.id === factory.id));
      }
    } else {
      setFactory({ id: "-" });
    }
    if (department != undefined && department.id != undefined) {
      setDepartment(departments.find((t) => t.id === department.id));
    } else {
      setDepartment({ id: "-" });
    }
  }

  function getDefault(item) {
    if (
      localStorage &&
      localStorage.getItem("jsc_event_filters") != undefined
    ) {
      return JSON.parse(localStorage.getItem("jsc_event_filters"))[item];
    }
    if (item == "startDate") return backDays(7);
    if (item == "endDate") return backDays(0);
    return { id: "-" };
  }

  function removeFile(index) {
    if (index > -1) {
      // only splice array when item is found
      linkAttachments.splice(index, 1); // 2nd parameter means remove one item only
    }
    setLinkAttachments(linkAttachments.slice(0, linkAttachments.length));
  }

  return (
    <div className="msg">
      <form onSubmit={onSubmit}>
        <FormSection>
          <Box style={{ minHeight: "300px", marginTop: "20px" }}>
            <TextField
              select
              label={intl.formatMessage(jsc.selectFactory)}
              fullWidth
              value={factory.id}
              onChange={(e) => {
                updateFilters({ id: e.target.value });
              }}
              disabled={info?.role != "TM"}
            >
              {factories?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Divider style={{ marginTop: "20px" }} />
            <TextField
              select
              label={intl.formatMessage(jsc.selectDepartment)}
              fullWidth
              value={department.id}
              onChange={(e) => {
                updateFilters(factory, { id: e.target.value });
              }}
              disabled={info?.role != "TM"}
            >
              {filterData("deps", departments).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Divider style={{ marginTop: "20px" }} />
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setWorker(workers.find((w) => w.id === newValue.id));
                } else {
                  setWorker("-");
                }
              }}
              required
              id="combo-box-demo"
              options={filterData("woks", workers)}
              getOptionLabel={(option) =>
                option.worker_name + " (" + option.fiscal_code + ")"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    department.name && department.id != "-"
                      ? intl.formatMessage(jsc.selectWorkerByDept, {
                          department: department.name,
                        }) + " *"
                      : intl.formatMessage(jsc.selectWorker) + " *"
                  }
                />
              )}
            />
            <Divider style={{ marginTop: "20px" }}></Divider>
            <TextField
              margin="dense"
              label={intl.formatMessage(jsc.selectEvent)}
              value={eventType.id ? eventType.id : eventType}
              onChange={(e) => {
                setEventType(eventTypes.find((w) => w.id === e.target.value));
              }}
              fullWidth
              select
              required
            >
              {eventTypes.map((evt) => (
                <MenuItem value={evt.id} key={evt.id}>
                  {evt.id != "-"
                    ? intl.formatMessage(
                        jsc[evt.description.replace(" ", "").toLowerCase()]
                      )
                    : evt.description}
                </MenuItem>
              ))}
            </TextField>
            <Divider style={{ marginTop: "20px" }}></Divider>
            <DatePicker
              minDate={backDays(7)}
              maxDate={backDays(0)}
              margin="normal"
              fullWidth
              label={intl.formatMessage(jsc.eventDate)}
              format="yyyy-MM-dd"
              value={date}
              onChange={(e) => setDate(e)}
            />
            <TextField
              id="time"
              label={intl.formatMessage(jsc.timeStart)}
              type="time"
              defaultValue="07:30"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <TextField
              id="time"
              label={intl.formatMessage(jsc.timeEnd)}
              type="time"
              defaultValue="12:30"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(e) => setEndTime(e.target.value)}
            />
            <Divider style={{ marginTop: "20px" }}></Divider>
            <p className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
              {intl.formatMessage(jsc.eventDescription)}
            </p>
            <ReactQuill
              className={errorRichEditor != "" ? "red1d" : ""}
              theme="snow"
              modules={modules}
              value={valueRichEditor}
              placeholder={intl.formatMessage(jsc.messageText)}
              onChange={setValueRichEditor}
              error={errorRichEditor == ""}
            />
            <Divider
              style={{ marginTop: "80px", marginBottom: "20px" }}
            ></Divider>
            <div style={{ display: "flex" }}>
              <p style={{ width: "30%", paddingRight: "25px" }}>
                {intl.formatMessage(jsc.eventAttachments)}
                <input
                  accept="image/*,application/pdf"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleInputFile}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    className={classes.upload}
                  >
                    {intl.formatMessage(common.upload)}
                  </Button>
                </label>
              </p>
              <div>
                {linkAttachments.length > 0
                  ? linkAttachments?.map((option, index) => (
                      <div
                        style={{ display: "flex", marginTop: "10px" }}
                        key={option.name}
                      >
                        <p style={{ width: "90%" }}> {option.name}</p>
                        <MenuItem key={option.id} value={option.id}>
                          <DeleteIcon
                            style={{ padding: "0px" }}
                            onClick={() => removeFile(index)}
                          />
                        </MenuItem>
                      </div>
                    ))
                  : intl.formatMessage(jsc.noFile)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "-40px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.button}
                onClick={handleBack}
              >
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button
                variant="contained"
                style={
                  worker != "-" && eventType != "-" && department.id != "-"
                    ? { color: "white", background: "rgb(29, 64, 175)" }
                    : {}
                }
                type="submit"
                className={classes.button}
                onClick={onSubmit}
                disabled={
                  worker == "-" || eventType == "-" || department.id == "-"
                }
              >
                {intl.formatMessage(jsc.saveEventWorker)}
              </Button>
            </div>
          </Box>
        </FormSection>
      </form>
    </div>
  );
}
