import { defineMessages } from "react-intl";

export const ecommerce = defineMessages({
  activeProducts: {
    id: "component.ecommerce.activeProducts",
    defaultMessage: "Prodotti attivi",
  },
  addProduct: {
    id: "component.ecommerce.addProduct",
    defaultMessage: "Aggiungi prodotto",
  },
  allProducts: {
    id: "component.ecommerce.allProducts",
    defaultMessage: "Tutti i prodotti",
  },
  burgers: {
    id: "component.ecommerce.burgers",
    defaultMessage: "Hamburger",
  },
  busy: {
    id: "component.ecommerce.busy",
    defaultMessage: "Occupato",
  },
  callList: {
    id: "component.ecommerce.callList",
    defaultMessage: "Storico chiamate",
  },
  callOutcome: {
    id: "component.ecommerce.callOutcome",
    defaultMessage: "Indica l'esito della chiamata",
  },
  callRecord: {
    id: "component.ecommerce.callRecord",
    defaultMessage: "Registra chiamata",
  },
  category: {
    id: "component.ecommerce.category",
    defaultMessage: "Categoria",
  },
  contacted: {
    id: "component.ecommerce.contacted",
    defaultMessage: "Contattato",
  },
  delayCollect: {
    id: "component.ecommerce.delayCollect",
    defaultMessage: "Posticipa l'orario di ritiro",
  },
  deleteOrder: {
    id: "component.ecommerce.deleteOrder",
    defaultMessage: "Cancella l'ordine",
  },
  deleted: {
    id: "component.ecommerce.deleted",
    defaultMessage: "Cancellato",
  },
  delivered: {
    id: "component.ecommerce.delivered",
    defaultMessage: "Consegnato",
  },
  displayPriority: {
    id: "component.ecommerce.displayPriority",
    defaultMessage: "Priorità di visualizzazione",
  },
  drinks: {
    id: "component.ecommerce.drinks",
    defaultMessage: "Bevande",
  },
  dropped: {
    id: "component.ecommerce.dropped",
    defaultMessage: "Cade linea",
  },
  inactiveProducts: {
    id: "component.ecommerce.inactiveProducts",
    defaultMessage: "Prodotti inattivi",
  },
  inProgress: {
    id: "component.ecommerce.inProgress",
    defaultMessage: "In lavorazione",
  },
  item: {
    id: "component.ecommerce.item",
    defaultMessage: "Articolo",
  },
  newProduct: {
    id: "component.ecommerce.newProduct",
    defaultMessage: "Nuovo prodotto",
  },
  noAnswer: {
    id: "component.ecommerce.noAnswer",
    defaultMessage: "Non risponde",
  },
  notCollect: {
    id: "component.ecommerce.notCollect",
    defaultMessage: "Non ritirato",
  },
  openOrder: {
    id: "component.ecommerce.openOrder",
    defaultMessage: "ORDINI APERTI",
  },
  orderShort: {
    id: "component.ecommerce.orderShort",
    defaultMessage: "Ord.",
  },
  pause: {
    id: "component.ecommerce.pause",
    defaultMessage: "Pausa",
  },
  phoneContact: {
    id: "component.ecommerce.phoneContact",
    defaultMessage: "Contatta il cliente",
  },
  pizza: {
    id: "component.ecommerce.pizza",
    defaultMessage: "Pizza",
  },
  price: {
    id: "component.ecommerce.price",
    defaultMessage: "Prezzo",
  },
  products: {
    id: "component.ecommerce.products",
    defaultMessage: "Prodotti",
  },
  ready: {
    id: "component.ecommerce.ready",
    defaultMessage: "Pronto per il ritiro",
  },
  resume: {
    id: "component.ecommerce.resume",
    defaultMessage: "Riprendi",
  },
  sandwich: {
    id: "component.ecommerce.sandwich",
    defaultMessage: "Panini",
  },
  salads: {
    id: "component.ecommerce.salads",
    defaultMessage: "Insalate",
  },
  sides: {
    id: "component.ecommerce.sides",
    defaultMessage: "Contorni",
  },
  status: {
    id: "component.ecommerce.status",
    defaultMessage: "Stato",
  },
  statusBack: {
    id: "component.ecommerce.statusBack",
    defaultMessage: "Torna allo stato precedente",
  },
  statusNext: {
    id: "component.ecommerce.statusNext",
    defaultMessage: "Passa allo stato successivo",
  },
  tag: {
    id: "component.ecommerce.tag",
    defaultMessage: "Tag",
  },
  toMake: {
    id: "component.ecommerce.toMake",
    defaultMessage: "Da preparare",
  },
  total: {
    id: "component.ecommerce.total",
    defaultMessage: "Totale",
  },
  unreachable: {
    id: "component.ecommerce.unreachable",
    defaultMessage: "Non raggiungibile",
  },
});
