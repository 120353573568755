import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { account, common, confirm, errors, form } from "../../messages";
import { useHistory, Prompt } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";
import AddressAutocomplete2 from "../../components/AddressAutocomplete2";
import { wikiMuseo } from "../../messages/wikiMuseo";
import MediaContentForm from "./MediaContentForm";
import {
  //compareTranslations,
  //getEmptyArray,
  //getFields,
  convertToSlug,
  getEmptyMap,
  getMapFields,
} from "../../utils/utils";
import FieldTranslation from "../../components/FieldTranslation";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomButton = () => <span className="octicon octicon-star" />;

const CustomToolbar = ({ toolbar }) => (
  <div id={toolbar} className="mt25">
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" />
      <option value="2" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-clean" />
    <button className="ql-insertStar">
      <CustomButton />
    </button>
  </div>
);

const disabilities = [
  { value: "PHYSICAL", label: "disabilityPhysical" },
  { value: "COGNITIVE", label: "disabilityCognitive" },
  { value: "AUTISM", label: "autism" },
  { value: "HEARING", label: "disabilityHearing" },
  { value: "VISUAL", label: "disabilityVisual" },
  { value: "DEAFSIGNING", label: "disabilityDeafSigning" },
  { value: "IPOVISION", label: "disabilityIpoVision" },
  { value: "AGED", label: "aged" },
  { value: "KIDS", label: "kids" },
];

function insertStar() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "★");
  this.quill.setSelection(cursorPosition + 1);
}

function modules(toolbar) {
  return {
    toolbar: {
      container: toolbar,
      handlers: {
        insertStar: insertStar,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };
}

const byName = (a, b) => a.label.localeCompare(b.label);

export default function POI() {
  const [values, setValues] = useState({
    title: "",
    subtitle: "",
    description: "",
    activity: "",
    outdoor: false,
    accessibility_description: "",
    accessibility: [],
    parent_id: "",
    address: {},
    friendly_url: "",
    private: false,
  });
  const [valuesLng, setValuesLng] = useState({
    id: new Map(),
    title: new Map(),
    subtitle: new Map(),
    description: new Map(),
    accessibility_description: new Map(),
    friendly_url: new Map(),
  });
  /*const [valuesLng, setValuesLng] = useState({
    id: [],
    title: [],
    subtitle: [],
    description: [],
    accessibility_description: [],
    friendly_url: [],
  });*/
  const [mediaContentValues, setMediaContentValues] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [localizedMedias, setLocalizedMedias] = useState(null);
  const [allowAudioUpload, setAllowAudioUpload] = useState(null);
  const [allowVideoUpload, setAllowVideoUpload] = useState(null);
  const [allow3DModelUpload, setAllow3DModelUpload] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [showPage, setShowPage] = useState(false);
  //const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [parentPois, setParentPois] = useState([]);
  const [filter, setFilter] = useState("general");
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [valueDescriptionRichEditor, setValueDescriptionRichEditor] = useState(
    ""
  );
  const [
    valueAccessibilityDescriptionRichEditor,
    setValueAccessibilityDescriptionRichEditor,
  ] = useState("");
  const showLocation = bmapi.getUserInfo().business.show_location;
  const showActivities = bmapi.getUserInfo().business.show_activities;
  const showOutdoor = bmapi.getUserInfo().business.show_outdoor;
  const attributionMandatory = bmapi.getUserInfo().business
    .attribution_mandatory;
  const subId = bmapi.getUserInfo().business.subscription_id;

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllowAudioUpload(sub[0].plan_limits.upload_audio);
  });

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllowVideoUpload(sub[0].plan_limits.upload_video);
  });

  bmapi.getSubscriptionsById(subId).then((sub) => {
    setAllow3DModelUpload(sub[0].plan_limits.upload_3dmodel);
  });

  const handleValue = useCallback(
    (varName) => (e) => {
      setDirty(true);
      if (varName === "outdoor") {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(
          e.target.checked
        );
      } else if (varName === "title") {
        ((val) => {
          setValues((v) => ({
            ...v,
            [varName]: val,
            ["friendly_url"]: convertToSlug(val),
          }));
        })(e.target.value);
      } else {
        if (varName == "private") {
          ((val) => setValues((v) => ({ ...v, [varName]: val })))(
            e.target.checked
          );
        } else {
          ((val) => setValues((v) => ({ ...v, [varName]: val })))(
            e.target.value
          );
        }
      }
    },
    []
  );

  /*const handleValuesLng = useCallback(
    (varName) => (index) => (e) => {
      const value = [...valuesLng[varName]];
      value[index] = e.target ? e.target.value : e;
      const newValuesLng = { ...valuesLng, [varName]: value };
      setValuesLng(newValuesLng);
    },
    [valuesLng]
  );*/

  const handleValuesLng = useCallback(
    (varName) => (lang) => (e) => {
      setDirty(true);
      const value = valuesLng[varName];
      value.set(lang, e.target.value);
      const newValuesLng = { ...valuesLng, [varName]: value };
      setValuesLng(newValuesLng);
    },
    [valuesLng]
  );

  const handleAddressSelected = (addr) => {
    setDirty(true);
    /*let address = {
      city: addr.city,
      state: addr.country,
      zip: addr.postalCode,
      province: addr.province,
      street: addr.route,
    };
    const newValues = { ...values, address: address };
    */
    const newValues = { ...values, address: addr };
    setValues(newValues);
  };

  const tabSwitch = (v) => {
    if (!dirty || (dirty && filter === "multimedia")) {
      setFilter(v);
    }
    if (v !== "general" && filter === "general" && dirty) {
      const res = window.confirm(intl.formatMessage(confirm.tabPrompt));
      if (res === true) {
        setFilter(v);
      }
    }
  };

  const deleteDisability = (disabilityToDelete) => {
    let newaccessibility = [];
    values.accessibility.map((disability) => {
      if (disability.value != disabilityToDelete.value) {
        newaccessibility.push(disability);
      }
    });
    setDirty(true);
    setValues((v) => ({
      ...v,
      ["accessibility"]: newaccessibility,
    }));
  };

  const getLabel = useCallback((code) => {
    const filtered = disabilities.filter((obj) => obj.value === code);
    return filtered && filtered.length ? filtered[0].label : "";
  }, []);

  const isSelected = (code) => {
    if (!values.accessibility) {
      return false;
    }
    const filtered = values.accessibility.filter((obj) => obj.value === code);
    return filtered && filtered.length;
  };

  const createLocalizedObj = () => {
    const localizedObj = [];

    for (let i = 0; i < availableLanguages.length; ++i) {
      /*const titleLng = valuesLng.title[i];
      const subtitleLng = valuesLng.subtitle[i];
      const descriptionLng = valuesLng.description[i];
      const accessibility_descriptionLng =
        valuesLng.accessibility_description[i];
      const friendly_urlLng = valuesLng.friendly_url[i];*/
      localizedObj.push({
        id: valuesLng.id.get(availableLanguages[i]) || "",
        title: valuesLng.title.get(availableLanguages[i]) || "",
        subtitle: valuesLng.subtitle.get(availableLanguages[i]) || "",
        description: valuesLng.description.get(availableLanguages[i]) || "",
        accessibility_description:
          valuesLng.accessibility_description.get(availableLanguages[i]) || "",
        friendly_url: valuesLng.friendly_url.get(availableLanguages[i]) || "",
        /*id: valuesLng.id[i],
        title: titleLng,
        subtitle: subtitleLng,
        description: descriptionLng,
        accessibility_description: accessibility_descriptionLng,
        friendly_url: friendly_urlLng,*/
        lang: availableLanguages[i],
      });
    }

    localizedObj.push({
      id: values.idDefaultLng,
      title: values.title,
      subtitle: values.subtitle,
      description: valueDescriptionRichEditor,
      accessibility_description: valueAccessibilityDescriptionRichEditor,
      friendly_url: values.friendly_url,
      lang: defaultLanguage,
    });
    return localizedObj;
  };

  useEffect(() => {
    const defaultLang = bmapi.getUserInfo().business.default_language;
    const availableLang = bmapi
      .getUserInfo()
      .business.available_languages.filter((lang) => lang !== defaultLang);
    setDefaultLanguage(defaultLang);
    availableLang.sort();
    setAvailableLanguages(availableLang);
    loadParentList();
    const hasChildren = new URLSearchParams(location.search).get(
      "has_children"
    );
    setHasChildren(hasChildren);
    const poiId = new URLSearchParams(location.search).get("id");
    if (poiId) {
      if (bmapi) {
        loadPoi(poiId);
      }
    } else {
      setShowPage(true);
      const newValues = {
        id: getEmptyMap(availableLang),
        title: getEmptyMap(availableLang),
        subtitle: getEmptyMap(availableLang),
        description: getEmptyMap(availableLang),
        accessibility_description: getEmptyMap(availableLang),
        friendly_url: getEmptyMap(availableLang),
        /*id: getEmptyArray(availableLang.length),
        title: getEmptyArray(availableLang.length),
        subtitle: getEmptyArray(availableLang.length),
        description: getEmptyArray(availableLang.length),
        accessibility_description: getEmptyArray(availableLang.length),
        friendly_url: getEmptyArray(availableLang.length),*/
      };
      setValuesLng(newValues);
    }
  }, [bmapi, loadPoi, loadParentList]);

  const defaultPoiField = (resp) => {
    let defaultPoi = null;
    resp.localized_pois.map((defaultLanguagePoi) => {
      if (
        defaultLanguagePoi.lang ===
        bmapi.getUserInfo().business.default_language
      ) {
        defaultPoi = defaultLanguagePoi;
      }
    });
    const poi = {
      id: resp.id,
      title: defaultPoi.title,
      subtitle: defaultPoi.subtitle,
      description: defaultPoi.description,
      accessibility_description: defaultPoi.accessibility_description,
      friendly_url: defaultPoi.friendly_url,
      media_contents: defaultPoi.media_contents,
      accessibility: !resp.accessibility
        ? []
        : resp.accessibility.map((code) => {
            return { value: code, label: getLabel(code) };
          }),
      private: defaultPoi.private,
    };
    return poi;
  };

  const loadPoi = useCallback(
    (poiId) => {
      bmapi
        .getPOIs({ id: poiId, business: bmapi.getUserInfo().business.id })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
            setShowPage(false);
          } else {
            setShowPage(true);
            let poi = {
              ...resp[0],
              accessibility: !resp[0].accessibility
                ? []
                : resp[0].accessibility.map((code) => {
                    return { value: code, label: getLabel(code) };
                  }),
              parent_id: !resp[0].parent_id ? "x" : resp[0].parent_id,
              private: resp[0].private,
            };
            const defaultLang = bmapi.getUserInfo().business.default_language;
            const newLocalizedPois = poi.localized_pois.filter(
              (poi) => poi.lang !== defaultLang
            );
            //newLocalizedPois.sort(compareTranslations);
            const newValues = {
              title: getMapFields(newLocalizedPois, "title", "lang"),
              subtitle: getMapFields(newLocalizedPois, "subtitle", "lang"),
              description: getMapFields(
                newLocalizedPois,
                "description",
                "lang"
              ),
              accessibility_description: getMapFields(
                newLocalizedPois,
                "accessibility_description",
                "lang"
              ),
              friendly_url: getMapFields(
                newLocalizedPois,
                "friendly_url",
                "lang"
              ),
              id: getMapFields(newLocalizedPois, "id", "lang"),
              /*title: getFields(newLocalizedPois, "title"),
              subtitle: getFields(newLocalizedPois, "subtitle"),
              description: getFields(newLocalizedPois, "description"),
              accessibility_description: getFields(
                newLocalizedPois,
                "accessibility_description"
              ),
              friendly_url: getFields(newLocalizedPois, "friendly_url"),
              id: getFields(newLocalizedPois, "id"),*/
            };
            setValuesLng(newValues);
            const newDefaultPoi = poi.localized_pois.filter(
              (localizedPoi) => localizedPoi.lang === defaultLang
            );
            if (newDefaultPoi.length === 1) {
              poi = {
                ...poi,
                idDefaultLng: newDefaultPoi[0].id,
                title: newDefaultPoi[0].title,
                subtitle: newDefaultPoi[0].subtitle,
                description: newDefaultPoi[0].description,
                accessibility_description:
                  newDefaultPoi[0].accessibility_description,
                friendly_url:
                  newDefaultPoi[0].friendly_url ||
                  (newDefaultPoi[0].title &&
                    convertToSlug(newDefaultPoi[0].title)),
              };
            }
            setValues(poi);
            setValueDescriptionRichEditor(poi.description);
            setValueAccessibilityDescriptionRichEditor(
              poi.accessibility_description
            );
            const localizedMedia = new Map();
            poi.localized_pois.map((localizedPoi) => {
              localizedMedia.set(
                localizedPoi.lang,
                localizedPoi.media_contents === null
                  ? []
                  : localizedPoi.media_contents
              );
            });
            setLocalizedMedias(localizedMedia);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          setShowPage(false);
        });
    },
    [bmapi, intl, notifyError, getLabel]
  );

  const loadParentList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getPOIs({
          business: bmapi.getUserInfo().business.id,
          lang: bmapi.getUserInfo().business.default_language,
        })
        .then((resp) => {
          const poisList = resp.filter((pois) => !pois.parent_id);
          let parentPoisList = [];
          if (resp) {
            parentPoisList = poisList.map((parentPoi) => {
              return {
                value: parentPoi.id,
                label: parentPoi.localized_pois[0].title,
              };
            });
            parentPoisList.splice(0, 0, { value: "x", label: "Nessun gruppo" });
          }
          setParentPois(parentPoisList);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, setParentPois]);

  const confirmPoi = (event) => {
    event.preventDefault();
    setDirty(false);
    const localizedPois = createLocalizedObj();
    let poi = {
      ...values,
      accessibility: !values.accessibility
        ? []
        : values.accessibility.map((obj) => obj.value),
      localized_pois: localizedPois,
      parent_id: values.parent_id === "x" ? "" : values.parent_id,
    };
    if (!poi.id) {
      bmapi
        .createPOI(poi)
        .then((resp) => {
          history.push(MANAGER_ROUTES.POI + "?id=" + resp.id);
          poi = defaultPoiField(resp);
          setValues(poi);
          loadPoi(poi.id);
          notifySuccess(intl.formatMessage(account.saveConfirm));
          const localizedMedia = new Map();
          availableLanguages.map((lang) => {
            localizedMedia.set(lang, []);
          });
          localizedMedia.set(defaultLanguage, []);
          setLocalizedMedias(localizedMedia);
          if (!values.parent_id || values.parent_id === "x") {
            history.push(MANAGER_ROUTES.MANAGE_POI_LIST);
            return;
          }
          setFilter("multimedia");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updatePOI(poi)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          history.push(MANAGER_ROUTES.MANAGE_POI_LIST);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Prompt when={dirty} message={intl.formatMessage(confirm.exitPrompt)} />
      <Tabs
        value={filter}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => {
          tabSwitch(v);
          //setFilter(v);
        }}
      >
        <Tab
          label={intl.formatMessage(common.generalData)}
          value="general"
          style={{ minWidth: 0 }}
        />
        {values.id && values.parent_id && values.parent_id !== "x" && (
          <Tab
            label={intl.formatMessage(common.multimediaData)}
            value="multimedia"
            style={{ minWidth: 0 }}
          />
        )}
      </Tabs>

      {filter === "general" && (
        <>
          {showPage && (
            <form onSubmit={confirmPoi}>
              <Box mb={2}>
                <Card>
                  <CardContent>
                    <FormControl fullWidth required margin="normal">
                      <InputLabel>{intl.formatMessage(form.title)}</InputLabel>
                      <Input
                        type="text"
                        value={values.title}
                        name="title"
                        onChange={handleValue("title")}
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              items={valuesLng.title}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("title")}
                              title={intl.formatMessage(form.title)}
                              language={defaultLanguage}
                            ></FieldTranslation>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>
                        {intl.formatMessage(form.subtitle)}
                      </InputLabel>
                      <Input
                        type="text"
                        value={values.subtitle}
                        name="subtitle"
                        onChange={handleValue("subtitle")}
                        endAdornment={
                          <InputAdornment position="end">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.subtitle}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("subtitle")}
                              title={intl.formatMessage(form.subtitle)}
                            ></FieldTranslation>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    {(hasChildren === "false" || !hasChildren) &&
                      parentPois?.length > 0 && (
                        <TextField
                          margin="normal"
                          label={intl.formatMessage(wikiMuseo.group)}
                          value={values.parent_id}
                          onChange={handleValue("parent_id")}
                          fullWidth
                          select
                          required
                        >
                          {parentPois &&
                            parentPois
                              .filter(
                                (parentPois) => parentPois.value !== values.id
                              )
                              .sort(byName)
                              .map((parentPoi) => (
                                <MenuItem
                                  key={parentPoi.value}
                                  value={parentPoi.value}
                                >
                                  {parentPoi.label}
                                </MenuItem>
                              ))}
                        </TextField>
                      )}
                    {values.parent_id !== "x" && (
                      <>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            style={{ fontSize: "13px", margin: "-18px 0px" }}
                          >
                            {intl.formatMessage(form.description)}
                          </InputLabel>
                          <div className="flaggy">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.description}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng("description")}
                              title={intl.formatMessage(form.description)}
                              editor={true}
                            ></FieldTranslation>
                          </div>
                          <CustomToolbar toolbar="toolbar" />
                          <ReactQuill
                            onChange={setValueDescriptionRichEditor}
                            placeholder="Inserire la descrizione del punto di interese"
                            modules={modules("#toolbar")}
                            value={valueDescriptionRichEditor}
                            theme="snow"
                          />
                          {/*<ReactQuill
                            className={errorRichEditor != "" ? "red1d" : "pt25"}
                            theme="snow"
                            modules={modules}
                            value={valueRichEditor}
                            placeholder="Inserire il nome dell’autore e il tipo di licenza con il quale il contenuto può essere condiviso"
                            onChange={setValueRichEditor}
                            error={errorRichEditor == ""}
                          />*/}
                        </FormControl>

                        {showActivities && (
                          <TextField
                            margin="normal"
                            label={intl.formatMessage(wikiMuseo.type)}
                            value={values.activity}
                            onChange={handleValue("activity")}
                            fullWidth
                            select
                          >
                            {[
                              {
                                value: "cultural",
                                label: intl.formatMessage(
                                  wikiMuseo.activityCultural
                                ),
                              },
                              {
                                value: "naturalistic",
                                label: intl.formatMessage(
                                  wikiMuseo.activityNature
                                ),
                              },
                              {
                                value: "food_and_bev",
                                label: intl.formatMessage(
                                  wikiMuseo.activityFood_Bev
                                ),
                              },
                              {
                                value: "recreational",
                                label: intl.formatMessage(
                                  wikiMuseo.activityRecreational
                                ),
                              },
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}

                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {intl.formatMessage(wikiMuseo.disabilityType)}
                          </InputLabel>
                          <Select
                            name="disabilities"
                            multiple
                            fullWidth
                            value={values.accessibility}
                            onChange={handleValue("accessibility")}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <Box
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {selected.map((disability) => (
                                  <Chip
                                    onMouseDown={(event) => {
                                      event.stopPropagation();
                                    }}
                                    onDelete={() =>
                                      deleteDisability(disability)
                                    }
                                    key={disability.value}
                                    label={intl.formatMessage(
                                      wikiMuseo[disability.label]
                                    )}
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {disabilities.map(
                              (disability) =>
                                !isSelected(disability.value) && (
                                  <MenuItem
                                    key={disability.value}
                                    value={disability}
                                  >
                                    {intl.formatMessage(
                                      wikiMuseo[disability.label]
                                    )}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                          {/*<InputLabel>
                            {intl.formatMessage(
                              wikiMuseo.accessibilityDescription
                            )}
                          </InputLabel>
                          <Input
                            type="text"
                            value={values.accessibility_description}
                            name="accessibility_description"
                            onChange={handleValue("accessibility_description")}
                            multiline
                            endAdornment={
                              <InputAdornment position="end">
                                <FieldTranslation
                                  language={defaultLanguage}
                                  items={valuesLng.accessibility_description}
                                  availableLanguages={availableLanguages}
                                  onChange={handleValuesLng(
                                    "accessibility_description"
                                  )}
                                  title={intl.formatMessage(
                                    wikiMuseo.accessibilityDescription
                                  )}
                                ></FieldTranslation>
                              </InputAdornment>
                            }
                          />*/}
                          <InputLabel
                            style={{ fontSize: "13px", margin: "-18px 0px" }}
                          >
                            {intl.formatMessage(
                              wikiMuseo.accessibilityDescription
                            )}
                          </InputLabel>
                          <div className="flaggy">
                            <FieldTranslation
                              language={defaultLanguage}
                              items={valuesLng.accessibility_description}
                              availableLanguages={availableLanguages}
                              onChange={handleValuesLng(
                                "accessibility_description"
                              )}
                              title={intl.formatMessage(
                                wikiMuseo.accessibilityDescription
                              )}
                              editor={true}
                            ></FieldTranslation>
                          </div>
                          <CustomToolbar toolbar="toolbar2" />
                          <ReactQuill
                            onChange={
                              setValueAccessibilityDescriptionRichEditor
                            }
                            theme="snow"
                            placeholder="Inserire la descrizione accessibilità del punto di interese"
                            modules={modules("#toolbar2")}
                            value={valueAccessibilityDescriptionRichEditor}
                          />
                        </FormControl>

                        <FormControl fullWidth margin="normal" required>
                          <InputLabel>Friendly URL</InputLabel>
                          <Input
                            type="text"
                            value={values.friendly_url}
                            name="friendly_url"
                            onChange={handleValue("friendly_url")}
                            multiline
                            disabled
                            /*endAdornment={
                              <InputAdornment position="end">
                                <FieldTranslation
                                  language={defaultLanguage}
                                  items={valuesLng.friendly_url}
                                  availableLanguages={availableLanguages}
                                  onChange={handleValuesLng("friendly_url")}
                                  title="Friendly URL"
                                ></FieldTranslation>
                              </InputAdornment>
                            }*/
                          />
                        </FormControl>

                        {showOutdoor && (
                          <FormControl margin="normal">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.outdoor}
                                  onChange={handleValue("outdoor")}
                                  color="primary"
                                />
                              }
                              label={intl.formatMessage(wikiMuseo.outdoor)}
                            />
                          </FormControl>
                        )}
                        <FormControl
                          style={{
                            border: "solid 1px grey",
                            padding: "10px",
                            margin: "10px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.private}
                                color="primary"
                                onChange={handleValue("private")}
                                key="private"
                                name="private"
                              />
                            }
                            label={intl.formatMessage(form.withAuthentication, {
                              entity: "Punto di interesse",
                            })}
                          />
                          {intl.formatMessage(form.messageAuthentication, {
                            entity: "punto di interesse",
                          })}
                        </FormControl>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Box>

              {showLocation && values.parent_id !== "x" && (
                <Box mb={2}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" margin="normal">
                        Località
                      </Typography>
                      <AddressAutocomplete2
                        googlePlacesKey="AIzaSyDmTGCjqNH942kSPubb1OblDbDsT2YaIrI"
                        types="address"
                        onChange={handleAddressSelected}
                        address={values.address}
                      />
                    </CardContent>
                  </Card>
                </Box>
              )}

              <Box mb={2}>
                <CardActions>
                  <Button
                    type="submit"
                    //onClick={confirmPoi}
                    variant="contained"
                    color="primary"
                  >
                    {intl.formatMessage(
                      values.id ? common.update : common.create
                    )}
                  </Button>
                  {values.id && (
                    <>
                      <Button
                        onClick={() => {
                          history.push(MANAGER_ROUTES.MANAGE_POI_LIST);
                        }}
                        variant="contained"
                      >
                        {intl.formatMessage(common.cancel)}
                      </Button>
                      {/*<Confirm
                        open={showUnsavedAlert}
                        onConfirm={() =>
                          history.push(MANAGER_ROUTES.MANAGE_POI_LIST)
                        }
                        onCancel={() => {
                          setShowUnsavedAlert(false);
                        }}
                        text={intl.formatMessage(confirm.exitPrompt)}
                      />*/}
                    </>
                  )}
                </CardActions>
              </Box>
            </form>
          )}
        </>
      )}

      {filter === "multimedia" && (
        <MediaContentForm
          media_contents={localizedMedias}
          setMediaContentValues={setMediaContentValues}
          mediaContentValues={mediaContentValues}
          id={values.id}
          loadData={loadPoi}
          availableLanguages={availableLanguages}
          defaultLanguage={defaultLanguage}
          allowAudioUpload={allowAudioUpload}
          allow3DModelUpload={allow3DModelUpload}
          allowVideoUpload={allowVideoUpload}
          entity_type="PointOfInterest"
          available_types={[
            "image",
            "audio",
            "video",
            "3dmodel",
            "externalContent",
          ]}
          attribution_mandatory={attributionMandatory}
        />
      )}

      <FormControl margin="normal">
        <Button
          onClick={() => history.push(MANAGER_ROUTES.MANAGE_POI_LIST)}
          startIcon={<ArrowBackIcon />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </FormControl>
    </Container>
  );
}
