import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as Tool,
  Legend,
  Line,
} from "recharts";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { common } from "../../messages";

function SensorGraph({
  datas,
  startDate,
  endDate,
  device,
  source,
  group,
  customer,
  open,
  setOpen,
}) {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [line, setLine] = useState([]);

  const getColumns = useCallback(
    (type) => {
      let arr = [];
      const colors = ["0000FF", "FF0000", "008000", "FFA500", "991199"];
      for (let i = 0; i < datas.length; i++) {
        if (type === "columns") arr.push(["columns", datas[i]]);
        else if (type === "line")
          arr.push({ name: datas[i], color: colors[i] });
      }
      return arr;
    },
    [datas]
  );

  useEffect(() => {
    startLoading();
    const columns = getColumns("columns");
    const lineStyle = getColumns("line");
    let params = [
      ...columns,
      ["page", -1], //-1
      ["qty", -1], //-1
      ["from", startDate.replace("T", "_")],
      ["to", endDate.replace("T", "_")],
      ["device", device === "-" ? "" : device],
      ["source", source === "-" ? "" : source],
      ["group", group === "-" ? "" : group],
      ["customer", customer === "-" ? "" : customer],
    ];
    bmapi
      .getLogsColumns(params)
      .then((resp) => {
        resp.map(
          (resp) =>
            (resp.Timestamp = moment
              .unix(resp.Timestamp)
              .format("DD/MM/YY HH:mm:SS"))
        );
        setData(resp);
        setLine(lineStyle);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    bmapi,
    customer,
    device,
    endDate,
    startDate,
    group,
    source,
    getColumns,
    intl,
    notifyError,
    startLoading,
    stopLoading,
  ]);

  const formatter = (item) => {
    return item.toExponential(4);
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"lg"}>
      <DialogContent style={{ margin: "auto" }}>
        {line.map((d) => (
          <ResponsiveContainer width={1100} height={200} key={d.name}>
            <LineChart
              width={1000}
              height={500}
              data={data}
              style={{ marginTop: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" /*horizontal={false}*/ />
              <XAxis
                dataKey="Timestamp"
                padding={{ left: 10, right: 10 }}
                reversed
                angle={8}
                textAnchor="start"
              />
              <YAxis
                type="number"
                domain={[
                  "dataMin - (0.1 * dataMin)",
                  "dataMax + (0.1 * dataMax)",
                ]}
                tickFormatter={formatter}
                width={70}
                tickCount={data.length}
              />
              <Tool />
              <Legend verticalAlign="top" height={36} />
              <Line
                type="monotone"
                dataKey={d.name}
                stroke={"#" + d.color}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            background: "#1d40af",
            color: "white",
          }}
          onClick={() => {
            setOpen(false);
          }}
          variant="contained"
        >
          {intl.formatMessage(common.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SensorGraph;
