import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Typography,
  ListSubheader,
  useTheme,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Lens,
  Menu as MenuIcon,
} from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import {
  BUSINESS_TYPES,
  EVENT_STATUS,
  MANAGER_ROUTES,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { addMinutes, format } from "date-fns";
import LanguageSelector from "./LanguageSelector";

const EXTEND_MINUTES = 10;

const getEndDate = (e) =>
  new Date(`${e.starting_date.substr(0, 10)} ${e.ending_time}`);
const byEndDate = (a, b) => getEndDate(a) - getEndDate(b);

export default function TopBarManager({ links, logo, actions }) {
  const {
    bmapi,
    businessId,
    businesses,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
    userId,
  } = useBmapi();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const classes = styles.useStyles();
  const [selected, setSelected] = useState(bmapi.getUserInfo().business);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvents, setAnchorElEvents] = useState(null);
  const [events, setEvents] = useState([]);
  const [ended, setEnded] = useState(false);
  const [minutes, setMinutes] = useState(EXTEND_MINUTES);

  const List = React.forwardRef(
    ({ els, handleChange, selected, title }, ref) => {
      return (
        <React.Fragment>
          {els.length > 0 && (
            <ListSubheader style={{ backgroundColor: "white" }}>
              {title}
            </ListSubheader>
          )}
          {els.map((b) => (
            <MenuItem
              key={b.id}
              onClick={() => handleChange(b)}
              selected={b.id === selected.id}
              ref={ref}
            >
              {b.name}
            </MenuItem>
          ))}
        </React.Fragment>
      );
    }
  );
  List.displayName = "List";

  const updateEnding = useCallback((events) => {
    setEnded(false);
    if (events?.length) {
      const toEnd = events.find((e) => getEndDate(e) < new Date());

      if (toEnd) {
        setEnded(toEnd);
      } else {
        const to = setTimeout(
          () => setEnded(events[0]),
          getEndDate(events[0]) - new Date()
        );
        return () => clearTimeout(to);
      }
    }
  }, []);

  const updateEvents = useCallback(() => {
    if (businessId) {
      bmapi
        .getBusinessEvents()
        .then((evs) =>
          (evs || [])
            .filter((e) => e.status === EVENT_STATUS.STARTED)
            .sort(byEndDate)
        )
        .then((evs) => {
          setEvents(evs);
          updateEnding(evs);
        })
        .catch(console.error);
    }
  }, [bmapi, businessId, updateEnding]);

  useEffect(() => {
    updateEvents();
    const to = setInterval(updateEvents, 10000);
    return () => clearInterval(to);
  }, [updateEvents]);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleEventNotificationClick = (event) => {
    if (events.length === 1) {
      goToEvent(events[0].campaign_ids[0]);
    } else {
      setAnchorElEvents(event.currentTarget);
    }
  };
  const goToEvent = (id) => {
    setAnchorElEvents(null);
    history.push(MANAGER_ROUTES.CAMPAIGN.replace(":campaignId/", id));
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleCloseEvents = () => setAnchorElEvents(null);
  const handleChange = (business) => {
    startLoading();
    bmapi.changeBusiness(business).then(stopLoading);
    setSelected(business);
    handleClose();
    history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
  };

  const alpha = (a, b) => a.name.localeCompare(b.name);

  const loops = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.LOOP && b.tenant_loop_default)
    .sort(alpha);

  // TODO: verificare cosa contiene subscriptions (a parte il business che rappresenta la sottoscrizione)
  const subscriptions =
    bmapi.subscription?.plan_limits?.max_business === 1
      ? []
      : businesses
          ?.filter(
            (b) => b.type === BUSINESS_TYPES.LOOP && !b.tenant_loop_default
          )
          .sort(alpha);
  /*
  const subscriptions = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.LOOP && !b.tenant_loop_default)
    .sort(alpha);*/
  const stores = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.MERCHANT)
    .sort(alpha);
  const museums = businesses
    ?.filter((b) => b.type === BUSINESS_TYPES.MUSEUM)
    .sort(alpha);

  const handleStop = () => {
    startLoading();

    return bmapi
      .stopBusinessEvent(ended.id, ended.location_id)
      .then(() => {
        updateEvents();
        notifySuccess(
          intl.formatMessage({
            id: "component.topbar.eventEndSuccessMessage",
            defaultMessage: "Evento concluso",
          })
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleExtend = () => {
    startLoading();

    return bmapi
      .saveBusinessEvent(
        {
          campaign_ids: ended.campaign_ids,
          ending_date: format(new Date(ended.starting_date), "yyyy-MM-dd"),
          ending_time: format(addMinutes(getEndDate(ended), minutes), "HH:mm"),
          name: ended.name,
          starting_date: format(new Date(ended.starting_date), "yyyy-MM-dd"),
        },
        ended.id
      )
      .then(() => {
        updateEvents();
        notifySuccess(
          intl.formatMessage({
            id: "component.topbar.eventExtendSuccessMessage",
            defaultMessage: "Evento prorogato",
          })
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Dialog open={!!ended} maxWidth={"sm"} fullWidth>
        <DialogTitle>
          {intl.formatMessage({
            id: "component.topbar.eventEnded",
            defaultMessage: "Evento concluso",
          })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage(
            {
              id: "component.topbar.eventEndedMessage",
              defaultMessage:
                "Un evento di {business} in corso presso {location} dovrebbe essere giunto al termine.",
            },
            {
              business: ended.business_name,
              location: ended.location_name,
            }
          )}
        </DialogContent>

        <DialogActions>
          <TextField
            select
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
          >
            {[10, 15, 20, 30].map((option) => (
              <MenuItem key={option} value={option}>
                {intl.formatMessage(
                  {
                    id: "component.topbar.minutes",
                    defaultMessage:
                      "{minutes, plural, one {# minuto} other {# minuti}}",
                  },
                  { minutes: option }
                )}
              </MenuItem>
            ))}
          </TextField>
          <Button onClick={handleExtend} variant="contained">
            {intl.formatMessage(
              {
                id: "component.topbar.extendEvent",
                defaultMessage: "Prorogare",
              },
              { minutes }
            )}
          </Button>
          <Button onClick={handleStop} variant="contained" color="primary">
            {intl.formatMessage({
              id: "component.topbar.endEvent",
              defaultMessage: "Concludere",
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <Toolbar>
        {!!userId && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div style={{ flex: "1 1 auto", margin: "0 5px" }}>
          <Logo logo={logo} link={MANAGER_ROUTES.HOME} />
        </div>
        {!bmapi.needsPrivacy() && businesses?.length > 0 && (
          <div style={{ flex: "0 0 auto" }}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {!!events?.length && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={handleEventNotificationClick}
                  >
                    <Lens
                      style={{
                        color: theme.palette.error.main,
                        display: "block",
                      }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElEvents}
                    keepMounted
                    open={Boolean(anchorElEvents)}
                    onClose={handleCloseEvents}
                  >
                    {events.map((ev) => (
                      <MenuItem
                        key={ev.id}
                        onClick={() => goToEvent(ev.campaign_ids[0])}
                      >
                        {ev.name} {ev.starting_time} | {ev.location_name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              )}
              <Grid item>
                {businesses?.length > 1 ? (
                  <React.Fragment>
                    <Button
                      onClick={handleClick}
                      endIcon={<ArrowDropDownIcon />}
                      variant="contained"
                      color="primary"
                      disableElevation
                    >
                      {selected.name}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted={false}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <List
                        els={loops}
                        handleChange={handleChange}
                        selected={selected}
                        title={intl.formatMessage({
                          id: "component.topbar.loops",
                          defaultMessage: "Circuiti",
                        })}
                      />

                      <List
                        els={subscriptions}
                        handleChange={handleChange}
                        selected={selected}
                        title={intl.formatMessage({
                          id: "component.topbar.subscriptions",
                          defaultMessage: "Sottoscrizioni",
                        })}
                      />

                      <List
                        els={stores}
                        handleChange={handleChange}
                        selected={selected}
                        title={intl.formatMessage({
                          id: "component.topbar.stores",
                          defaultMessage: "Negozi",
                        })}
                      />

                      <List
                        els={museums}
                        handleChange={handleChange}
                        selected={selected}
                        title={intl.formatMessage({
                          id: "component.topbar.museums",
                          defaultMessage: "Musei/Agenzie",
                        })}
                      />
                    </Menu>
                  </React.Fragment>
                ) : (
                  <Typography variant="button" display="block">
                    {selected.name}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        <LanguageSelector />
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={links}
        actions={actions}
        logoImage={logo}
      />
    </AppBar>
  );
}
