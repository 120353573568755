import { defineMessages } from "react-intl";

export const paymentMessages = defineMessages({
  success: {
    id: "payment.success",
    defaultMessage: "Pagamento effettuato con successo",
  },
  subscriptionSuccess: {
    id: "payment.subscriptionSuccess",
    defaultMessage:
      'Complimenti! L\'abbonamento è stata registrato. Puoi accedere ai tuoi abbonamenti in qualsiasi momento tramite il menu "Abbonamenti".',
  },
  subscriptionSuccessWait: {
    id: "payment.subscriptionSuccessWait",
    defaultMessage:
      "Verrai contattato nel più breve tempo possibile per proseguire con la tua nuova sottoscrizione.",
  },
  gotoSubscription: {
    id: "payment.gotoSubscription",
    defaultMessage: "Completa il tuo abbonamento",
  },
  canceled: {
    id: "payment.canceled",
    defaultMessage: "Pagamento annullato",
  },
  subscriptionCanceled: {
    id: "payment.subscriptionCanceled",
    defaultMessage:
      "Hai annullato la procedura di pagamento e non ti è stato addebitato alcun importo. Per ricominciare la procedura di pagamento premi il pulsante ABBONATI",
  },
  error: {
    id: "payment.error",
    defaultMessage: "Procedura di pagamento interrotta",
  },
  subscriptionError: {
    id: "payment.subscriptionError",
    defaultMessage: "Si è verificato un errore nella procedura di pagamento: ",
  },
  subscriptionErrorInfo: {
    id: "payment.subscriptionErrorInfo",
    defaultMessage:
      'Se vuoi avere più informazioni o se preferisci altre modalità di pagamento scrivi la tua richiesta nel campo di testo sotto e premi "Invia"',
  },
  subscriptionErrorRequestInfo: {
    id: "payment.subscriptionErrorRequestInfo",
    defaultMessage: "Inserisci richiesta",
  },
  subscriptionErrorRequestInfoSent: {
    id: "payment.subscriptionErrorInfoSent",
    defaultMessage: "La tua richiesta è stata inviata con successo",
  },
  wrongPan: {
    id: "payment.wrongPan",
    defaultMessage: "Il PAN della carta è errato",
  },
  authDenied: {
    id: "payment.authDenied",
    defaultMessage: "Autorizzazione negata",
  },
  orderDuplication: {
    id: "payment.orderDuplication",
    defaultMessage: "Ordine di pagamento duplicato",
  },
  unconfirmed3dsecure: {
    id: "payment.unconfirmed3dsecure",
    defaultMessage: "Conferma 3D secure non pervenuta",
  },
  cardNotAuthForTrx: {
    id: "payment.cardNotAuthForTrx",
    defaultMessage: "La carta non è autorizzata per questo tipo di operazione",
  },
  timeout: {
    id: "payment.timeout",
    defaultMessage: "Tempo limite superato",
  },
  invalidCard: {
    id: "payment.invalidCard",
    defaultMessage: "Carta non valida (verifica se è scaduta o bloccata)",
  },
  insufficientFunds: {
    id: "payment.insufficientFunds",
    defaultMessage: "Fondi insufficienti",
  },
  /*
  genericError: {
    id: "payment.genericError",
    defaultMessage:
      "Si è verificato un errore durante la procedura di pagamento",
  },*/
});
