import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common, jsc } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import moment from "moment";

const byName = (a, b) => a.name.localeCompare(b.name);

function filterData(dev, srcs) {
  if (dev.id !== "-") {
    return [
      ...srcs.filter((src) => src.device_id === dev.id),
      { source_id: "-", source_description: "Qualsiasi sorgente" },
    ];
  } else return srcs;
}

function SensorGraphColumnSelection({
  open,
  setOpen,
  setOpenGraph,
  sendGraphData,
  dev,
  src,
  setDevice,
  devices,
  setSource,
  sources,
  startDate,
  endDate,
  customer,
  group,
  loadLogs,
}) {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [columns, setColumns] = useState([]);
  const [possibleColumns, setPossibleColumns] = useState([]);

  useEffect(() => {
    if (dev.id === "-" && src.id === "-") setPossibleColumns([]);
    if (dev.id !== "-" && src.id !== "-") loadColumns();
  }, [loadColumns, dev, src]);

  const handleChange = (event) => {
    const cols = { ...columns, [event.target.name]: event.target.checked };
    for (const property in cols) {
      if (cols[property] === false) {
        delete cols[property];
      }
    }
    if (Object.keys(cols).length === 0) setColumns([]);
    else setColumns(cols);
  };

  const loadColumns = useCallback(
    (id) => {
      startLoading();
      const params = {
        from: startDate.replace("T", "_"),
        to: endDate.replace("T", "_"),
        device: dev.id,
        source: id || src.id,
        customer: customer.id,
        group: group.id,
      };

      for (const property in params) {
        if (params[property] === "-") {
          delete params[property];
        }
      }

      bmapi
        .getLogsColumnsForGraph(params)
        .then((resp) => {
          const propMap = new Map();

          const blacklistedCol = new Map();
          blacklistedCol.set("ClientID", true);
          blacklistedCol.set("ClientName", true);
          blacklistedCol.set("DeviceId", true);
          blacklistedCol.set("DeviceSN", true);
          blacklistedCol.set("GroupID", true);
          blacklistedCol.set("PacketID", true);
          blacklistedCol.set("PublicKey", true);
          blacklistedCol.set("Signature", true);
          blacklistedCol.set("SourceId", true);
          blacklistedCol.set("TenantID", true);
          blacklistedCol.set("PacketTimestamp", true);
          blacklistedCol.set("CreatedAt", true);
          blacklistedCol.set("Timestamp", true);
          blacklistedCol.set("GroupName", true);
          blacklistedCol.set("DeviceDescription", true);
          blacklistedCol.set("SourceDescription", true);
          blacklistedCol.set("TableName", true);
          blacklistedCol.set("PacketVersion", true);
          blacklistedCol.set("Version", true);
          blacklistedCol.set("_id_", true);
          blacklistedCol.set("BCFirstSyncAt", true);
          blacklistedCol.set("BCMinedAt", true);
          blacklistedCol.set("BCStatus", true);
          blacklistedCol.set("BCSyncAt", true);
          blacklistedCol.set("ID", true);

          const col = [];

          resp.map((r) => {
            for (let i = 0; i < resp.length; i++) {
              if (propMap.has(r)) {
                continue;
              }

              if (blacklistedCol.has(r)) {
                continue;
              }

              col.push({
                name: r,
                selector: (row) =>
                  r === "Timestamp"
                    ? moment.unix(row[r]).format("DD/MM/YYYY HH:mm:SS")
                    : row[r],
                grow: r === "Timestamp" ? 1.5 : 1,
              });

              propMap.set(r);
            }
          });
          setPossibleColumns(col.sort(byName));
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [
      bmapi,
      customer,
      dev,
      src,
      endDate,
      startDate,
      group,
      intl,
      notifyError,
      startLoading,
      stopLoading,
    ]
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>{intl.formatMessage(jsc.graphColumns)}</DialogTitle>
      <DialogContent style={{ padding: "0 15px" }}>
        <TextField
          margin="dense"
          label={intl.formatMessage(jsc.filterDevice)}
          value={dev.id}
          onChange={(e) => {
            setDevice({ id: e.target.value });
            setColumns([]);
          }}
          fullWidth
          select
          style={{ paddingTop: "3px" }}
        >
          {devices.map((dev) => (
            <MenuItem value={dev.device_id} key={dev.device_description}>
              {dev.device_description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={dev.id === "-"}
          margin="dense"
          label={intl.formatMessage(jsc.filterSource)}
          value={src.id}
          onChange={(e) => {
            setSource({ id: e.target.value });
            loadColumns(e.target.value);
            setColumns([]);
          }}
          fullWidth
          select
          style={{ paddingTop: "3px" }}
        >
          {filterData(dev, sources).map((src) => (
            <MenuItem value={src.source_id} key={src.source_description}>
              {src.source_id + " " + "(" + src.source_description + ")"}
            </MenuItem>
          ))}
        </TextField>
        {Object.keys(columns).length > 5 && (
          <Typography style={{ color: "red" }}>
            {intl.formatMessage(jsc.maxColumns)}
          </Typography>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {possibleColumns.length ? (
            possibleColumns.map((c) => (
              <FormControlLabel
                key={c.name}
                control={
                  <Checkbox
                    checked={columns.name}
                    onChange={handleChange}
                    name={c.name}
                  />
                }
                label={c.name}
              />
            ))
          ) : (
            <Typography gutterBottom>
              {intl.formatMessage(jsc.noResults)}
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setColumns([]);
          }}
        >
          {intl.formatMessage(common.back)}
        </Button>
        <Button
          disabled={
            dev.id === "-" ||
            src.id === "-" ||
            columns.length === 0 ||
            Object.keys(columns).length > 5
          }
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            loadLogs("getTotal");
            sendGraphData(columns);
            setOpenGraph(true);
            setOpen(false);
            setColumns([]);
          }}
        >
          {intl.formatMessage(common.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SensorGraphColumnSelection;
