import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../../utils/bmapi-context";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Button, Divider, MenuItem, TextField } from "@material-ui/core";
import { common, jsc } from "../../messages";
import { DatePicker } from "../DatePicker";
import FormSection from "./input/FormSection";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@material-ui/core/styles";
import { backDays } from "../../utils/utils";
import { modules } from "../../utils/quill";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "45%",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "30px",
    display: "block",
  },
  upload: {
    width: "100%",
    marginTop: "8px",
  },
}));

export default function ReportCreateForm({ handleSubmit, handleBack }) {
  const intl = useIntl();
  const classes = useStyles();
  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [departments, setDepartments] = React.useState(null);
  const [factories, setFactories] = React.useState(null);
  const [department, setDepartment] = React.useState(getDefault("department"));
  const [factory, setFactory] = React.useState({ id: "-" });
  const [info, setInfo] = React.useState(null);
  const [date, setDate] = React.useState(new Date());
  const [timestamp, setTimestamp] = React.useState(
    new Date().toLocaleTimeString().substring(0, 5)
  );
  const [linkAttachments, setLinkAttachments] = React.useState([]);

  /*function updateFilters(factory, department) {
    if (factory != undefined && factory.id != undefined) {
      setFactory(factories.find((f) => f.id === factory.id));
    } else {
      setFactory({ id: "-" });
    }
    if (department != undefined && department.id != undefined) {
      setDepartment(departments.find((t) => t.id === department.id));
    } else {
      setDepartment({ id: "-" });
    }
  }*/

  function updateFilters(factory, department) {
    if (factory != undefined && factory.id != undefined) {
      if (
        department != undefined &&
        department.id != undefined &&
        department.id != "-"
      ) {
        setFactory(
          factories.find(
            (f) =>
              f.id == departments.find((t) => t.id === department.id).factory_id
          )
        );
      } else {
        setFactory(factories.find((f) => f.id === factory.id));
      }
    } else {
      setFactory({ id: "-" });
    }
    if (department != undefined && department.id != undefined) {
      setDepartment(departments.find((t) => t.id === department.id));
    } else {
      setDepartment({ id: "-" });
    }
  }

  useEffect(() => {
    startLoading();
    Promise.all([
      bmapi.getUserInfo(),
      bmapi.getDepartments(),
      bmapi.getWorkers(),
      bmapi.getFactories(),
    ])
      .then(([info, deps, woks, facs]) => {
        facs.unshift({ id: "-", name: intl.formatMessage(jsc.anyFactory) });
        deps.unshift({
          id: "-",
          name: intl.formatMessage(jsc.selectDepartment),
        });
        setInfo(info);
        setDepartments(deps);
        setFactories(facs);
        if (/*info.worker_id && */ info.role != "TM") {
          setFactory(
            facs.find(
              (y) =>
                y.id ==
                woks.find((x) => x.worker_id == info.worker_id).factory_id
            )
          );
          setDepartment(
            deps.find(
              (y) =>
                y.id ==
                woks.find((x) => x.worker_id == info.worker_id).department_id
            )
          );
        }
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    setErrorRichEditor("");
    e.preventDefault();
    var data = {
      department_id: department.id,
      company_id: department.company_id,
      factory_id: department.factory_id,
      report_desc: valueRichEditor,
      date: date,
      timestamp: timestamp,
      report_attachments: linkAttachments,
    };
    handleSubmit(data);
  };

  const handleInputFile = (event) => {
    let files = event.target.files;
    let validList = [...files].filter(
      (s) => s.type.includes("image") || s.type.includes("pdf")
    );
    if (validList.length == files.length) {
      linkAttachments.push(...files);
      setLinkAttachments(linkAttachments.slice(0, linkAttachments.length));
    } else {
      notifyError(intl.formatMessage(jsc.noFilePermission));
    }
  };

  function filterData(data, factory) {
    if (factory && factory != "-" && factory.id != "-") {
      data = data.filter((d) => d.factory_id == factory.id || d.id == "-");
    }
    return data == null ? [] : data;
  }

  function getDefault(item) {
    if (
      localStorage &&
      localStorage.getItem("jsc_report_filters") != undefined
    ) {
      return JSON.parse(localStorage.getItem("jsc_report_filters"))[item];
    }
    if (item == "startDate") return backDays(7);
    if (item == "endDate") return backDays(0);
    return { id: "-" };
  }

  function removeFile(index) {
    if (index > -1) {
      // only splice array when item is found
      linkAttachments.splice(index, 1); // 2nd parameter means remove one item only
    }
    setLinkAttachments(linkAttachments.slice(0, linkAttachments.length));
  }

  return (
    <div className="msg">
      <FormSection>
        <Box style={{ minHeight: "300px", marginTop: "20px" }}>
          <TextField
            select
            label={intl.formatMessage(jsc.selectFactory)}
            fullWidth
            value={factory ? factory.id : factory}
            onChange={(e) => {
              updateFilters({ id: e.target.value });
            }}
            disabled={info?.role != "TM"}
          >
            {factories?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <Divider style={{ marginTop: "20px" }} />
          <TextField
            select
            label={intl.formatMessage(jsc.selectDepartment)}
            fullWidth
            value={department.id ? department.id : department}
            onChange={(e) => {
              updateFilters(factory, { id: e.target.value });
            }}
            disabled={info?.role != "TM"}
          >
            {filterData(departments, factory).map((dep) => (
              <MenuItem value={dep.id} key={dep.id}>
                {dep.name}
              </MenuItem>
            ))}
          </TextField>
          <Divider style={{ marginTop: "20px" }} />
          <DatePicker
            minDate={backDays(7)}
            maxDate={backDays(0)}
            margin="normal"
            fullWidth
            label={intl.formatMessage(jsc.reportDate)}
            format="yyyy-MM-dd"
            value={date}
            onChange={(e) => setDate(e)}
          />
          <TextField
            id="time"
            label={intl.formatMessage(jsc.reportTime)}
            type="time"
            defaultValue={new Date().toLocaleTimeString().substring(0, 5)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={(e) => setTimestamp(e.target.value)}
          />
          <Divider style={{ marginTop: "20px" }}></Divider>
          <p className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
            {intl.formatMessage(jsc.eventDescription)}
          </p>
          <ReactQuill
            className={errorRichEditor != "" ? "red1d" : ""}
            theme="snow"
            modules={modules}
            value={valueRichEditor}
            placeholder={intl.formatMessage(jsc.messageText)}
            onChange={setValueRichEditor}
            error={errorRichEditor == ""}
          />
          <Divider style={{ marginTop: "80px", marginBottom: "20px" }} />
          <div style={{ display: "flex" }}>
            <p
              style={{ width: "70%" }}
              className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
            >
              {intl.formatMessage(jsc.reportAttachments)}
            </p>
            <input
              accept="image/*,application/pdf"
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleInputFile}
            />
            <label htmlFor="contained-button-file">
              <Button
                color="primary"
                variant="contained"
                component="span"
                className={classes.upload}
              >
                {intl.formatMessage(common.upload)}
              </Button>
            </label>
          </div>
          {linkAttachments.length > 0
            ? linkAttachments?.map((option, index) => (
                <div
                  style={{ display: "flex", marginTop: "10px" }}
                  key={option.name}
                >
                  <p style={{ width: "90%" }}> {option.name}</p>
                  <MenuItem key={option.id} value={option.id}>
                    <DeleteIcon
                      style={{ padding: "0px" }}
                      onClick={() => removeFile(index)}
                    />
                  </MenuItem>
                </div>
              ))
            : intl.formatMessage(jsc.noFile)}
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.button}
              onClick={handleBack}
            >
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={
                department.id != "-"
                  ? { color: "white", background: "rgb(29, 64, 175)" }
                  : {}
              }
              type="submit"
              className={classes.button}
              onClick={onSubmit}
              disabled={department.id == "-"}
            >
              {intl.formatMessage(common.save)} {"report"}
            </Button>
          </div>
        </Box>
      </FormSection>
    </div>
  );
}
