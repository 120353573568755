import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { common, jsc } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Title from "../../ui/Title";
import { addHours, format } from "date-fns";
import { getErrorMessageString } from "../../utils/errors";
import moment from "moment";
import SensorGraph from "./SensorGraph";
import { CheckCircleOutline, RadioButtonUnchecked } from "@material-ui/icons";
import SensorGraphColumnSelection from "../../components/SensorGraphColumnSelection";
import { parseBmarkenDate } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    upload: {
      width: "100%",
      marginTop: "8px",
    },
  },
}));

function filterData(dev, srcs) {
  if (dev.id !== "-") {
    return [
      ...srcs.filter((src) => src.device_id === dev.id),
      { source_id: "-", source_description: "Qualsiasi sorgente" },
    ];
  } else return srcs;
}

function DataPopUp({ open, setOpen, data, setData }) {
  const intl = useIntl();

  function createData(name, val) {
    return { name, val };
  }

  const rows = [
    createData(intl.formatMessage(jsc.transactionHash), data.tx_hash),
    createData(intl.formatMessage(jsc.etherscanUrl), data.etherscan_url),
    createData(intl.formatMessage(jsc.blockNumber), data.block_number),
    createData(
      intl.formatMessage(jsc.blockTimestamp),
      data.block_timestamp
        ? moment.unix(data.block_timestamp).format("DD/MM/YYYY HH:mm")
        : data.block_timestamp
    ),
    createData(intl.formatMessage(jsc.rootHash), data.root_hash),
    createData(
      intl.formatMessage(jsc.syncAt),
      data.bc_sync_at
        ? format(parseBmarkenDate(data.bc_sync_at), "dd/MM/yyyy HH:mm")
        : data.bc_sync_at
    ),
  ];

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setData(false);
      }}
      fullWidth
      maxWidth={data === intl.formatMessage(jsc.syncAwait) ? "sm" : "md"}
    >
      <DialogContent style={{ padding: "0 15px" }}>
        <List>
          {data === intl.formatMessage(jsc.syncAwait) && (
            <ListItem>
              <Typography variant="h6" gutterBottom>
                {data}
              </Typography>
            </ListItem>
          )}
          {data && data !== intl.formatMessage(jsc.syncAwait) && (
            <TableContainer>
              <Table>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      {row.name === intl.formatMessage(jsc.etherscanUrl) ? (
                        <TableCell>
                          <a href={row.val}>{row.val}</a>
                        </TableCell>
                      ) : (
                        <TableCell>{row.val}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setData(false);
          }}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SensorDetection() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [openGraph, setOpenGraph] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [BCData, setBCData] = React.useState(false);
  const [searchDone, setSearchDone] = React.useState(true);
  const [graphData, setGraphData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [qty, setQty] = React.useState(10);
  const [devices, setDevices] = React.useState([]);
  const [device, setDevice] = React.useState({ id: "-" });
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState({ id: "-" });
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState({ id: "-" });
  const [groups, setGroups] = React.useState([]);
  const [group, setGroup] = React.useState({ id: "-" });
  const [startDate, setStartDate] = React.useState(getDefault("startDate"));
  const [endDate, setEndDate] = React.useState(getDefault("endDate"));
  const [logs, setLogs] = React.useState([]);
  const [logsCount, setLogsCount] = React.useState([]);

  useEffect(() => {
    startLoading();
    //loadLogs("getTotal");
    Promise.all([
      bmapi.getDevices(),
      bmapi.getGroups(),
      bmapi.getCustomers(),
      bmapi.getSources(),
    ])
      .then(([dev, grp, cus, src]) => {
        dev.unshift({
          device_id: "-",
          device_description: "Qualsiasi dispositivo",
        });
        grp.unshift({ group_id: "-", group_name: "Qualsiasi gruppo" });
        cus.unshift({ customer_id: "-", customer_name: "Qualsiasi cliente" });
        src.unshift({
          source_id: "-",
          source_description: "Qualsiasi sorgente",
        });
        setDevices(dev);
        setGroups(grp);
        setCustomers(cus);
        setSources(src);
        return [startDate, endDate];
      })
      .catch((e) => notifyError(e))
      .finally(stopLoading);
    // eslint-disable-next-line
  }, []);

  const formatter = (row, property) => {
    if (property === "Timestamp") {
      return moment.unix(row[property]).format("DD/MM/YYYY HH:mm:ss");
    } else if (property === "BCSyncAt") {
      if (row.BCSyncAt == undefined) {
        return (
          <HtmlTooltip
            title={
              <React.Fragment>
                {intl.formatMessage(jsc.syncAwait)}
              </React.Fragment>
            }
          >
            <RadioButtonUnchecked />
          </HtmlTooltip>
        );
      } else
        return (
          <HtmlTooltip
            title={
              <React.Fragment>{intl.formatMessage(jsc.sync)}</React.Fragment>
            }
          >
            <CheckCircleOutline />
          </HtmlTooltip>
        );
    } else {
      return row[property];
    }
  };

  const propMap = new Map();

  const blacklistedCol = new Map();
  blacklistedCol.set("ClientID", true);
  blacklistedCol.set("ClientName", true);
  blacklistedCol.set("DeviceId", true);
  blacklistedCol.set("DeviceSN", true);
  blacklistedCol.set("GroupID", true);
  blacklistedCol.set("PacketID", true);
  blacklistedCol.set("PublicKey", true);
  blacklistedCol.set("Signature", true);
  blacklistedCol.set("SourceId", true);
  blacklistedCol.set("TenantID", true);
  blacklistedCol.set("PacketTimestamp", true);
  blacklistedCol.set("CreatedAt", true);
  blacklistedCol.set("BCFirstSyncAt", true);
  blacklistedCol.set("BCMinedAt", true);
  blacklistedCol.set("BCStatus", true);
  blacklistedCol.set("ID", true);

  const orderedCol = new Map();
  orderedCol.set(intl.formatMessage(jsc.BCStatus), 0);
  orderedCol.set("Timestamp", 1);
  orderedCol.set("GroupName", 2);
  orderedCol.set("DeviceDescription", 3);
  orderedCol.set("SourceDescription", 4);
  orderedCol.set("TableName", 5);

  const col = [];

  col.push({
    name: intl.formatMessage(jsc.BCStatus),
    selector: (row) => formatter(row, "BCSyncAt"),
    grow: 1,
  });

  propMap.set("BCSyncAt");

  logs.map((object) => {
    for (const property in object) {
      if (propMap.has(property)) {
        continue;
      }

      if (blacklistedCol.has(property)) {
        continue;
      }

      col.push({
        name:
          property === "BCSyncAt" ? intl.formatMessage(jsc.BCStatus) : property,
        selector: (row) => formatter(row, property),
        grow: property === "Timestamp" ? 1.5 : 1,
      });

      propMap.set(property);
    }
  });

  col.sort(function (a, b) {
    const aPos = orderedCol.get(a.name);
    const bPos = orderedCol.get(b.name);

    if (aPos == undefined && bPos == undefined) {
      return b - a;
    }

    if (aPos == undefined && bPos != undefined) {
      return 1;
    }

    if (aPos != undefined && bPos == undefined) {
      return -1;
    }

    return aPos - bPos;
  });

  function getDefault(item) {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const hour =
      new Date().getHours() + Math.round(new Date().getMinutes() / 60);
    const date = new Date(year, month, day, hour, 0);
    if (item === "startDate")
      return (
        format(date, "yyy-MM-dd") + "T" + format(addHours(date, -2), "HH:mm")
      );
    else if (item === "endDate")
      return format(date, "yyy-MM-dd") + "T" + format(date, "HH:mm");
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  function resetFilter(e) {
    e.preventDefault();
    setPage(0);
    setQty(10);
    setCustomer({ id: "-" });
    setGroup({ id: "-" });
    setDevice({ id: "-" });
    setSource({ id: "-" });
    setStartDate(getDefault("startDate"));
    setEndDate(getDefault("endDate"));
    setSearchDone(true);
    setLogs([]);
    setLogsCount([]);
  }

  const loadLogs = (getTotal, p, q) => {
    startLoading();
    const params = {
      page: p || "0",
      qty: q || qty,
      from: startDate.replace("T", "_"),
      to: endDate.replace("T", "_"),
      device: device.id,
      source: source.id,
      customer: customer.id,
      group: group.id,
    };

    for (const property in params) {
      if (params[property] === "-") {
        delete params[property];
      }
    }

    bmapi
      .getLogs(params)
      .then((resp) => {
        if (!resp) {
          resp = [];
          setLogs(resp);
        } else {
          if (getTotal) {
            delete params.page;
            delete params.qty;
            bmapi
              .getLogsCount(params)
              .then((count) => {
                setLogs(resp);
                setLogsCount(count);
              })
              .catch((e) => {
                notifyError(getErrorMessageString(e, intl));
              });
          } else setLogs(resp);
        }
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadLogs(false, newPage, false);
  };

  const handleChangeRowsPerPage = (event) => {
    setQty(parseInt(event.target.value, 10));
    setPage(0);
    loadLogs(false, "0", parseInt(event.target.value, 10));
  };

  const sendGraphData = (columns) => {
    setGraphData(Object.keys(columns));
  };

  const getRowData = (row) => {
    if (row.BCSyncAt == undefined) {
      setBCData(intl.formatMessage(jsc.syncAwait));
      setOpenPopUp(true);
    } else {
      startLoading();
      bmapi
        .getBCData({ log: row.ID })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setBCData(resp);
          } else setBCData(resp);
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
      setOpenPopUp(true);
    }
  };

  return (
    <Container style={{ maxWidth: "1100px" }} className="tablistNone">
      <Title>{intl.formatMessage(jsc.sensorDetection)}</Title>
      {/* main */}
      <div>
        <div className="border border-gray-400 bg-white rounded p-4 flex flex-col justify-between leading-normal">
          {/* toolbox */}
          <div
            className="mb-1"
            style={{
              background: "white",
              borderRadius: "30px",
              padding: "15px",
              marginBottom: "15px",
            }}
          >
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterCustomer)}
                value={customer.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setCustomer({ id: e.target.value });
                }}
                fullWidth
                select
                style={{ paddingTop: "3px", display: "none" }}
              >
                {customers.map((cm) => (
                  <MenuItem value={cm.customer_id} key={cm.customer_name}>
                    {cm.customer_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterGroup)}
                value={group.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setGroup({ id: e.target.value });
                }}
                fullWidth
                select
                style={{ paddingTop: "3px" }}
              >
                {groups.map((gr) => (
                  <MenuItem value={gr.group_id} key={gr.group_name}>
                    {gr.group_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterDevice)}
                value={device.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setDevice({ id: e.target.value });
                }}
                fullWidth
                select
                style={{ paddingTop: "3px" }}
              >
                {devices.map((dev) => (
                  <MenuItem value={dev.device_id} key={dev.device_description}>
                    {dev.device_description}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label={intl.formatMessage(jsc.filterSource)}
                value={source.id}
                onChange={(e) => {
                  setSearchDone(true);
                  setSource({ id: e.target.value });
                }}
                fullWidth
                select
                style={{ paddingTop: "3px" }}
              >
                {filterData(device, sources).map((src) => (
                  <MenuItem value={src.source_id} key={src.source_description}>
                    {src.source_id + " " + "(" + src.source_description + ")"}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ width: "100%" }}>
                <div style={{ display: "inline-block" }}>
                  <div>{intl.formatMessage(jsc.filterFrom)}</div>
                  <div>
                    <TextField
                      id="dateStart"
                      type="datetime-local"
                      required // not clearable
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textField}
                      onChange={(e) => {
                        setSearchDone(true);
                        setStartDate(
                          e.currentTarget.value == ""
                            ? setSearchDone(false)
                            : e.currentTarget.value
                        );
                      }}
                      value={startDate}
                      error={startDate > endDate}
                      helperText={
                        startDate > endDate && intl.formatMessage(jsc.dateError)
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div>{intl.formatMessage(jsc.filterTo)}</div>
                  <div>
                    <TextField
                      id="dateEnd"
                      type="datetime-local"
                      required // not clearable
                      InputLabelProps={{
                        shrink: false,
                      }}
                      className={classes.textField}
                      onChange={(e) => {
                        setSearchDone(true);
                        setEndDate(
                          e.currentTarget.value == ""
                            ? setSearchDone(false)
                            : e.currentTarget.value
                        );
                      }}
                      value={endDate}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-start",
                    margin: "-35px 0px 15px",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        {intl.formatMessage(jsc.searchAgain)}
                      </React.Fragment>
                    }
                  >
                    <span>
                      <Button
                        color="primary"
                        onClick={() => {
                          setSearchDone(false);
                          loadLogs("getTotal");
                        }}
                        variant="contained"
                        disabled={!searchDone}
                        style={{ marginRight: "10px" }}
                      >
                        {intl.formatMessage(jsc.search)}
                      </Button>
                    </span>
                  </HtmlTooltip>
                  <Button
                    onClick={(e) => resetFilter(e)}
                    style={{
                      background: "#1d40af",
                      color: "white",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    disabled={!true}
                  >
                    {intl.formatMessage(jsc.filterClear)}
                  </Button>
                </div>
              </div>
            </form>
          </div>
          {/* graph */}
          {openGraph && (
            <SensorGraph
              datas={graphData}
              startDate={startDate}
              endDate={endDate}
              device={device.id}
              source={source.id}
              customer={customer.id}
              group={group.id}
              open={openGraph}
              setOpen={setOpenGraph}
            />
          )}
          {/* tabella */}
          <div className="tb_container">
            {/* Pagination */}
            {logs.length > 0 && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <span>
                  <Button
                    onClick={() => setOpen(true)}
                    style={{
                      background: "#1d40af",
                      color: "white",
                    }}
                    variant="contained"
                  >
                    {intl.formatMessage(jsc.viewGraph)}
                  </Button>
                </span>
                <TablePagination
                  component="div"
                  count={logsCount}
                  page={page}
                  onChangePage={(e, p) => handleChangePage(e, p)}
                  rowsPerPage={qty}
                  onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                />
              </Box>
            )}
            <DataTable
              columns={col}
              data={logs}
              paginationServer
              noDataComponent={intl.formatMessage(jsc.noResults)}
              onRowClicked={(row) => {
                getRowData(row);
              }}
              style={{
                border: "1px solid blue",
                borderRadius: "10px",
              }}
            />
            {/* Pagination */}
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <span>
                <Button
                  onClick={() => setOpen(true)}
                  style={{
                    background: "#1d40af",
                    color: "white",
                  }}
                  variant="contained"
                >
                  {intl.formatMessage(jsc.viewGraph)}
                </Button>
              </span>
              <TablePagination
                component="div"
                count={logsCount}
                page={page}
                onChangePage={(e, p) => handleChangePage(e, p)}
                rowsPerPage={qty}
                onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
              />
            </Box>
          </div>
          {/* dialog */}
          <SensorGraphColumnSelection
            open={open}
            setOpen={setOpen}
            setOpenGraph={setOpenGraph}
            sendGraphData={sendGraphData}
            dev={device}
            src={source}
            setDevice={setDevice}
            devices={devices}
            setSource={setSource}
            sources={sources}
            startDate={startDate}
            endDate={endDate}
            customer={customer}
            group={group}
            loadLogs={loadLogs}
          />
          <DataPopUp
            setOpen={setOpenPopUp}
            open={openPopUp}
            data={BCData}
            setData={setBCData}
          />
        </div>
      </div>
    </Container>
  );
}

export default SensorDetection;
