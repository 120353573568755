import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";

import { navigation } from "../../messages";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { Autocomplete } from "@material-ui/lab";
import Confirm from "../../ui/Confirm";

const byName = (a, b) => a.name.localeCompare(b.name);
const byBatch = (a, b) => a.batch_id.localeCompare(b.batch_id);

export default function Lot() {
  const { businessId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [inventories, setInventories] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [stats, setStats] = useState({});
  const [batchToAssign, setBatchToAssign] = useState(false);
  const [autocompleteKey, setAutocompleteKey] = useState(Math.random());
  const [assignRequest, setAssignRequest] = useState(false);

  const goToStore = (id) => {
    history.push(MANAGER_ROUTES.LOTS.replace(":businessId?", id));
  };

  const handleAssign = () => {
    startLoading();
    setAssignRequest(false);
    bmapi
      .assignMallInventory(businessId, batchToAssign.id)
      .then(() => bmapi.getMallInventories())
      .then((inv) => {
        setBatchToAssign(false);
        setAutocompleteKey(Math.random());
        setInventories(inv.sort(byBatch));
      })
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "pages.lots.associateConfirmMessage",
            defaultMessage: "Lotto associato con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const loadStats = (business_id) => {
    startLoading();
    bmapi
      .getMallCouponStats(business_id)
      .then((stats) => setStats((s) => ({ ...s, [businessId]: stats })))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const lots = useMemo(
    () =>
      (inventories || []).reduce(
        (a, b) => ({
          ...a,
          [b.business_id]: (a[b.business_id] || 0) + 1,
        }),
        {}
      ),
    [inventories]
  );

  useEffect(() => {
    startLoading();
    Promise.all([bmapi.getBusinesses(), bmapi.getMallInventories()])
      .then(([bs, inv]) => {
        setBusinesses(bs.sort(byName));
        setInventories(inv.sort(byBatch));
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  return (
    !!businesses &&
    (businessId ? (
      <Container maxWidth="sm">
        <Title backUrl={MANAGER_ROUTES.LOTS.replace(":businessId?/", "")}>
          {(businesses || []).find((b) => b.id === businessId).name}
        </Title>
        <Confirm
          open={!!assignRequest}
          onConfirm={handleAssign}
          onCancel={() => setAssignRequest(false)}
          text={intl.formatMessage(
            {
              id: "pages.lots.associateConfirm",
              defaultMessage: "Conferma l'associazione del lotto",
            },
            assignRequest
          )}
        />
        <Box mb={2}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {intl.formatMessage({
                  id: "pages.lots.associateToBusiness",
                  defaultMessage: "Associa un lotto al punto vendita",
                })}
              </Typography>
              <Typography gutterBottom>
                {intl.formatMessage({
                  id: "pages.lots.associateInfo",
                  defaultMessage:
                    "Usa l'autocomplete per scrivere e selezionare il codice del lotto che vuoi attribuire a questo punto vendita. Premi Associa per confermare",
                })}
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Autocomplete
                    options={inventories || []}
                    getOptionLabel={(option) =>
                      `${option.batch_id}: ${option.business_name}`
                    }
                    onChange={(_, val) => setBatchToAssign(val)}
                    key={autocompleteKey}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({
                          id: "pages.lots.lots",
                          defaultMessage: "Lotti",
                        })}
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setAssignRequest(batchToAssign)}
                  >
                    {intl.formatMessage({
                      id: "pages.lots.associate",
                      defaultMessage: "Associa",
                    })}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box mb={2}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {
                  (inventories || []).filter(
                    (lot) => lot.business_id === businessId
                  ).length
                }{" "}
                {intl.formatMessage({
                  id: "pages.lots.associatedLots",
                  defaultMessage: "Lotti associati",
                })}
              </Typography>
              <Typography>
                {(inventories || [])
                  .filter((lot) => lot.business_id === businessId)
                  .map((lot) => lot.batch_id)
                  .sort((a, b) => a.localeCompare(b))
                  .join(", ")}
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box mb={2}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {intl.formatMessage({
                  id: "pages.lots.stats",
                  defaultMessage: "Statistiche",
                })}
              </Typography>
              {stats[businessId] ? (
                <>
                  <Typography>
                    {intl.formatMessage({
                      id: "pages.lots.plays",
                      defaultMessage: "Giocate Effettuate",
                    })}
                    : <strong>{stats[businessId].burned_coupons}</strong>
                  </Typography>
                  <Typography>
                    {intl.formatMessage({
                      id: "pages.lots.wins",
                      defaultMessage: "Giocate Vincenti",
                    })}
                    : <strong>{stats[businessId].winner_coupons}</strong>
                  </Typography>
                  <Typography>
                    {intl.formatMessage({
                      id: "pages.lots.loses",
                      defaultMessage: "Giocate Perdenti",
                    })}
                    :{" "}
                    <strong>
                      {stats[businessId].burned_coupons -
                        stats[businessId].winner_coupons}
                    </strong>
                  </Typography>
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => loadStats(businessId)}
                >
                  {intl.formatMessage({
                    id: "pages.lots.loadStats",
                    defaultMessage: "Carica",
                  })}
                </Button>
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>
    ) : (
      <Container maxWidth="sm">
        <Title>{intl.formatMessage(navigation.lots)}</Title>
        <Card>
          {(businesses || []).map((bs) => (
            <ListItem button key={bs.id} onClick={() => goToStore(bs.id)}>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Box>{bs.name}</Box>
                    <Box>{lots?.[bs.id] || 0} Lotti</Box>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </Card>
      </Container>
    ))
  );
}
