import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Accordion,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import { Adjust, EmojiEvents, ExpandMore } from "@material-ui/icons";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";

import { campaign as campaignMsg, common } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";

export default function CardPrizes({ card, onUpdate, prizes }) {
  const intl = useIntl();
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();

  const prizesTier = new Set(
    prizes?.map((p) => p.threshold).sort((a, b) => a - b)
  );

  const prizesForTier = (tier) => {
    return prizes.filter((p) => p.threshold === tier);
  };

  const redeem = useCallback(
    (prizeId) => {
      startLoading();

      bmapi
        .redeemPrize(card.id, prizeId)
        .then(() => {
          notifySuccess(intl.formatMessage(campaignMsg.prizeReceivedMessage));
          onUpdate();
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [
      bmapi,
      card?.id,
      intl,
      notifyError,
      notifySuccess,
      onUpdate,
      startLoading,
      stopLoading,
    ]
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />} id="prizes">
        <Typography>{intl.formatMessage(campaignMsg.getPrizes)}</Typography>
      </AccordionSummary>

      <Timeline style={{ padding: 0 }}>
        {[...prizesTier].map((tier, i) => (
          <TimelineItem key={tier}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage(common.totalPoints, {
                  points: tier / 100,
                })}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              {card?.available_value > tier ? (
                <TimelineDot color="primary">
                  <EmojiEvents />
                </TimelineDot>
              ) : (
                <TimelineDot color="grey">
                  <Adjust />
                </TimelineDot>
              )}
              {i < prizesTier.size - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent style={{ flexGrow: 4 }}>
              {prizesForTier(tier).map((prize) => (
                <Typography gutterBottom key={prize.id}>
                  {prize.name}{" "}
                  {card?.available_value > tier && (
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => redeem(prize.id)}
                    >
                      {intl.formatMessage(campaignMsg.redeemPrizeAction)}
                    </Button>
                  )}
                </Typography>
              ))}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Accordion>
  );
}
