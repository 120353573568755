import Validator from "validator";
import trianglify from "trianglify";
import moment from "moment";
import { createBgGradient } from "./colors";
import {
  API_ERROR,
  BMAPI_ERROR,
  ERRORS,
  HTTP,
  URL_CALLBACK_PARAM,
  URL_TRACE_PARAM,
  URL_TRACE_VALUES,
} from "./constants";
import { langs } from "./dictionaries";

export function validateRoles(roleWanted, userRole) {
  return Array.isArray(roleWanted)
    ? roleWanted.includes(userRole)
    : roleWanted === userRole;
}

export function validateUUID(id) {
  return Validator.isUUID(id);
}

export function validateEmail(e) {
  return Validator.isEmail(e);
}

export function download(blob, filename = "download") {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.download = filename || "download";
  document.body.appendChild(link);

  const clickHandler = () => {
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.removeEventListener("click", clickHandler);
    }, 150);
  };

  link.addEventListener("click", clickHandler, false);
  link.click();
  return link;
}

export function checkTokenExpired(error) {
  return (
    error.httpCode === 401 &&
    error.code === ERRORS.TOKEN_NOT_VALID &&
    error.message.includes("ID token has expired at")
  );
}

export class BmapiError extends Error {
  constructor(message, settings, ...params) {
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BmapiError);
    }

    this.name = BMAPI_ERROR;
    this.settings = settings;
  }
}

class ApiError extends Error {
  constructor(httpCode, code, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = API_ERROR;
    this.code = code;
    this.httpCode = httpCode;
    this.message = message;
  }
}

export function handleError(response) {
  return response.json().then((data) => {
    throw new ApiError(data.HTTPCode, data.Code, data.Message);
  });
}

export function handleResponse(response) {
  if (!response.ok) return handleError(response);

  const contentType = response.headers.get("content-type") || "";
  if (contentType.includes("application/json")) {
    return response.json();
  } else if (contentType.includes("text/plain")) {
    return response.json();
  } else if (contentType.includes("text/csv")) {
    return response.blob();
  } else if (contentType.includes("image/png")) {
    return response.blob();
  } else if (contentType.includes("application/pdf")) {
    return response.blob();
  }
  return response.blob();
}

export function handleDownload(response) {
  return response.ok ? response.blob().then(download) : handleError(response);
}

export async function fetch(url, method = HTTP.GET, body, headers) {
  return window
    .fetch(url, {
      method,
      headers: new Headers(headers),
      ...(body ? { body } : {}),
      credentials: "include",
    })
    .then(handleResponse);
}

export function createBg(seed = null, color) {
  const pattern = trianglify({
    height: 400,
    width: 640,
    seed: seed,
    xColors: createBgGradient(color),
    yColors: "match",
    variance: 1,
  });
  return pattern.toCanvas().toDataURL();
}

export function onlyInt(e) {
  if (!/^[0-9]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function onlyNumbers(e) {
  if (!/^[0-9,.]$/.test(e.key) && e.key !== "Enter") {
    e.preventDefault();
  }
}

export function objToQuery(obj = {}) {
  return (
    (Object.entries(obj).length ? "?" : "") +
    new URLSearchParams(obj).toString()
  );
}

export function replaceParams(url, params = {}) {
  return url.replace(
    new RegExp(
      Object.keys(params)
        .map((k) => `{${k}}`)
        .join("|") || false,
      "gi"
    ),
    (matched) => params[matched.slice(1, -1)]
  );
}

export function composeUrl(url, params, query) {
  return replaceParams(url, params) + objToQuery(query);
}

function loadConf(tenant, env) {
  try {
    const conf = require(`../configurations/${tenant}${
      env ? `.${env}` : ""
    }.json`);
    console.info(`Configuration: ${tenant}.${env || "default"}`);
    return conf;
  } catch {
    return {};
  }
}

export function createSettings(tenant, env) {
  const envConf = loadConf(tenant, env);
  const tenantConf = loadConf(tenant);
  const defaultConf = {
    ...loadConf("_default"),
    ...loadConf("_default", env),
  };
  return {
    common: {
      ...defaultConf,
      ...tenantConf,
      ...envConf,
    },
    consumer: {
      ...defaultConf,
      ...defaultConf._consumer,
      ...tenantConf,
      ...tenantConf._consumer,
      ...envConf,
      ...envConf._consumer,
    },
    manager: {
      ...defaultConf,
      ...defaultConf._manager,
      ...tenantConf,
      ...tenantConf._manager,
      ...envConf,
      ...envConf._manager,
    },
  };
}

function loadTheme(theme) {
  try {
    const conf = require(`../configurations/themes/${theme}.json`);
    console.info(`Theme: ${theme}`);
    return conf;
  } catch {
    return {};
  }
}

export function createThemeConf(theme, app) {
  const customTheme = loadTheme(theme);
  const defaultTheme = loadTheme("_default");
  return {
    ...defaultTheme,
    ...customTheme,
    ...(customTheme[`_${app}`] ? customTheme[`_${app}`] : {}),
  };
}

export function getData(key, empty = {}) {
  return JSON.parse(localStorage.getItem(key)) || empty;
}

export function setData(key, data, empty) {
  const newData = {
    ...getData(key, empty),
    ...data,
  };
  localStorage.setItem(key, JSON.stringify(newData));
  return newData;
}

export function parseTokenId(token_id) {
  return JSON.parse(
    window.atob(token_id.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
  );
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default {
  checkTokenExpired,
  composeUrl,
  createBg,
  createBgGradient,
  createSettings,
  createThemeConf,
  download,
  fetch,
  getData,
  handleDownload,
  handleError,
  handleResponse,
  objToQuery,
  onlyNumbers,
  parseTokenId,
  setData,
  uuidv4,
  validateEmail,
  validateRoles,
  validateUUID,
};

export function mergeSubscrPlans(tiers, items) {
  const arr = [];
  if (!items || !tiers || !items.length || !tiers.length) {
    return arr;
  }
  var itemsMap = new Map(items.map((item) => [item.bme_plan_key, item]));
  tiers.map((tier) => {
    if (tier.action) {
      arr.push(tier);
    } else {
      const item = itemsMap.get(tier.id);
      if (item) {
        item.specs = tier.specs;
        item.best = tier.best;
        arr.push(item);
      }
    }
  });
  return arr;
}

export function getRouteBase(location) {
  const path = window.location.pathname.split("/");
  return (
    location.protocol + "//" + location.host + "/" + path[1] + "/" + path[2]
  );
}

export function getEmptyArray(n) {
  const arr = [];
  for (let i = 0; i < n; ++i) {
    arr.push("");
  }
  return arr;
}

export function getEmptyMap(n) {
  let map = new Map();
  for (let i = 0; i < n.length; i++) {
    map.set(n[i], "");
  }
  return map;
}

export function getLanguageLabel(code) {
  for (let i = 0; i < langs.length; ++i) {
    if (langs[i].value === code) {
      return langs[i];
    }
  }
  return {};
}

export function getFields(arrobjects, field) {
  const arr = [];
  for (let i = 0; i < arrobjects.length; ++i) {
    arr.push(arrobjects[i][field]);
  }
  return arr;
}

export function getMapFields(arrobjects, field, lang) {
  let map = new Map();
  for (let i = 0; i < arrobjects.length; ++i) {
    map.set(arrobjects[i][lang], arrobjects[i][field]);
  }
  return map;
}

export function compareTranslations(o1, o2) {
  return o1.lang < o2.lang ? -1 : 1;
}

export function compareByTitle(o1, o2) {
  return o1.title.toLowerCase() < o2.title.toLowerCase() ? -1 : 1;
}

export function parseBmarkenDate(param) {
  if (param === "0001-01-01T00:00:00Z") {
    return null;
  }
  var timestamp = Date.parse(param);

  if (isNaN(timestamp) == false) {
    return new Date(timestamp);
  }

  return null;
}

export function hourToString(ISOstr) {
  return ISOstr.slice(11, 16).replace(/:/g, "");
}

export function priceText(price) {
  return Number.parseFloat(price / 100).toFixed(2);
}

/*
export function decodeArray(
  arr,
  mapping,
  fieldFrom = "value",
  fieldTo = "label"
) {
  if (!arr || !mapping) {
    return [];
  }
  const newMap = new Map(
    mapping.map((mappedElement) => [
      mappedElement[fieldFrom],
      mappedElement[fieldTo],
    ])
  );
  return arr.map((item) => newMap.get(item));
}

export const isInObjArray = (arr, value, fieldName = "value") => {
  if (!arr) {
    return false;
  }
  const filtered = arr.filter((obj) => obj[fieldName] === value);
  return filtered && filtered.length;
};
*/
export const isInArray = (arr, value) => {
  if (!arr) {
    return false;
  }
  return arr.indexOf(value) !== -1;
};

export const decodeFromArray = (
  arr,
  value,
  fieldFrom = "value",
  fieldTo = "label"
) => {
  if (!arr || !Array.isArray(arr)) {
    return "";
  }
  const filtered = arr.filter((obj) => obj[fieldFrom] === value);
  return filtered && filtered.length ? filtered[0][fieldTo] : "";
};

export const checkNullArray = (arr) => {
  return !arr || arr === null ? [] : arr;
};

export const intlFormatMessage = (message, dflt, intl) =>
  message ? intl.formatMessage(message) : dflt;

export function getQueryVariable(query, variable) {
  if (query.startsWith("?")) {
    query = query.substring(1);
  }
  const vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export function propagateUrlTracing() {
  const trace = getQueryVariable(location.search, URL_TRACE_PARAM);
  const callback = getQueryVariable(location.search, URL_CALLBACK_PARAM);
  return addUrlParameters(trace, callback);
}

export function addUrlParameters(trace, callback) {
  let search = "";
  if (trace) {
    search += "&" + URL_TRACE_PARAM + "=" + trace;
  }
  if (callback) {
    search += "&" + URL_CALLBACK_PARAM + "=" + encodeURIComponent(callback);
  }
  if (search) {
    search = "?" + search.substring(1);
  }
  return search;
}
/*
export const propagateCallback = (url) => {
  const name = "callback";
  const callback = getQueryVariable(location.search, name);

  if (callback) {
    const arr = url.split("?");
    const sep = arr && arr.length > 1 ? "&" : "?";
    url = arr[0] + sep + name + "=" + callback;
  }

  return url;
};
*/
/*
export const checkExternalUrl = (url, subscriptionSlug, language) => {
  let newUrl = url?.replace(
    "$$ALTERNATIVESITE$$",
    DOMAIN_CONFIG.ALTERNATIVESITE
  );
  if (subscriptionSlug) {
    newUrl = newUrl?.replace("$$SUBSCRIPTION_SLUG$$", subscriptionSlug);
  }
  if (language) {
    newUrl = newUrl?.replace("$$LANGUAGE$$", language);
  }
  return newUrl;
};
*/
export const getCallbackUrl = () => {
  const trace = getQueryVariable(location.search, URL_TRACE_PARAM);
  const callback = getQueryVariable(location.search, URL_CALLBACK_PARAM);
  if (trace === URL_TRACE_VALUES.SUBSCRIPTION) {
    if (callback?.startsWith("/join")) {
      return callback;
    }
  }
  if (trace === URL_TRACE_VALUES.EXTERNAL) {
    return callback;
  }
  return null;
};

export const isSubscriptionCompleted = (subscription) => {
  if (!subscription) {
    return true;
  }
  return !!subscription.friendly_url;
};

export const isAlphanumeric = (text) => {
  return text.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i);
};

export function checkFiscalCodeIT(cf) {
  if (!/^[0-9A-Z]{16}$/.test(cf)) return false;
  var s = 0;
  var even_map = "BAFHJNPRTVCESULDGIMOQKWZYX";
  for (var i = 0; i < 15; i++) {
    var c = cf[i];
    var n = 0;
    if ("0" <= c && c <= "9") n = c.charCodeAt(0) - "0".charCodeAt(0);
    else n = c.charCodeAt(0) - "A".charCodeAt(0);
    if ((i & 1) === 0) n = even_map.charCodeAt(n) - "A".charCodeAt(0);
    s += n;
  }
  if ((s % 26) + "A".charCodeAt(0) !== cf.charCodeAt(15)) return false;
  return true;
}

export function checkVatNumberIT(pi) {
  pi = pi.replace(/\s/g, "");
  if (!/^[0-9]{11}$/.test(pi)) return false;
  var s = 0;
  for (var i = 0; i < 11; i++) {
    var n = pi.charCodeAt(i) - "0".charCodeAt(0);
    if ((i & 1) === 1) {
      n *= 2;
      if (n > 9) n -= 9;
    }
    s += n;
  }
  if (s % 10 !== 0) return false;
  return true;
}

export function getSubscriptionLogo(sub) {
  let logoUrl = "",
    logoId = "";
  const mediaContentsCount = sub?.media_contents?.length;
  if (mediaContentsCount) {
    const mediaContent = sub.media_contents[0];
    logoUrl = mediaContent?.url;
    logoId = mediaContent?.id;
  }
  return { logoId, logoUrl };
}

export function isSubscriptionIncomplete(sub) {
  if (!sub) {
    return false;
  }
  return !sub.friendly_url || !getSubscriptionLogo(sub).logoUrl;
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

export function oneYearExpiration() {
  var now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  return now;
}

export function checker(obj) {
  // Filter factory
  obj.data.events =
    obj.filters.factory && obj.filters.factory.id !== "-"
      ? obj.data.events.filter((w) => w.factory_id === obj.filters.factory.id)
      : obj.data.events;
  // Filter department
  obj.data.events =
    obj.filters.department && obj.filters.department.id !== "-"
      ? obj.data.events.filter(
          (w) => w.department_id === obj.filters.department.id
        )
      : obj.data.events;
  // Filter worker
  obj.data.events =
    obj.filters.worker && obj.filters.worker.id !== "-"
      ? obj.data.events.filter((w) => w.worker_id === obj.filters.worker.id)
      : obj.data.events;
  // Filter team
  obj.data.events =
    obj.filters.team && obj.filters.team.id !== "-"
      ? obj.data.events.filter(({ worker_id: id1 }) =>
          obj.data.workers
            .filter((w) => w.team_id === obj.filters.team.id)
            .some(({ id: id2 }) => id2 == id1)
        )
      : obj.data.events;
  // Filter eventType
  obj.data.events =
    obj.filters.eventType && obj.filters.eventType.id !== "-"
      ? obj.data.events.filter(
          (w) => w.event_type_id === obj.filters.eventType.id
        )
      : obj.data.events;
  // Filter startDate
  obj.data.events =
    obj.filters.startDate && obj.filters.startDate !== "-"
      ? obj.data.events.filter((w) => {
          var mDate = moment(w.date, "YYYYMMDD");
          var fmDate = mDate.format("MM/DD/YYYY");
          return (
            new Date(fmDate).getTime() >=
            new Date(obj.filters.startDate).getTime() - 3600000
          );
        })
      : obj.data.events;
  // Filter endDate
  obj.data.events =
    obj.filters.endDate && obj.filters.endDate !== "-"
      ? obj.data.events.filter((w) => {
          var mDate = moment(w.date, "YYYYMMDD");
          var fmDate = mDate.format("MM/DD/YYYY");
          return (
            new Date(fmDate).getTime() <=
            new Date(obj.filters.endDate).getTime() - 3600000
          );
        })
      : obj.data.events;
  return obj.data.events.sort(byDate);
}
export function reportChecker(obj) {
  // Filter factory
  obj.data.reports =
    obj.filters.factory && obj.filters.factory.id !== "-"
      ? obj.data.reports.filter((w) => w.factory_id === obj.filters.factory.id)
      : obj.data.reports;
  // Filter department
  obj.data.reports =
    obj.filters.department && obj.filters.department.id !== "-"
      ? obj.data.reports.filter(
          (w) => w.department_id === obj.filters.department.id
        )
      : obj.data.reports;
  // Filter startDate
  obj.data.reports =
    obj.filters.startDate && obj.filters.startDate !== "-"
      ? obj.data.reports.filter((w) => {
          var mDate = moment(w.report_date, "YYYYMMDD");
          var fmDate = mDate.format("MM/DD/YYYY");
          return (
            new Date(fmDate).getTime() >=
            new Date(obj.filters.startDate).getTime() - 3600000
          );
        })
      : obj.data.reports;
  // Filter endDate
  obj.data.reports =
    obj.filters.endDate && obj.filters.endDate !== "-"
      ? obj.data.reports.filter((w) => {
          var mDate = moment(w.report_date, "YYYYMMDD");
          var fmDate = mDate.format("MM/DD/YYYY");
          return (
            new Date(fmDate).getTime() <=
            new Date(obj.filters.endDate).getTime() - 3600000
          );
        })
      : obj.data.reports;
  return obj.data.reports.sort(byReportDate);
}
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function backDays(days, date) {
  var mDate;
  if (date) {
    mDate = moment(addDays(date, -days), "YYYYMMDD");
  } else {
    mDate = moment(addDays(new Date(), -days), "YYYYMMDD");
  }
  return mDate.format("YYYY-MM-DD");
}

export function byCreationDate(a, b) {
  return new Date(moment(b.created_at)) - new Date(moment(a.created_at));
}

export function byDate(a, b) {
  return new Date(moment(b.date)) - new Date(moment(a.date));
}

export function byReportDate(a, b) {
  return new Date(moment(b.report_date)) - new Date(moment(a.report_date));
}

export function checkBasicEventData(data, workerDetail) {
  return !(
    data.event_desc == workerDetail.event_desc &&
    data.date == workerDetail.date &&
    data.start_at == workerDetail.start_at &&
    data.end_at == workerDetail.end_at
  );
}

export function checkBasicReportData(data, workerDetail) {
  return !(
    data.report_desc == workerDetail.report_desc &&
    data.date == workerDetail.report_date &&
    data.timestamp == workerDetail.timestamp
  );
}
