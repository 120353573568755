import React from "react";

import { Grid as MuiGrid } from "@material-ui/core";

export default function Grid({ items = [], render, large = false }) {
  const sizes = large ? { xs: 12, sm: 12, md: 6 } : { xs: 12, sm: 6, md: 4 };
  return (
    !!items.length && (
      <MuiGrid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {items.map((item, i) => (
          <MuiGrid key={i} item {...sizes}>
            {render(item)}
          </MuiGrid>
        ))}
      </MuiGrid>
    )
  );
}
