import React, { useEffect, useState, useCallback } from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container /*, Typography*/,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";

import Title from "../../ui/Title";
import {
  CONSUMER_ROUTES,
  MANAGER_ROUTES,
  MANAGER_NAMESPACE,
} from "../../utils/constants";
import { paymentMessages } from "../../messages/paymentMessages";
import { Alert } from "@material-ui/lab";
import { common, navigation } from "../../messages";
import { getErrorMessageString } from "../../utils/errors";

// payment ok, subscription creation ok
const PAYMENT_STATUS_SUCCESS = "0";
// payment canceled, subscription creation canceled
const PAYMENT_STATUS_CANCELED = "1";
// payment error, subscription creation canceled
const PAYMENT_STATUS_ERROR = "2";
// payment ok, subscription creation error
const PAYMENT_STATUS_PROCESSED = "3";

function getPaymentErrorMessage(code) {
  switch (code) {
    case "102":
      return paymentMessages.wrongPan;
    case "103":
    case "400":
    case "404":
      return paymentMessages.authDenied;
    case "108":
      return paymentMessages.orderDuplication;
    case "116":
      return paymentMessages.unconfirmed3dsecure;
    case "117":
      return paymentMessages.cardNotAuthForTrx;
    case "121":
      return paymentMessages.timeout;
    case "129":
    case "401":
      return paymentMessages.invalidCard;
    case "405":
      return paymentMessages.insufficientFunds;
    default:
      //return paymentMessages.genericError;
      return null;
  }
}

export default function SubscribePaymentOutcome() {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [title, setTitle] = useState(null);
  //const [errorInfo, setErrorInfo] = useState(null);
  const [description, setDescription] = useState(null);
  const [level, setLevel] = useState(null);
  const [requestInfo, setRequestInfo] = useState("");
  const [subscriptionSlug, setSubscriptionSlug] = useState(null);
  //const [subscriptionId, setSubscriptionId] = useState(null);

  const { bmapi, notifyError, notifySuccess, baseUrl } = useBmapi();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const trxId = params.get("codTrans");
    console.log("WWWW1");
    if (!trxId && location.state && location.state.trialKey) {
      console.log("WWWW2");
      level = "success";
      paymentStatus = PAYMENT_STATUS_SUCCESS;
      titleMessage = intl.formatMessage(paymentMessages.success);
      descriptionMessage = intl.formatMessage(
        paymentMessages.subscriptionSuccess
      );

      setLevel(level);
      setPaymentStatus(paymentStatus);
      setTitle(titleMessage);
      setDescription(descriptionMessage);
      setSubscriptionSlug(location.state.trialKey);

      return;
    }
    const outcome = params.get("esito");
    const outcomeCode = params.get("codiceEsito");
    const outcomeDescr = params.get("messaggio");
    const body = {
      importo: parseInt(params.get("importo")),
      divisa: params.get("divisa"),
      codTrans: trxId,
      brand: params.get("brand"),
      mac: params.get("mac"),
      esito: outcome,
      data: params.get("data"),
      orario: params.get("orario"),
      codiceEsito: parseInt(outcomeCode),
      codaut: params.get("codAut"),
      pan: params.get("pan"),
      scadenza_pan: params.get("scadenza_pan"),
      nazionalita: params.get("nazionalita"),
      languageId: params.get("languageId"),
      tipoTransazione: params.get("tipoTransazione"),
      messaggio: outcomeDescr,
    };

    let paymentStatus, titleMessage, descriptionMessage, level;

    if (outcome === "ANNULLO") {
      level = "warning";
      paymentStatus = PAYMENT_STATUS_CANCELED;
      titleMessage = intl.formatMessage(paymentMessages.canceled);
      descriptionMessage = intl.formatMessage(
        paymentMessages.subscriptionCanceled
      );
      body.codaut = "MANUALLY_CANCELED";
    } else if (outcomeCode === "0") {
      level = "success";
      paymentStatus = PAYMENT_STATUS_SUCCESS;
      titleMessage = intl.formatMessage(paymentMessages.success);
      descriptionMessage = intl.formatMessage(
        paymentMessages.subscriptionSuccess
      );
    } else {
      //setErrorInfo(paymentMessages.subscriptionErrorInfo);
      level = "error";
      paymentStatus = PAYMENT_STATUS_ERROR;
      titleMessage = intl.formatMessage(paymentMessages.error);
      const em = getPaymentErrorMessage(outcomeCode, intl);
      descriptionMessage =
        intl.formatMessage(paymentMessages.subscriptionError) +
        (em ? intl.formatMessage(em) : "");
    }
    bmapi
      .subscriptionTxUpdate(trxId, body)
      .then((resp) => {
        console.log("...success", resp);
        if (outcomeCode === "0") {
          loadSubscriptionSlug(trxId);
        }
      })
      .catch((err) => {
        console.log("sub err", JSON.stringify(err));
        if (outcomeCode === "0") {
          if (err && err.code !== "PAYMENT_STATUS_ERROR") {
            console.log("...XXXX");
            paymentStatus = PAYMENT_STATUS_PROCESSED;
            descriptionMessage = intl.formatMessage(
              paymentMessages.subscriptionSuccessWait
            );
          } else if (outcomeCode === "0") {
            loadSubscriptionSlug(trxId);
          }
        }
      })
      .finally(() => {
        setLevel(level);
        setPaymentStatus(paymentStatus);
        setTitle(titleMessage);
        setDescription(descriptionMessage);
      });
  }, [bmapi, location, intl, loadSubscriptionSlug, history]);

  const goPricing = () => {
    const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;
    history.push(routes.PRICING);
  };
  /*
  const goHome = () => {
    const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;
    history.push(routes.HOME);
  };*/

  /*
  useEffect(() => {
    if (paymentStatus === PAYMENT_STATUS_SUCCESS && subscriptionSlug) {
      
      //setTimeout(() => {
      //  const url = `/${}${MANAGER_NAMESPACE}`;
      //  history.push(url)subscriptionSlug;
      //}, 4000);

      console.log(
        "ddddd.........",
        `${bmapi.settings.subscriptionsURL}${subscriptionSlug}${MANAGER_NAMESPACE}`,
        !!history
      );
    }
  }, [
    paymentStatus,
    bmapi.settings.subscriptionsURL,
    subscriptionSlug,
    history,
  ]);
  */

  const goSubscription = () => {
    const path = window.location.pathname
      .split("/")
      .map((p) => p.toLowerCase());
    let url = `${path[0]}${subscriptionSlug}${MANAGER_NAMESPACE}`;
    url = baseUrl.replace("-/", url);
    window.location.href = url;
  };

  const sendRequest = () => {
    const params = new URLSearchParams(location.search);
    const trxId = params.get("codTrans");
    bmapi
      .subscriptionTxMessage(trxId, requestInfo)
      .then((resp) => {
        console.log("...msf success", resp);
        notifySuccess(
          intl.formatMessage(paymentMessages.subscriptionErrorRequestInfoSent)
        );
        const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;
        history.push(routes.PRICING);
      })
      .catch((e) => {
        console.log("sub msg err", e);
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const loadSubscriptionSlug = useCallback(
    (txId) => {
      if (!bmapi || !txId) {
        setSubscriptionSlug(null);
        return;
      }
      bmapi
        .getPaymentPayload(txId)
        .then((resp) => {
          console.log("loadSubscriptionSlug", resp);
          if (resp.payload_type === "SUBSCRIPTION" && resp.payload_id) {
            bmapi
              .getSubscriptionsById(resp.payload_id)
              .then((respSubscr) => {
                if (respSubscr && respSubscr.length) {
                  setSubscriptionSlug(respSubscr[0].friendly_url);
                }
              })
              .catch((e) => {
                console.log("loadSubscriptionSlug by id err", e);
                setSubscriptionSlug(null);
              });
          }
        })
        .catch((e) => {
          console.log("loadSubscriptionSlug by tx err", e);
          setSubscriptionSlug(null);
        });
    },
    [bmapi]
  );

  return (
    <Container maxWidth="sm">
      <Title backUrl={CONSUMER_ROUTES.HOME}>{title}</Title>
      <Card>
        <CardContent>
          {!!paymentStatus && <Alert severity={level}>{description}</Alert>}
          {paymentStatus === PAYMENT_STATUS_ERROR && (
            <>
              <Typography>
                {intl.formatMessage(paymentMessages.subscriptionErrorInfo)}
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                value={requestInfo}
                fullWidth
                onChange={(e) => setRequestInfo(e.target.value)}
                placeholder={intl.formatMessage(
                  paymentMessages.subscriptionErrorRequestInfo
                )}
                multiline
                rows={5}
                label={intl.formatMessage(common.message)}
              />
            </>
          )}
        </CardContent>
        <CardActions>
          {/*
          <Button onClick={goHome} variant="contained" color="inherit">
            {intl.formatMessage(navigation.goHome)}
          </Button>
          */}
          {paymentStatus === PAYMENT_STATUS_CANCELED && (
            <Button onClick={goPricing} variant="contained" color="primary">
              {intl.formatMessage(navigation.subscribe)}
            </Button>
          )}

          {paymentStatus === PAYMENT_STATUS_SUCCESS && subscriptionSlug && (
            <Button
              onClick={goSubscription}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage(paymentMessages.gotoSubscription)}
            </Button>
          )}
          {paymentStatus === PAYMENT_STATUS_ERROR && (
            <Button
              onClick={sendRequest}
              variant="contained"
              color="primary"
              disabled={!requestInfo}
            >
              {intl.formatMessage(common.send)}
            </Button>
          )}
        </CardActions>
      </Card>
    </Container>
  );
}
