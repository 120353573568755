import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { Container, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getInitialState } from "../../utils/campaignCreation";
import { MANAGER_ROUTES } from "../../utils/constants";

export default function CampaignClone() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifySuccess, startLoading, stopLoading } = useBmapi();
  const [initialValues, setInitialValues] = useState(false);

  useEffect(() => {
    startLoading();
    bmapi.getCampaignDetails(campaignId).then(({ campaign }) => {
      setInitialValues(getInitialState(false, bmapi, false, campaign, true));
      stopLoading();
    });
  }, [bmapi, campaignId, startLoading, stopLoading]);

  const handleSubmit = (values, after) => {
    startLoading();

    return bmapi
      .saveCampaign(values)
      .then(after)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.campaignClone.saved",
            defaultMessage: "Campagna duplicata con successo",
          })
        );
        history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <IconButton
          component={RouterLink}
          to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
          size="small"
        >
          <ArrowBack />
        </IconButton>{" "}
        <FormattedMessage
          id="pages.campaignClone.title"
          defaultMessage="Duplica campagna per {businessName}"
          values={{
            businessName: <strong>{bmapi.getUserInfo().business.name}</strong>,
          }}
        />
      </Title>

      {!!initialValues && (
        <CommonCreateForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      )}
    </Container>
  );
}
