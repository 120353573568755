import React, { useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  AUTH_NAMESPACE,
  CONSUMER_NAMESPACE,
  CONSUMER_ROUTES,
  DEV_SUBSCRIPTION,
  MANAGER,
  MANAGER_NAMESPACE,
  MANAGER_ROUTES,
  TENANTS,
  USER_STATUS,
} from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import PageWrapper from "./PageWrapper";
import Navbar from "./Navbar";
import { getCallbackUrl, propagateUrlTracing } from "../utils/utils";

const isHome = (page) => !!page.home;
const withPath = (page) => !!page.routing?.path;
const byPathLength = (a, b) => b.routing.path.length - a.routing.path.length;

const fredirect = (url) => () => {
  window.location.href = url;
  return null;
};

function Page({ routing }) {
  if (routing.redirectUrl) {
    return (
      <Route
        exact={routing.exact}
        key={routing.path}
        path={routing.path}
        component={fredirect(routing.redirectUrl)}
      />
    );
  }

  const Wrapper = routing.Wrapper || PageWrapper;
  return (
    <Route exact={routing.exact} key={routing.path} path={routing.path}>
      <Wrapper component={routing.Component} />
    </Route>
  );
}

export default function Routing() {
  const { baseUrl, bmapi, pages } = useBmapi();
  const location = useLocation();
  const history = useHistory();
  const cbUrl = getCallbackUrl();
  const [redirectPage, setRedirectPage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const cbUrl = getCallbackUrl();
    if (bmapi.userStatus === USER_STATUS.LOGGED && cbUrl) {
      //history.push(cbUrl.replace(baseUrl, "/"));
      //bmapi.setCallbackUrl(false);
      window.location.href = cbUrl;
      return;
    }
    if (
      bmapi.userStatus === USER_STATUS.ANONYMOUS &&
      bmapi.isManager() &&
      bmapi.settings.uniqueSignIn
    ) {
      window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1);
      return;
    }
    let iredirectPage;
    if (bmapi.subscriptionSlug === DEV_SUBSCRIPTION && bmapi.app === MANAGER) {
      const arr = pages.filter(
        (page) => page.id === "manager.view_subscriptions"
      );
      if (arr?.length === 1) {
        iredirectPage = arr[0];
      }
    }
    setRedirectPage(iredirectPage);
  }, [bmapi, bmapi.userStatus, baseUrl, history, pages]);

  const activePages = useMemo(() => pages.filter(withPath).sort(byPathLength), [
    pages,
  ]);

  return (
    (bmapi.userStatus !== USER_STATUS.LOGGED || !cbUrl) && (
      <>
        <Switch>
          <Route path={MANAGER_NAMESPACE + AUTH_NAMESPACE} />
          <Route path={CONSUMER_NAMESPACE + AUTH_NAMESPACE} />
          <Route component={Navbar} />
        </Switch>
        <Switch>
          {!!redirectPage && (
            <>
              {Page(redirectPage)}
              <Redirect to={MANAGER_ROUTES.VIEW_SUBSCRIPTIONS} />
            </>
          )}
          {activePages.map(Page)}
          {bmapi.tenant === TENANTS.LUCCA && (
            <Redirect
              from={CONSUMER_ROUTES.LUCCA_CANCEL}
              to={CONSUMER_ROUTES.LUCCA_BUY}
            />
          )}

          <Redirect
            to={activePages.find(isHome).routing?.path + propagateUrlTracing()}
          />
        </Switch>
      </>
    )
  );
}
