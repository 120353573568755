import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { BmapiProvider } from "./utils/bmapi-context";
import {
  CONSUMER,
  CONSUMER_NAMESPACE,
  DEFAULT_TENANT,
  DEFAULT_TENANT_MAP,
  DEV_SUBSCRIPTION,
  ENVS,
  LOAD_DEFAULT_TENANT,
  MANAGER,
  MANAGER_NAMESPACE,
  MULTIPLE_TENANTS,
  OLD_MANAGER_NAMESPACE,
  SUBSCRIPTIONS,
  DOMAIN_CONFIG,
} from "./utils/constants";
import { LocalizationProvider } from "./utils/i18n";
import * as serviceWorker from "./utils/serviceWorker";

import App from "./components/App";

import "./index.css";

function isManager(app) {
  return (
    app === MANAGER_NAMESPACE.replaceAll("/", "") ||
    app === OLD_MANAGER_NAMESPACE.replaceAll("/", "")
  );
}

function bootstrap(basename, tenant, subscription, app = CONSUMER) {
  serviceWorker.unregister();

  if (!window.location.pathname.startsWith(basename)) {
    window.location.replace(`${window.location.origin}${basename}`);
    return;
  }
  ReactDOM.render(
    <Router basename={basename}>
      <BmapiProvider
        tenant={tenant}
        subscription={subscription}
        app={isManager(app) ? MANAGER : CONSUMER}
        baseUrl={`${window.location.origin}${basename}`}
      >
        <LocalizationProvider>
          <App />
        </LocalizationProvider>
      </BmapiProvider>
    </Router>,
    document.getElementById("root")
  );
}

if (window.location.href.includes("//bmarken.web.app/mysarca/")) {
  window.location.href = window.location.href.replace(
    "//bmarken.web.app/mysarca/",
    "//mysarca.bmarken.com/"
  );
}

const path = window.location.pathname.split("/").map((p) => p.toLowerCase());

if (DOMAIN_CONFIG?.TENANT) {
  if (DOMAIN_CONFIG.SUBSCRIPTION) {
    bootstrap("/", DOMAIN_CONFIG.TENANT, DOMAIN_CONFIG.SUBSCRIPTION, path[1]);
  } else {
    if (
      !path[1] &&
      DOMAIN_CONFIG.EXTERNAL_ROOT &&
      DOMAIN_CONFIG.ALTERNATIVESITE
    ) {
      window.location = DOMAIN_CONFIG.ALTERNATIVESITE;
    } else {
      if (SUBSCRIPTIONS) {
        bootstrap(`/${path[1]}/`, DOMAIN_CONFIG.TENANT, path[1], path[2]);
      } else {
        bootstrap("/", DOMAIN_CONFIG.TENANT, undefined, path[1]);
      }
    }
  }
} else if (MULTIPLE_TENANTS) {
  if (!path[1]) {
    if (LOAD_DEFAULT_TENANT) {
      window.location =
        window.location.href +
        (DEFAULT_TENANT_MAP[window.location.hostname] || DEFAULT_TENANT) +
        CONSUMER_NAMESPACE;
    } else {
      console.error("Tenant multipli attivati. Tenant non impostato");

      if (process.env.NODE_ENV === ENVS.PRODUCTION) {
        window.location.replace("https://liberacta.com/");
      }
    }
  } else {
    if (SUBSCRIPTIONS) {
      if (!path[2]) {
        bootstrap(
          `/${path[1]}/${DEV_SUBSCRIPTION}/`,
          path[1],
          DEV_SUBSCRIPTION,
          path[3]
        );
      } else {
        bootstrap(`/${path[1]}/${path[2]}/`, path[1], path[2], path[3]);
      }
    } else {
      bootstrap(`/${path[1]}/`, path[1], null, path[2]);
    }
  }
} else {
  //if (SUBSCRIPTION) {
  //  bootstrap("/", DEFAULT_TENANT, SUBSCRIPTION, path[0]);
  //}
  if (SUBSCRIPTIONS) {
    if (!path[1]) {
      bootstrap(
        `/${DEV_SUBSCRIPTION}/`,
        DEFAULT_TENANT,
        DEV_SUBSCRIPTION,
        path[2]
      );
    } else {
      bootstrap(`/${path[1]}/`, DEFAULT_TENANT, path[1], path[2]);
    }
  } else {
    bootstrap("/", DEFAULT_TENANT, undefined, path[1]);
  }
}
