import { defineMessages } from "react-intl";

export const common = defineMessages({
  accept: {
    id: "common.accept",
    defaultMessage: "Accetta",
  },
  add: {
    id: "common.add",
    defaultMessage: "Aggiungi",
  },
  addElement: {
    id: "common.addElement",
    defaultMessage: "Elemento aggiunto con successo",
  },
  all: {
    id: "common.all",
    defaultMessage: "Tutti",
  },
  automatic: {
    id: "common.form.automatic",
    defaultMessage: "Automatica",
  },
  back: {
    id: "common.back",
    defaultMessage: "Indietro",
  },
  backHome: {
    id: "common.backHome",
    defaultMessage: "Torna alla home",
  },
  backTour: {
    id: "common.backTour",
    defaultMessage: "Torna al tour",
  },
  backTours: {
    id: "common.backTours",
    defaultMessage: "Torna ai tours",
  },
  nextPOI: {
    id: "common.nextPOI",
    defaultMessage: "Successivo",
  },
  prevPOI: {
    id: "common.prevPOI",
    defaultMessage: "Precedente",
  },
  birthday: {
    id: "common.form.birthday",
    defaultMessage: "Data di nascita",
  },
  business: {
    id: "common.business",
    defaultMessage: "Negozio",
  },
  campaign: {
    id: "common.campaign",
    defaultMessage: "Campagna",
  },
  campaigns: {
    id: "common.campaigns",
    defaultMessage: "Campagne",
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Annulla",
  },
  check: {
    id: "common.check",
    defaultMessage: "Controlla",
  },
  close: {
    id: "common.close",
    defaultMessage: "Chiudi",
  },
  confirm: {
    id: "common.confirm",
    defaultMessage: "Conferma",
  },
  create: {
    id: "common.create",
    defaultMessage: "Crea",
  },
  date: {
    id: "common.date",
    defaultMessage: "Data",
  },
  day: {
    id: "common.day",
    defaultMessage: "Giorno",
  },
  days: {
    id: "common.form.days",
    defaultMessage: "Giorni",
  },
  delete: {
    id: "common.delete",
    defaultMessage: "Elimina",
  },
  deleteElement: {
    id: "common.deleteElement",
    defaultMessage: "Elemento eliminato con successo",
  },
  demo: {
    id: "common.demo",
    defaultMessage: "Demo",
  },
  deutch: {
    id: "common.deutch",
    defaultMessage: "Tedesco",
  },
  download: {
    id: "common.download",
    defaultMessage: "Download",
  },
  email: {
    id: "common.email",
    defaultMessage: "Email",
  },
  emailVerified: {
    id: "common.emailVerified",
    defaultMessage: "Email verificata con successo",
  },
  expense: {
    id: "common.expense",
    defaultMessage: "Spesa",
  },
  event: {
    id: "common.event",
    defaultMessage: "Evento",
  },
  eventRunning: {
    id: "common.eventRunning",
    defaultMessage: "Evento in corso",
  },
  file: {
    id: "common.file",
    defaultMessage: "File",
  },
  forward: {
    id: "common.forward",
    defaultMessage: "Avanti",
  },
  noEventRunning: {
    id: "common.noEventRunning",
    defaultMessage: "Nessun evento in corso",
  },
  end: {
    id: "common.end",
    defaultMessage: "Fine",
  },
  endEvent: {
    id: "common.endEvent",
    defaultMessage: "Concludi evento",
  },
  eventCode: {
    id: "common.eventCode",
    defaultMessage: "Codice evento",
  },
  duration: {
    id: "common.duration",
    defaultMessage: "Durata",
  },
  english: {
    id: "common.english",
    defaultMessage: "Inglese",
  },
  start: {
    id: "common.start",
    defaultMessage: "Inizio",
  },
  startEvent: {
    id: "common.startEvent",
    defaultMessage: "Avvia evento",
  },
  notPresent: {
    id: "common.notPresent",
    defaultMessage: "Assente",
  },
  female: {
    id: "common.form.female",
    defaultMessage: "Femmina",
  },
  fiscalCode: {
    id: "common.form.fiscal_code",
    defaultMessage: "Codice fiscale",
  },
  french: {
    id: "common.french",
    defaultMessage: "Francese",
  },
  from: {
    id: "common.from",
    defaultMessage: "Da",
  },
  gender: {
    id: "common.form.gender",
    defaultMessage: "Genere",
  },
  generalData: {
    id: "common.generalData",
    defaultMessage: "Dati generali",
  },
  hoursCount: {
    id: "common.hoursCount",
    defaultMessage: "{hours, plural, one {1 ora} other {# ore}}",
  },
  invitedBy: {
    id: "common.form.invitedBy",
    defaultMessage: "Codice amico",
  },
  issueNotification: {
    id: "common.form.issueNotification",
    defaultMessage: "Notifica di emissione",
  },
  italian: {
    id: "common.italian",
    defaultMessage: "Italiano",
  },
  language: {
    id: "common.form.language",
    defaultMessage: "Lingua",
  },
  lastName: {
    id: "common.form.last_name",
    defaultMessage: "Cognome",
  },
  loading: {
    id: "common.loading",
    defaultMessage: "Caricamento",
  },
  location: {
    id: "common.location",
    defaultMessage: "Località",
  },
  locations: {
    id: "common.locations",
    defaultMessage: "Località",
  },
  loop: {
    id: "common.loop",
    defaultMessage: "Circuito",
  },
  male: {
    id: "common.form.male",
    defaultMessage: "Maschio",
  },
  multimediaData: {
    id: "common.multimediaData",
    defaultMessage: "Dati multimediali",
  },
  mobile: {
    id: "common.mobile",
    defaultMessage: "Telefono",
  },
  modify: {
    id: "common.modify",
    defaultMessage: "Modifica",
  },
  month: {
    id: "common.month",
    defaultMessage: "Mese",
  },
  months: {
    id: "common.months",
    defaultMessage: "Mesi",
  },
  name: {
    id: "common.name",
    defaultMessage: "Nome",
  },
  noElement: {
    id: "common.noElement",
    defaultMessage: "Nessun elemento presente",
  },
  noSubscriptions: {
    id: "common.noSubscriptions",
    defaultMessage:
      "Non hai ancora sottoscrizioni attive. Registra la tua attività <link>cliccando qui</link>",
  },
  notSpecified: {
    id: "common.notSpecified",
    defaultMessage: "Non specificato",
  },
  ok: {
    id: "common.ok",
    defaultMessage: "Ok",
  },
  other: {
    id: "common.other",
    defaultMessage: "Altro",
  },
  password: {
    id: "common.password",
    defaultMessage: "Password",
  },
  passwordUpdated: {
    id: "common.passwordUpdated",
    defaultMessage: "Password cambiata con successo",
  },
  prizes: {
    id: "common.prizes",
    defaultMessage: "Premi",
  },
  product: {
    id: "common.product",
    defaultMessage: "Prodotto",
  },
  qrCode: {
    id: "common.qrCode",
    defaultMessage: "Mostra QR Code",
  },
  quantity: {
    id: "common.quantity",
    defaultMessage: "Quantità",
  },
  referrer: {
    id: "common.referrer",
    defaultMessage: "Referrer",
  },
  role: {
    id: "common.role",
    defaultMessage: "Ruolo",
  },
  save: {
    id: "common.save",
    defaultMessage: "Salva",
  },
  secondaryEmail: {
    id: "common.secondaryEmail",
    defaultMessage: "Email secondaria",
  },
  send: {
    id: "common.send",
    defaultMessage: "Invia",
  },
  spanish: {
    id: "common.spanish",
    defaultMessage: "Spagnolo",
  },
  statistics: {
    id: "common.statistics",
    defaultMessage: "Statistiche",
  },
  store: {
    id: "common.store",
    defaultMessage: "Negozio",
  },
  stores: {
    id: "common.stores",
    defaultMessage: "Negozi",
  },
  subscriptions: {
    id: "common.subscriptions",
    defaultMessage: "I tuoi abbonamenti",
  },
  times: {
    id: "common.form.times",
    defaultMessage: "Volte",
  },
  to: {
    id: "common.to",
    defaultMessage: "A",
  },
  total: {
    id: "common.total",
    defaultMessage: "Totale",
  },
  totalPoints: {
    id: "common.totalPoints",
    defaultMessage: "{points, plural, one {# punto} other {# punti}}",
  },
  transactions: {
    id: "common.transactions",
    defaultMessage: "Transazioni",
  },
  update: {
    id: "common.update",
    defaultMessage: "Aggiorna",
  },
  upload: {
    id: "common.upload",
    defaultMessage: "Upload",
  },
  uploadLogo: {
    id: "common.uploadLogo",
    defaultMessage: "Carica logo",
  },
  deleteLogo: {
    id: "common.deleteLogo",
    defaultMessage: "Cancella logo",
  },
  url: {
    id: "common.url",
    defaultMessage: "URL",
  },
  value: {
    id: "common.value",
    defaultMessage: "Valore",
  },
  view: {
    id: "common.view",
    defaultMessage: "Visualizza",
  },
  week: {
    id: "common.week",
    defaultMessage: "Settimana",
  },
  welcome: {
    id: "common.welcome",
    defaultMessage: "Benvenuto!",
  },
  welcomeMsg: {
    id: "common.welcomeMsg",
    defaultMessage:
      "Utilizza <link>il tuo QR Code</link> per ottenere nuovi card e coupon. Lo puoi trovare aprendo il menu in alto a destra o usando il pulsante in fondo a questa pagina.",
  },
  message: {
    id: "common.message",
    defaultMessage: "Messaggio",
  },
  messages: {
    id: "common.messages",
    defaultMessage: "Messaggi",
  },
});
