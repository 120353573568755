import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useParams } from "react-router-dom";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import Product from "../../components/Product";
import { useBmapi } from "../../utils/bmapi-context";
import { getAvailability } from "../../utils/campaigns";
import styles from "../../utils/styles";
import {
  CONSUMER_ROUTES,
  PRODUCTS,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  TENANTS,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { common } from "../../messages";
import CardTetris from "../../components/campaign/CardTetris";
import CardDetails from "../../components/campaign/CardDetails";
import CardCreditiAncot from "../../components/campaign/CardCreditiAncot";
import CardStatistics from "../../components/campaign/CardStatistics";
import CardTransactions from "../../components/campaign/CardTransactions";
import CardPrizes from "../../components/campaign/CardPrizes";
import CardProducts from "../../components/campaign/CardProducts";
import CardCheckins from "../../components/campaign/CardCheckins";
import CardEventRunning from "../../components/campaign/CardEventRunning";
import useDocumentTitle from "../../utils/documentTitle";

const byExpiration = (a, b) =>
  new Date(a.expiration_date) - new Date(b.expiration_date);

function BackButton() {
  const intl = useIntl();

  return (
    <Box my={2}>
      <Button
        component={RouterLink}
        to={CONSUMER_ROUTES.HOME}
        startIcon={<ArrowBackIcon />}
      >
        {intl.formatMessage(common.back)}
      </Button>
    </Box>
  );
}

export function ProductCard({
  action,
  title,
  subtitle,
  Icon,
  iconBg,
  counter,
}) {
  const classes = styles.useStyles();

  return (
    <ListItem>
      {Icon && (
        <ListItemAvatar>
          <Badge
            color="secondary"
            badgeContent={counter > 1 ? counter : 0}
            overlap="circle"
          >
            <Avatar
              className={classes.cardIcon}
              style={iconBg && { backgroundColor: iconBg }}
            >
              <Icon />
            </Avatar>
          </Badge>
        </ListItemAvatar>
      )}
      <ListItemText primary={title} secondary={subtitle} />
      {action && (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={action.fn}>
            <action.Icon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function Wrapper({ children, render, visible = true }) {
  return visible && <Box mb={4}>{render ? render() : children}</Box>;
}

function hasCheckins(campaign, userId) {
  return campaign?.front_end_type === PRODUCTS.COURSE_PASS && userId;
}

function isActive(campaign) {
  return (res) => res.campaign_id === campaign.id && res.status === 0;
}

export default function Campaign() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const { bmapi, userId, startLoading, stopLoading, notifyError } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);
  const [reserved, setReserved] = useState(false);
  const [issuedQuantity, setIssuedQuantity] = useState(false);
  const [checkins, setCheckins] = useState(false);
  const [lesson, setLesson] = useState(false);
  const setTitle = useDocumentTitle();

  const updateCheckins = useCallback(() => {
    bmapi.getUserCheckIns().then((cs) => {
      const campaignCheckins = (cs || [])
        .filter((c) => (c.campaign_ids || []).includes(campaignId))
        .sort((a, b) => new Date(b.check_in_at) - new Date(a.check_in_at));
      setCheckins(campaignCheckins.filter((c) => c.checked_out));
      setLesson(campaignCheckins.find((c) => !c.checked_out));
    });
  }, [bmapi, campaignId]);

  const updateData = useCallback(() => {
    startLoading();

    Promise.all([
      bmapi.getCampaign(campaignId),
      userId ? bmapi.getUserProductsStats() : [],
      userId ? bmapi.getUserProductsByCampaign(campaignId) : [],
      userId ? bmapi.getUserReservations() : [],
    ])
      .then(([{ campaign, issued_qty }, stats, products, reservations]) => {
        setCampaign(campaign);
        setTitle(campaign.name);
        setIssuedQuantity(issued_qty);
        setStats(stats.find((s) => s.campaign.id === campaignId) || false);
        setProducts(products.sort(byExpiration) || []);
        setReserved((reservations || []).some(isActive(campaign)));

        if (hasCheckins(campaign, userId)) updateCheckins();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    bmapi,
    campaignId,
    intl,
    notifyError,
    setTitle,
    startLoading,
    stopLoading,
    updateCheckins,
    userId,
  ]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  const visiblity = useMemo(
    () => ({
      ancotCredits:
        bmapi.settings.theme === TENANTS.ANCOT &&
        campaign?.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS &&
        campaign?.rules.main_event_id === "",
      checkins: hasCheckins(campaign, userId),
      details:
        bmapi.settings.theme !== TENANTS.ANCOT ||
        campaign?.rules.main_event_id !== "",
      prizes:
        campaign?.rules.subtype === PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE &&
        Array.isArray(campaign?.rules.prizes) &&
        campaign?.rules.prizes.length > 0,
      products:
        bmapi.settings.theme !== TENANTS.ANCOT &&
        campaign?.type !== PRODUCT_TYPES.CAMPAIGN_EARNING_CARD &&
        products?.filter((p) => p.status === 0 || p.status === 4).length > 0,
      statistics:
        stats &&
        campaign?.front_end_type !== PRODUCTS.COURSE_PASS &&
        bmapi.settings.theme !== TENANTS.ANCOT &&
        campaign?.rules.category_bonus_value === 0,
      tetris: stats && campaign?.rules.category_bonus_value > 0,
      transactions:
        products?.length > 0 &&
        campaign?.front_end_type !== PRODUCTS.COURSE_PASS &&
        bmapi.settings.theme !== TENANTS.ANCOT,
    }),
    [bmapi.settings.theme, campaign, products, stats, userId]
  );

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <BackButton />

        <Wrapper>
          <Product
            campaign={campaign}
            products={products}
            issuedQuantity={issuedQuantity}
            availability={stats ? getAvailability(stats) : 0}
            onUpdate={updateData}
            reserved={reserved}
          />
        </Wrapper>

        <Wrapper visible={!!lesson && visiblity.checkins}>
          <CardEventRunning lesson={lesson} onUpdate={updateCheckins} />
        </Wrapper>

        <Wrapper visible={visiblity.checkins}>
          <CardCheckins checkins={checkins} />
        </Wrapper>

        <Wrapper visible={visiblity.products}>
          <CardProducts
            campaign={campaign}
            onUpdate={updateData}
            products={products}
          />
        </Wrapper>

        <Wrapper visible={visiblity.prizes}>
          <CardPrizes
            card={products?.[0]}
            onUpdate={updateData}
            prizes={campaign.rules.prizes}
          />
        </Wrapper>

        <Wrapper visible={visiblity.tetris}>
          <CardTetris stats={stats} />
        </Wrapper>

        <Wrapper visible={visiblity.details}>
          <CardDetails campaign={campaign} />
        </Wrapper>

        <Wrapper visible={visiblity.ancotCredits}>
          <CardCreditiAncot />
        </Wrapper>

        <Wrapper visible={visiblity.statistics}>
          <CardStatistics stats={stats} />
        </Wrapper>

        <Wrapper visible={visiblity.transactions}>
          <CardTransactions campaign={campaign} />
        </Wrapper>

        <BackButton />
      </Container>
    )
  );
}
