import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common } from "../messages";

export default function QRCodeDialog({ code, setCode, item, itemTitle }) {
  const intl = useIntl();

  return (
    <Dialog
      open={!!code}
      onClose={() => setCode(null)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>{itemTitle}</DialogTitle>
      <DialogContent align="center">
        {code && <img src={code} alt="QR Code" style={{ maxWidth: "100%" }} />}
        {item && item.id}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setCode(null)}
          variant="contained"
          color="primary"
        >
          {intl.formatMessage(common.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
