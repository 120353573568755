import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Container,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Input,
  TextareaAutosize,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
//import MessageCampaingCreateForm from "../../ui/forms/MessageCampaingCreateForm";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useParams } from "react-router-dom";
import FormSection from "../../ui/forms/input/FormSection";
import { common, form } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import { getErrorMessageString } from "../../utils/errors";
import { useHistory } from "react-router-dom";

function initValues() {
  return { campaignName: "", title: "", body: "" };
}

export default function Campaigns() {
  const {
    bmapi,
    businessId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const { messageId } = useParams();
  const [values, setValues] = useState(initValues());
  const intl = useIntl();
  const today = new Date();

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    if (campaigns) {
      bmapi
        .getMessagesCampaign()
        .then((messages) => {
          const msgObj = messages.find((x) => x.message.id == messageId);
          setValues({
            campaignName:
              campaigns.find((x) => x.id == msgObj.message.campaign_id) ==
              undefined
                ? "Nessuna campagna"
                : campaigns.find((x) => x.id == msgObj.message.campaign_id)
                    .name,
            title: msgObj.message.title,
            body: msgObj.message.body,
            banner: msgObj.mobile_config.banner,
            push_notification: msgObj.mobile_config.push_notification,
            expire_at: msgObj.message.expire_at.substring(0, 10),
            app_link_label: msgObj.message.app_link_label,
            app_link_uri: msgObj.message.app_link_uri,
            image_url: msgObj.message.image_url,
            link_uri: msgObj.mobile_config.link_uri,
          });
          stopLoading();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          stopLoading();
        });
    }
    // eslint-disable-next-line
  }, [campaigns]);

  const handleChange = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  function fields() {
    return (
      <div style={{ minHeight: "300px" }}>
        <Input
          name="title"
          label={intl.formatMessage(form.title)}
          value={values.title}
          onChange={handleChange("title")}
          key="title"
          fullWidth
          margin="normal"
          required
        />
        <Typography className="MuiFormLabel-root">{"Testo *"}</Typography>
        <TextareaAutosize
          name="body"
          label={"Testo"}
          value={values.body}
          onChange={handleChange("body")}
          key="body"
          style={{
            width: "100%",
            height: "200px",
          }}
          aria-label="maximum height"
          placeholder="Inserire testo del messaggio"
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.banner ? "checked" : ""}
                onChange={handleChange("banner")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={intl.formatMessage(form.banner)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.push_notification ? "checked" : ""}
                onChange={handleChange("push_notification")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Push Notification"
          />
        </FormGroup>
        <DatePicker
          minDate={today}
          margin="normal"
          fullWidth
          label="Expire At"
          format="yyyy-MM-dd"
          value={values.expire_at}
          onChange={handleChange("expire_at")}
        />
        <Typography>App Link Label</Typography>
        <Input
          name="app_link_label"
          value={values.app_link_label}
          onChange={handleChange("app_link_label")}
          key="app_link_label"
          fullWidth
          margin="normal"
        />
        <Typography>App Link URI</Typography>
        <Input
          name="app_link_uri"
          value={values.app_link_uri}
          onChange={handleChange("app_link_uri")}
          key="app_link_uri"
          fullWidth
          margin="normal"
        />
        <Typography>Image URL</Typography>
        <Input
          name="image_url"
          value={values.image_url}
          onChange={handleChange("image_url")}
          key="image_url"
          fullWidth
          margin="normal"
        />
        <Typography>Link URI</Typography>
        <Input
          name="link_uri"
          value={values.link_uri}
          onChange={handleChange("link_uri")}
          key="link_uri"
          fullWidth
          margin="normal"
        />
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const client_id = "16d4c11d-1798-4217-8834-d0fe37a4c49c";
    return bmapi
      .updateMessageCampaign(
        values.title,
        values.body,
        values.banner,
        values.push_notification,
        values.expire_at instanceof Date
          ? values.expire_at.toISOString().substring(0, 10)
          : values.expire_at,
        values.app_link_label,
        values.app_link_uri,
        values.image_url,
        values.link_uri,
        messageId,
        client_id
      )
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.messageCampaign.update",
            defaultMessage: "Messaggio aggiornato con successo",
          })
        );
        stopLoading();
        history.push(MANAGER_ROUTES.MESSAGES_CAMPAIGN);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  return !businessId ? (
    <Container maxWidth="md" className="tablistNone"></Container>
  ) : campaigns ? (
    <Container maxWidth="sm" className="tablistNone">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="pages.messageCampaigns.edit"
              defaultMessage="Modifica Messaggio"
            />
          </Box>
        </Box>
      </Title>
      <Box>
        <FormSection>
          <Typography disabled>
            <b>Campagna:</b> {values.campaignName}
            <b>Titolo:</b> {values.title}
            <b>Body:</b> {values.body}
          </Typography>
        </FormSection>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormSection>{fields()}</FormSection>
        <Button variant="contained" fullWidth color="primary" type="submit">
          {intl.formatMessage(common.save)}
        </Button>
      </form>
      {/*<!--MessageCampaingCreateForm
        handleSubmit={handleSubmit}
        style={{ background: "red" }}
        title={values.title}
        body={values.body}
      /-->*/}
    </Container>
  ) : null;
}
