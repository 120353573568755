import { defineMessages } from "react-intl";

export const museumLabels = defineMessages({
  editBusiness: {
    id: "component.manageMuseums.editStore",
    defaultMessage: "Modifica museo/agenzia",
  },
  noBusinessRegistered: {
    id: "component.manageMuseums.noStoreRegistered",
    defaultMessage: "Nessun museo/agenzia ancora registrato.",
  },
  emptyAction: {
    id: "component.manageMuseums.emptyAction",
    defaultMessage:
      "Crea il primo museo/agenzia cliccando sul bottone a fondo pagina o <link>qui</link>.",
  },
  pageTitle: {
    id: "pages.museums.title",
    defaultMessage: "Musei/Agenzie",
  },
  createBusiness: {
    id: "component.manageMuseums.createStore",
    defaultMessage: "Crea museo/agenzia",
  },
});
