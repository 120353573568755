import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { campaign as campaignMsg, common } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import RichText from "../RichText";

function StoresDialog({ onClose, open, stores }) {
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {intl.formatMessage(campaignMsg.participatingStores)}
      </DialogTitle>
      <DialogContent>
        {stores === false ? (
          intl.formatMessage(campaignMsg.loading)
        ) : stores === true ? (
          intl.formatMessage(campaignMsg.allStores)
        ) : stores.length === 0 ? (
          intl.formatMessage(campaignMsg.noStores)
        ) : (
          <ul>
            {stores.map((s) => (
              <li key={s.business_id}>{s.business_name}</li>
            ))}
          </ul>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {intl.formatMessage(common.ok)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function CardDetails({ campaign }) {
  const intl = useIntl();
  const { bmapi, userId } = useBmapi();
  const [stores, setStores] = useState(false);
  const [viewStores, setViewStores] = useState(false);

  const createLink = (str) =>
    campaign.tos_consumer_url.includes("://") ? (
      <Link
        key={campaign.tos_consumer_url}
        href={campaign.tos_consumer_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {str}
      </Link>
    ) : (
      <Link
        component={RouterLink}
        key={campaign.tos_consumer_url}
        to={campaign.tos_consumer_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {str}
      </Link>
    );

  useEffect(() => {
    if (userId && campaign.loop_campaign) {
      bmapi.getCampaignTerms(campaign.id).then((st) => {
        setStores(
          st.length === 0 ||
            st.filter((s) => s.signed && s.business_name && !s.demo)
        );
      });
    }
  }, [bmapi, campaign.id, campaign.loop_campaign, userId]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{intl.formatMessage(campaignMsg.details)}</Typography>
      </AccordionSummary>
      <CardContent>
        <RichText text={campaign.description} />
        {campaign.tos_consumer_url && (
          <Box mt={1}>
            <Typography variant="body2">
              {intl.formatMessage(campaignMsg.termOfService, {
                link: createLink,
              })}
            </Typography>
          </Box>
        )}
        {userId && campaign.loop_campaign && (
          <Box mt={1}>
            <Typography variant="body2">
              <Link onClick={() => setViewStores(true)}>
                {intl.formatMessage(campaignMsg.participatingStores)}
              </Link>
            </Typography>
          </Box>
        )}
      </CardContent>

      <StoresDialog
        open={viewStores}
        onClose={() => setViewStores(false)}
        stores={stores}
      />
    </Accordion>
  );
}
